import React from 'react';
import { Tag } from 'antd';

// Impossible to predict what properties are gonna be used, needs to be set as any
const CustomTag = (props: any) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={label !== 'English' && closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

// Impossible to predict what properties are gonna be used, needs to be set as any
const CustomTagWithoutDeselect = (props: any) => {
  const { label, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag onMouseDown={onPreventMouseDown} onClose={onClose}>
      {label}
    </Tag>
  );
};

export { CustomTagWithoutDeselect, CustomTag };
