import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    version="1.1"
    width="24px"
    height="24px"
    viewBox="0 0 24.0 24.0"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="ii">
        <path d="M7,0 C7.55,0 8,0.45 8,1 L7.9932398,1.11619898 C7.93520408,1.6119898 7.51071429,2 7,2 L5,2 C4.45,2 4,2.45 4,3 L4,5 C4,6.3 3.16,7.42 2,7.83 L2,8.17 C3.16,8.58 4,9.7 4,11 L4,13 C4,13.55 4.45,14 5,14 L7,14 C7.55,14 8,14.45 8,15 L7.9932398,15.116199 C7.93520408,15.6119898 7.51071429,16 7,16 L5,16 C3.34,16 2,14.66 2,13 L2,11 C2,10.45 1.55,10 1,10 L0.88380102,9.9932398 C0.388010204,9.93520408 0,9.51071429 0,9 L0,7 C0,6.45 0.45,6 1,6 L1.11619898,5.9932398 C1.6119898,5.93520408 2,5.51071429 2,5 L2,3 C2,1.34 3.34,0 5,0 Z M15,0 C16.66,0 18,1.34 18,3 L18,5 C18,5.55 18.45,6 19,6 C19.55,6 20,6.45 20,7 L20,9 C20,9.55 19.55,10 19,10 L18.883801,10.0067602 C18.3880102,10.0647959 18,10.4892857 18,11 L18,13 C18,14.66 16.66,16 15,16 L13,16 C12.45,16 12,15.55 12,15 L12.0067602,14.883801 C12.0647959,14.3880102 12.4892857,14 13,14 L15,14 C15.55,14 16,13.55 16,13 L16,11 C16,9.69 16.84,8.58 18,8.17 L18,7.83 C16.84,7.42 16,6.3 16,5 L16,3 C16,2.45 15.55,2 15,2 L13,2 C12.45,2 12,1.55 12,1 L12.0067602,0.88380102 C12.0647959,0.388010204 12.4892857,0 13,0 Z" />
      </clipPath>
      <clipPath id="ii1">
        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(2.0 4.0)">
      <g clipPath="url(#ii)">
        <polygon points="0,0 20,0 20,16 0,16 0,0" stroke="none" fill="#979797" />
        <g transform="translate(-2.0 -4.0)">
          <g clipPath="url(#ii1)">
            <polygon points="2,4 22,4 22,20 2,20 2,4" stroke="none" fill="#979797" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
