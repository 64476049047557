import React, { ReactChild } from 'react';
import { Button } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import HeaderComponent from 'components/Header';
import { Wrapper, StyledResult } from './styled';

interface IProps {
  status: number;
  title?: string;
  extra?: ReactChild;
}

const GenericNotFound = ({ status, title, extra }: IProps) => {
  let cStatus: string | number = status;
  let cSubTitle = null;
  const cTitle = title || cStatus || 'unknown error';

  switch (cStatus) {
    // Antd Result has only these 3 templates defined
    case 403:
      cSubTitle = <p>Sorry, you are not authorized to access this page.</p>;
      break;
    case 404:
      cSubTitle = <p>Sorry, the page you visited does not exist.</p>;
      break;
    case 500:
      cSubTitle = <p>Sorry, something went wrong.</p>;
      break;
    default:
      // for others used generic 'error' template
      cSubTitle = <p>Unknown error</p>;
      cStatus = 'error';
  }

  return (
    <Wrapper>
      <HeaderComponent />
      <StyledResult
        status={cStatus as ResultStatusType}
        title={title || cTitle}
        subTitle={cSubTitle}
        extra={
          extra || (
            <Button type="primary">
              <a href="/configurations">Back Home</a>
            </Button>
          )
        }
      />
    </Wrapper>
  );
};

export default GenericNotFound;
