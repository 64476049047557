import React, { MouseEventHandler } from 'react';
import Select, { components, MultiValueGenericProps, MultiValueProps, Props } from 'react-select';

import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
}

export const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

export const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props} />
));

export const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props & SortableContainerProps
>;
