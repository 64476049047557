import React from 'react';
import { Row } from 'antd';
import { Outlet } from 'react-router-dom';

import Logo from 'components/Logo';

import { Layout, NotAuthorizedContent, LayoutHeader } from './styled';

const NotAuthorizedLayout = () => {
  return (
    <Layout>
      <LayoutHeader>
        <Row justify="center">
          <Logo width={200} />
        </Row>
      </LayoutHeader>
      <NotAuthorizedContent>
        <Outlet />
      </NotAuthorizedContent>
    </Layout>
  );
};

export default NotAuthorizedLayout;
