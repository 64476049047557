import { gql } from '@apollo/client';

const AUTH_USER_MUTATION = () => gql`
  mutation authenticateUser($token: String!) {
    authenticateUser(accessToken: $token) {
      token
    }
  }
`;

export default AUTH_USER_MUTATION;
