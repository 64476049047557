import { AppContext } from 'lib/constants/global';
import { useContext } from 'react';

const useMessage = () => {
  const ctx = useContext(AppContext);

  return ctx.message;
};

export default useMessage;
