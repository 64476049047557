import React, { ReactNode } from 'react';
import { Checkbox } from 'antd';

import Button, { IButtonProps } from 'components/Button/Button';
import CloseIcon from 'components/Icons/Close';
import { Flex } from 'shared-components';

import { Container, Title, Description, Label, Content } from './styled';

interface ButtonProps extends IButtonProps {
  label: string;
  'data-testid'?: string;
}

interface IModalProps {
  children: React.ReactNode;
  visible: boolean;
  title: ReactNode | string;
  description?: string;
  dataTestId?: string;
  isDefaultFooter?: boolean;
  maskClosable?: boolean;
  primaryButton?: ButtonProps | null;
  secondaryButton?: ButtonProps | null;
  checkbox?: boolean;
  isConfirmation?: boolean;
  hideFooterBorder?: boolean;
  loading?: boolean;
  width?: string | number;
  bodyHeight?: string | number;
  checkboxLabel?: string;
  onCheckboxChange?: (isChecked: boolean) => void;
  onClose: () => void;
}

const Modal = ({
  visible,
  children,
  title,
  description,
  isDefaultFooter = true,
  maskClosable,
  checkboxLabel,
  dataTestId,
  primaryButton,
  secondaryButton,
  checkbox,
  isConfirmation,
  hideFooterBorder,
  width,
  bodyHeight,
  onClose,
  onCheckboxChange,
}: IModalProps) => {
  return (
    <Container
      maskStyle={{ backgroundColor: 'rgb(242, 246, 253, 0.8)' }}
      open={visible}
      data-testid={dataTestId || ''}
      centered
      title={
        <>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </>
      }
      onCancel={onClose}
      maskClosable={maskClosable}
      hideFooterBorder={hideFooterBorder}
      closeIcon={<CloseIcon data-cy="modal-close-button" />}
      footer={
        <>
          {checkbox && onCheckboxChange && (
            <div>
              <Checkbox onChange={(e) => onCheckboxChange(e.target.checked)} />
              {checkboxLabel && <Label>{checkboxLabel}</Label>}
            </div>
          )}
          <Flex
            justifyContent={isDefaultFooter ? 'space-between' : 'end'}
            style={isDefaultFooter ? { width: '100%' } : { marginLeft: 'auto' }}
          >
            {secondaryButton && (
              <Button
                {...secondaryButton}
                key="secondaryButton"
                size="default"
                type="border"
                data-id={visible ? 'secondaryModalButton' : ''}
                data-cy="modal-secondary-button"
              >
                {secondaryButton.label}
              </Button>
            )}
            {primaryButton && (
              <Button
                {...primaryButton}
                key="primaryButton"
                size="default"
                data-id={visible ? 'primaryModalButton' : ''}
                type="primary"
                data-cy="modal-primary-button"
              >
                {primaryButton.label}
              </Button>
            )}
          </Flex>
        </>
      }
      width={width}
      bodyHeight={bodyHeight}
    >
      <Content isConfirmation={isConfirmation}>{children}</Content>
    </Container>
  );
};

export default Modal;
