import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = ({
  height = '16',
  width = '16',
  fill = '#303030',
}: {
  height: string;
  width: string;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 96 960 960"
    width={width}
    fill={fill}
  >
    <path d="M322 422q-9-9-9-22t9-22l137-137q5-5 10-7t11-2q5 0 10.5 2t10.5 7l137 137q9 9 9 22t-9 22q-9 9-22 9t-22-9L480 308 366 422q-9 9-22 9t-22-9Zm158 502q-5 0-10.5-2t-10.5-7L322 778q-9-9-9-22t9-22q9-9 22-9t22 9l114 114 114-114q9-9 22-9t22 9q9 9 9 22t-9 22L501 915q-5 5-10 7t-11 2Z" />
  </svg>
);

export default (props: any) => <Icon component={() => CustomIcon(props)} {...props} />;
