import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    id="questionIcon"
    width="16px"
    height="16px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10,1.66666667 C5.4,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.4,18.3333333 10,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 10,1.66666667 Z M10,14.1666667 C9.54166667,14.1666667 9.16666667,13.7916667 9.16666667,13.3333333 L9.16666667,10 C9.16666667,9.54166667 9.54166667,9.16666667 10,9.16666667 C10.4583333,9.16666667 10.8333333,9.54166667 10.8333333,10 L10.8333333,13.3333333 C10.8333333,13.7916667 10.4583333,14.1666667 10,14.1666667 Z M10.8333333,7.5 L9.16666667,7.5 L9.16666667,5.83333333 L10.8333333,5.83333333 L10.8333333,7.5 Z" />
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
