import styled from 'styled-components';

import { Input } from 'antd';

export default styled(Input)`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => props.theme.colors.primaryText};

  & .ant-input-group {
    height: 40px;

    & .ant-input-group-addon {
      background: ${(props) => props.theme.colors.darkGray};
      color: ${(props) => props.theme.colors.gray};
    }

    & .ant-input {
      height: 100%;
    }
  }
`;
