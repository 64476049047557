import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>vpn_key_off_black_24dp</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-308.000000, -461.000000)">
        <g id="Group-2-Copy" transform="translate(272.000000, 347.000000)">
          <g id="Subscription" transform="translate(20.000000, 90.000000)">
            <g id="vpn_key_off_black_24dp" transform="translate(16.000000, 24.000000)">
              <g id="Group">
                <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              </g>
              <path
                d="M3.98,6.81 C2.2,7.85 1,9.79 1,12 C1,15.31 3.69,18 7,18 C9.21,18 11.15,16.8 12.18,15.01 L19.07,21.9 C19.46,22.29 20.09,22.29 20.48,21.9 C20.87,21.51 20.87,20.88 20.48,20.49 L3.51,3.51 C3.12,3.12 2.49,3.12 2.1,3.51 C1.71,3.9 1.71,4.53 2.1,4.92 L3.98,6.81 Z M8.99,11.82 C9,11.88 9,11.94 9,12 C9,13.1 8.1,14 7,14 C5.9,14 5,13.1 5,12 C5,10.9 5.9,10 7,10 C7.06,10 7.12,10 7.18,10.01 L8.99,11.82 Z M20.32,17.5 C20.74,17.13 21,16.59 21,16 L21,14 C22.1,14 23,13.1 23,12 C23,10.9 22.1,10 21,10 L12.83,10 L20.32,17.5"
                id="Shape"
                fill="#979797"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
