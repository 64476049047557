import { useAuth0 } from '@auth0/auth0-react';

export const useLogout = (redirectToLogout = false) => {
  const { logout } = useAuth0();

  return async () => {
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}${redirectToLogout ? '/logout' : '/login'}`,
      },
    });
  };
};
