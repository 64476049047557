import React, { useState } from 'react';
import { Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { IWidget } from 'lib/interfaces/widget';
import useMessage from 'lib/hooks/useMessage';
import { showErrorResponseNotification } from 'lib/helpers';
import { WebhookPayloadAnswersLayout, WebhookPayloadAnswersType } from 'lib/enums/webhooks';

import WebhookApiService from 'lib/api/webhookApi';

import Modal from 'components/Modal/Modal';

import ConfigurationStep from './ConfigurationStep';
import SettingsStep from './SettingsStep';
import AuthorizationStep from './AuthorizationStep';

interface IProps {
  onClose: () => void;
  fetchWebhooks: () => void;
  // TODO: Move widget list to Context and reuse on all of the pages to avoid prop hell
  widgetsList: Array<IWidget>;
}

export enum Steps {
  CONFIGURATION = 'configuration',
  SETTINGS = 'settings',
  AUTHORIZATION = 'authorization',
}

const CreateWebhookModal = ({ onClose, widgetsList, fetchWebhooks }: IProps) => {
  const message = useMessage();
  const [configurationForm] = Form.useForm();
  const [configuration, setConfiguration] = useState({
    name: '',
    widgetUuid: '',
    webhookUrl: '',
  });
  const [settingsForm] = Form.useForm();
  const [settings, setSettings] = useState({
    topicUuid: '',
    optionUuid: '',
    requestData: ['widgetId', 'userId', 'answers'],
    answersLayout: WebhookPayloadAnswersLayout.UUIDS,
    answersType: WebhookPayloadAnswersType.CURRENT,
  });
  const [authorizationForm] = Form.useForm();
  const [step, setStep] = useState<Steps>(Steps.CONFIGURATION);
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const params: { pacId?: string } = useParams();

  const onFieldsChange = (form: FormInstance) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableNext(hasErrors);
  };

  const onPrimaryClick = async () => {
    if (step === Steps.CONFIGURATION) {
      try {
        const configurationData = await configurationForm.validateFields();
        setConfiguration(configurationData);
        settingsForm.setFieldValue('topicUuid', '');
        settingsForm.setFieldValue('optionUuid', '');
        setStep(Steps.SETTINGS);
      } catch (error) {
        showErrorResponseNotification(error);
      }
    }
    if (step === Steps.SETTINGS) {
      const settingsData = await settingsForm.validateFields();

      setSettings(settingsData);
      setStep(Steps.AUTHORIZATION);
    }
    if (step === Steps.AUTHORIZATION) {
      try {
        const authData = await authorizationForm.validateFields();

        const authObject =
          authData.type === 'basic'
            ? { username: authData?.username, password: authData?.password }
            : authData.type === 'api-key'
            ? { name: authData.name, value: authData.value, location: authData.location }
            : {};
        if (params.pacId) {
          try {
            setLoading(true);

            const response = await WebhookApiService.createWebhook(params.pacId, {
              ...configuration,
              ...settings,
              authentication: {
                enabled: authData.type !== 'no',
                ...(authData.type !== 'no' && { type: authData.type }),
                payload: authObject,
              },
            });
            message.success(`New webhook created: ${response.data ? response.data.uuid : ''}`);
            setLoading(false);
            onClose();
            fetchWebhooks();
          } catch (error) {
            const err = error as AxiosError;

            if (err.response?.data.statusCode === 422) {
              message.error('Some mandatory data was not sent');
            } else {
              showErrorResponseNotification(error);
            }
            setLoading(false);
          }
        }
      } catch (error) {
        message.warning('Please fill required fields');
      }
    }
  };

  const onSecondaryClick = () => {
    if (step === Steps.SETTINGS) {
      setDisableNext(false);
      return setStep(Steps.CONFIGURATION);
    }
    if (step === Steps.AUTHORIZATION) {
      setDisableNext(false);
      return setStep(Steps.SETTINGS);
    }
    return onClose();
  };

  return (
    <Modal
      visible
      title="Add Webhook"
      secondaryButton={{
        label: step === Steps.CONFIGURATION ? 'Cancel' : 'Back',
        onClick: onSecondaryClick,
      }}
      primaryButton={{
        label: step === Steps.AUTHORIZATION ? 'Add Webhook' : 'Next',
        onClick: onPrimaryClick,
        disabled: disableNext,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      {step === Steps.CONFIGURATION && (
        <ConfigurationStep
          form={configurationForm}
          initialValues={configuration}
          onFieldsChange={() => onFieldsChange(configurationForm)}
          widgetsList={widgetsList}
        />
      )}
      {step === Steps.SETTINGS && (
        <SettingsStep
          form={settingsForm}
          initialValues={settings}
          onFieldsChange={() => onFieldsChange(settingsForm)}
          widget={
            widgetsList.find((widget) => widget.uuid === configuration.widgetUuid) ||
            ({} as IWidget)
          }
        />
      )}
      {step === Steps.AUTHORIZATION && (
        <AuthorizationStep
          form={authorizationForm}
          initialValues={{ type: 'no' }}
          onFieldsChange={() => onFieldsChange(authorizationForm)}
        />
      )}
    </Modal>
  );
};

export default CreateWebhookModal;
