import React, { useState } from 'react';
import { message, Form, Checkbox } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import UserTransparent from 'components/Icons/UserTransparent';
import { Item, Input } from 'components/Form';
import PacService, { Pac } from 'lib/api/pacApi';
import { showErrorResponseNotification } from 'lib/helpers';
import PermissionMenu from 'components/Menu/PermissionMenu';
import { UserBlock, ButtonsWrapper, InputWrapper, OwnerWrapper } from './styled';
import useUserData from '../../../lib/hooks/useUserData';

interface IModalProps {
  onClose: () => void;
  title?: string;
  width?: number;
  primaryButtonLabel?: string;
  primaryButtonDanger?: boolean;
  hideFooterBorder?: boolean;
  isDefaultFooter?: boolean;
  usersList: Array<string>;
  owner: string;
  pac: Pac;
}

const EditPermissionsModal = ({
  onClose,
  title = 'EditPermissions',
  width = 770,
  primaryButtonLabel = 'Confirm',
  primaryButtonDanger = false,
  hideFooterBorder = true,
  isDefaultFooter = true,
  usersList,
  owner,
  pac,
}: IModalProps) => {
  const [isAddState, switchAddState] = useState(false);
  const [users, setUsers] = useState<Array<string>>(usersList);
  const [ownerOfPac, setOwnerOfPac] = useState<string>(owner);
  const [emailForm] = Form.useForm();
  const { user } = useAuth0();
  const pacUuid = pac.uuid;
  const { isSuperAdmin } = useUserData();

  const createNewUser = async () => {
    try {
      const userEmail = emailForm.getFieldValue('email');
      const notifyUser = emailForm.getFieldValue('notify');
      const response = await PacService.addUser(pacUuid, {
        newCustomerId: userEmail.toLowerCase(),
        notifyViaEmail: notifyUser,
      });
      setUsers(response.data.customerIds);
      message.success('New user successfully added.');
      emailForm.setFieldValue('email', '');
      emailForm.setFieldValue('notify', true);
      switchAddState(false);
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const onDelete = async (email: string) => {
    try {
      const response = await PacService.deleteUser(pacUuid, email);
      setUsers(response.data.customerIds);
      message.success('User successfully deleted.');
      emailForm.setFieldValue('email', '');
      emailForm.setFieldValue('notify', true);
      switchAddState(false);
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const checkIfExists = (rule: string, value: string, callback: (error?: string) => void) => {
    if (users.includes(value.toLowerCase())) {
      callback('This user already exists');
    } else {
      callback();
    }
  };

  const changeOwner = async (email: string) => {
    try {
      const response = await PacService.updateOwner(email, pacUuid);
      setOwnerOfPac(response.data.creatorId);
      setUsers([response.data.creatorId, ...users.filter((id) => id !== response.data.creatorId)]);
      message.success('Owner successfully changed.');
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  return (
    <Modal
      visible
      title={title}
      secondaryButton={{ label: 'Cancel', onClick: onClose }}
      width={width}
      bodyHeight={500}
      isConfirmation
      primaryButton={{
        label: primaryButtonLabel,
        onClick: onClose,
        danger: primaryButtonDanger,
      }}
      hideFooterBorder={hideFooterBorder}
      onClose={onClose}
      isDefaultFooter={isDefaultFooter}
    >
      <h2 data-cy="permission-title">Permissions</h2>
      <p>
        Here you can grant other users full access to your configuration and all preference data
        captured. Add users by inserting their email address.
      </p>
      <UserBlock>
        {users.map((userEmail: string) => (
          <li key={userEmail}>
            {userEmail}
            {userEmail === ownerOfPac ? (
              <OwnerWrapper>
                <UserTransparent />
                Owner
              </OwnerWrapper>
            ) : (
              (user?.email === ownerOfPac || isSuperAdmin) && (
                <PermissionMenu
                  onDelete={() => onDelete(userEmail)}
                  pac={pacUuid}
                  makeOwner={() => changeOwner(userEmail)}
                  dataCy="modal-delete-topic-button"
                />
              )
            )}
          </li>
        ))}
      </UserBlock>

      {isAddState ? (
        <InputWrapper form={emailForm} initialValues={{ email: '', notify: true }}>
          <Item
            name="email"
            data-cy="modal-edit-permissions-email-input"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
              { validator: checkIfExists },
            ]}
          >
            <Input
              onChange={(event) => {
                emailForm.setFieldValue('email', event.target.value.toLowerCase());
              }}
            />
          </Item>
          <ButtonsWrapper>
            <Item style={{ flex: 1 }} name="notify" valuePropName="checked">
              <Checkbox defaultChecked>Notify user via email</Checkbox>
            </Item>
            <Item shouldUpdate className="submit">
              {() => (
                <Button
                  onClick={() => createNewUser()}
                  type="border"
                  disabled={
                    !emailForm.isFieldsTouched(['email']) ||
                    emailForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  }
                  data-cy="modal-edit-permissions-add-user-button"
                >
                  Add User
                </Button>
              )}
            </Item>
            <Item>
              <Button
                onClick={() => {
                  emailForm.setFieldValue('email', '');
                  emailForm.setFieldValue('notify', true);
                  switchAddState(false);
                }}
                type="border"
              >
                Cancel
              </Button>
            </Item>
          </ButtonsWrapper>
        </InputWrapper>
      ) : (
        <Button
          disabled={!!pac?.deactivated && !isSuperAdmin}
          onClick={() => switchAddState(true)}
          type="border"
          data-cy="modal-edit-permissions-add-new-user-button"
        >
          Add New User
        </Button>
      )}
    </Modal>
  );
};

export default EditPermissionsModal;
