export enum Fonts {
  DEFAULT = 'System fonts (Default)',
  HELVETICA = 'Helvetica',
  VERDANA = 'Verdana',
  GEORGIA = 'Georgia',
  ARIAL = 'Arial',
}

export enum TitleFontSize {
  XS = 28,
  S = 32,
  M = 34,
  L = 36,
  XL = 38,
}

export enum WidgetFontSize {
  XS = 14,
  S = 16,
  M = 18,
  L = 20,
  XL = 22,
}
