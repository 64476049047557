import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

export default styled(TextArea)`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => props.theme.colors.primaryText};
`;
