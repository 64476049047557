import styled from 'styled-components';
// TODO: migrate app to antd ^5.0.0 so we don't need to handle two versions
import { ColorPicker } from 'antd-v5';

interface IProps {
  borderBottom?: boolean;
}

export const Container = styled.div`
  width: 740px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  margin-bottom: 40px;
`;

export const SectionWrapper = styled.div<IProps>`
  padding: 20px;

  & h2 {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: 600;
    margin-bottom: 10px;
  }

  & span {
    color: ${(props) => props.theme.colors.primaryIcons};
  }

  ${(props) => props.borderBottom && `border-bottom: 1px solid ${props.theme.colors.gray}`}
`;

export const SectionTitle = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: 500;
`;

export const CustomLabel = styled.p`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FlexChild = styled.div`
  width: 49%;

  input {
    height: 40px;
  }
`;

export const StyledColorPicker = styled(ColorPicker)`
  height: 40px;
  padding: 0 11px;

  .ant-color-picker-color-block {
    width: 27px !important;
  }
`;
