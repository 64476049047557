/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, List, Tooltip as TooltipAntd } from 'antd';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Title, Description, Flex } from 'shared-components';
import { isNotEmptyField } from 'lib/regex/validations';
import { ITopic, IWidget } from 'lib/interfaces/widget';
import Button from 'components/Button/Button';
import { Item, Input, Tooltip } from 'components/Form';
import { StyledSelect as Select } from 'components/Select/Select';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Add as AddIcon,
  TrashCan as TrashCanIcon,
  DragIndicator as DragIndicatorIcon,
  Translate as TranslateIcon,
} from 'components/Icons';
import { useMessage } from 'lib/hooks';
import {
  TopicsButton,
  TopicsForm,
  Container,
  OptionWrapper,
  OptionsHeader,
  AddButton,
  CustomLabel,
  DetailsHeader,
  TopicDragWrapper,
  InputWrapper,
} from './styled';
import TopicsTranslation from './TopicsTranslation';
import useUserData from '../../../../lib/hooks/useUserData';

interface IProps {
  edit?: boolean;
  answers?: Array<{ topicUuid: string; answers: {} }>;
  form: FormInstance<ITopic>;
  topics: Array<ITopic>;
  updateTopics: (topics: Array<ITopic>) => void;
  onFieldsChange: () => void;
  editableLanguages?: string[];
  languageList: { [key: string]: string };
  widget?: IWidget;
  fetchWidgets?: () => Promise<void> | void;
  isPacDeactivated: boolean;
  isPacLite:boolean;
}

enum DeletionType {
  OPTION = 'option',
  TOPIC = 'topic',
}

enum SelectionTypes {
  CHECKBOX = 'multi_checkbox',
  RADIO = 'radio',
  SELECT = 'select',
}

const Topics = ({
  form,
  topics,
  updateTopics,
  onFieldsChange,
  answers,
  edit,
  editableLanguages,
  languageList,
  widget,
  fetchWidgets,
  isPacDeactivated,
  isPacLite
}: IProps) => {
  const message = useMessage();
  const [topicToEdit, setTopicToEdit] = useState<ITopic | null>(topics[0] || null);
  const [isAddTopicMode, setAddTopicMode] = useState(topics.length === 0);
  const [isTopicDisabled, setTopicDisabled] = useState(topics.length === 0);
  const [isTopicEditingDisabled, disableTopicEditing] = useState(false);
  const [removeOption, setRemoveOption] = useState<number>();
  const [confirmDeletion, setConfirmDeletion] = useState<DeletionType | false>(false);
  const [isTranslatingWidget, setIsTranslatingWidget] = useState<boolean>(false);
  const [isFormBeingChanged, setIsFormBeingChanged] = useState<boolean>(false);
  const [iconTooltipZindex, setIconTooltipZindex] = useState<number>(1000);
  const { isSuperAdmin } = useUserData();

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const clonedListItems = cloneDeep(topics);
    const [reorderedItem] = clonedListItems.splice(result.source.index, 1);
    clonedListItems.splice(result.destination.index, 0, reorderedItem);

    updateTopics(clonedListItems);
  };

  const handleOnDragEndOptions = (result: DropResult, move: (from: number, to: number) => void) => {
    if (!result.destination) return;

    if (topicToEdit) {
      const topicById = topics.find((topic) => topic.uuid === topicToEdit.uuid);
      if (!topicById) return;

      const clonedListItems = cloneDeep(topicById);
      const [reorderedItem] = clonedListItems.options.splice(result.source.index, 1);

      move(result.source.index, result.destination.index);
      clonedListItems.options.splice(result.destination.index, 0, reorderedItem);

      updateTopics(
        topics.map((topic) => (topic.uuid === topicToEdit.uuid ? clonedListItems : topic)),
      );
    }
  };

  useEffect(() => {
    if (topicToEdit) {
      topics.forEach((topic) => {
        if (topic.uuid === topicToEdit.uuid) {
          setTopicToEdit(topic);
          form.setFieldsValue(topic || null);
        }
      });
    }
  }, [topics]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (answers && answers.length > 0) {
      const topicId = form.getFieldValue('uuid');
      const answerForThisTopic = answers.filter((answer) => answer.topicUuid === topicId)[0];

      if (answerForThisTopic) {
        // Check if there are any values for answers object that is inside of selected Topic
        return Object.keys(answerForThisTopic.answers).length > 0
          ? disableTopicEditing(true)
          : disableTopicEditing(false);
      }
      // Default fallback for empty or new topics
      return disableTopicEditing(false);
    }
  }, [topicToEdit, answers]);

  const deleteTopic = async () => {
    const formValues = await form.validateFields();
    const updatedTopics = topics.filter((el) => el.uuid !== formValues.uuid);
    updateTopics(updatedTopics);
    form.setFieldsValue(topics[0] || null);
    setTopicToEdit(topics[0] || null);
  };

  const onValuesChange = async () => {
    try {
      const formValues = await form.validateFields();
      setIsFormBeingChanged(true);
      if (isAddTopicMode) {
        const newValue = {
          ...formValues,
          uuid: uuidv4(),
          isNew: true,
        };
        updateTopics([...topics, newValue]);
        form.setFieldsValue(newValue);
        setAddTopicMode(false);
        setTopicToEdit(newValue);
        setTopicDisabled(false);
        return;
      }

      if (topicToEdit) {
        // When updating topics at a higher level, the formValues.options after being removed still has the deleted option
        // but with the values as undefined. This reduce updates the reference and makes the delete option work properly.
        const filteredOptions = formValues.options.filter((option) => option.uuid !== undefined);
        form.setFieldsValue({ options: filteredOptions });

        updateTopics(
          topics.map((topic) =>
            topic.uuid === formValues.uuid ? { ...formValues, options: filteredOptions } : topic,
          ),
        );
      }
    } catch (error) {
      // TODO: remove this temporary solution (check logic of setting data in this modal)
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  const selectTopic = (topic: ITopic) => {
    if (!isTopicDisabled) {
      form.setFieldsValue({
        ...topic,
        description: topic.description || '',
        uuid: topic.uuid,
        required: !!topic?.required,
      });
      setTopicToEdit(topic);
    }
  };

  const onCloseTranslationModel = (IsTranslatingWidget: boolean) => {
    setIsTranslatingWidget(IsTranslatingWidget);
    if (fetchWidgets) {
      fetchWidgets();
    }
  };

  const markOptionAsDefault = async (index: number, selected: boolean) => {
    try {
      const formValues = await form.validateFields();
      onValuesChange();
      if (formValues.selectType !== SelectionTypes.CHECKBOX && selected) {
        const updatedOptions = formValues.options.map((option, id) => ({
          ...option,
          default: id === index,
        }));
        form.setFieldsValue({ options: updatedOptions });

        updateTopics(
          topics.map((topic) =>
            topic.uuid === formValues.uuid ? { ...formValues, options: updatedOptions } : topic,
          ),
        );
      } else {
        const updatedOptions = formValues.options.map((option, id) => ({
          ...option,
          default: id === index ? selected : option.default,
        }));
        form.setFieldsValue({ options: updatedOptions });
        updateTopics(
          topics.map((topic) =>
            topic.uuid === formValues.uuid ? { ...formValues, options: updatedOptions } : topic,
          ),
        );
      }
    } catch (error) {
      // TODO: remove this temporary solution (check logic of setting data in this modal)
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  return (
    <>
      <Title>Topics</Title>
      <Description>Here you can add and configure widget topics.</Description>
      <Container justifyContent="space-between">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div>
            {topics.length > 0 && (
              <Droppable droppableId="DropId">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    data-cy="modal-menu-topics"
                  >
                    <List
                      rowKey="uuid"
                      dataSource={topics}
                      size="small"
                      renderItem={(item, index) => (
                        <Draggable draggableId={`draggable-${index}`} index={index}>
                          {(providedItem) => (
                            <TopicDragWrapper
                              ref={providedItem.innerRef}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                            >
                              <DragIndicatorIcon />
                              <TopicsButton
                                data-cy={`modal-topic-list-item-${item.uuid}`}
                                key={item.uuid}
                                selected={!isAddTopicMode && topicToEdit?.uuid === item.uuid}
                                disabled={isTopicDisabled}
                                onClick={() => selectTopic(item)}
                              >
                                <p>{item.name}</p>
                              </TopicsButton>
                            </TopicDragWrapper>
                          )}
                        </Draggable>
                      )}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
            {isAddTopicMode && (
              <TopicsButton
                selected={isAddTopicMode}
                onClick={() => {
                  if (!isTopicDisabled) {
                    setTopicToEdit(null);
                  }
                }}
                addButton
              >
                Topic
              </TopicsButton>
            )}
            <Button
              data-cy="modal-add-topic-button"
              Icon={<AddIcon />}
              type="white"
              disabled={(isPacDeactivated && !isSuperAdmin) || isTopicDisabled ||isPacLite}
              style={{ marginLeft: '16px' }}
              onClick={() => {
                if (!isTopicDisabled) {
                  setAddTopicMode(true);
                  setTopicDisabled(true);
                  setTopicToEdit(null);
                  form.resetFields();
                }
              }}
            >
              Add Topic
            </Button>
          </div>
        </DragDropContext>
        {(isAddTopicMode || topics.length > 0) && (
          <TopicsForm
            key={topicToEdit?.uuid}
            form={form}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
            initialValues={{
              options: [{ name: '', uuid: uuidv4() }],
            }}
            colon={false}
          >
            <DetailsHeader>
              <CustomLabel>Details</CustomLabel>
              {edit && !isTopicDisabled && editableLanguages && editableLanguages.length > 1 && (
                <div
                  style={{
                    padding: '0 8px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TooltipAntd title="Translate" placement="top" zIndex={iconTooltipZindex}>
                    <TranslateIcon
                      data-cy="modal-translate-topic-button"
                      fill="#303030"
                      onMouseOver={() => {
                        setIconTooltipZindex(1000);
                      }}
                      onClick={() => {
                        if (isFormBeingChanged) {
                          message.error(
                            'Please save any unsaved changes before opening the translation modal',
                          );
                          return;
                        }
                        setIconTooltipZindex(1);
                        setIsTranslatingWidget(true);
                      }}
                    />
                  </TooltipAntd>
                </div>
              )}
              {topics.length > 1 && (
                <Button
                  data-cy="modal-delete-topic-button"
                  onClick={() => (edit ? setConfirmDeletion(DeletionType.TOPIC) : deleteTopic())}
                  htmlType="button"
                  style={{ width: '20px', height: '20px', border: 'none' }}
                  type="border"
                  disabled={isTopicEditingDisabled}
                >
                  <TrashCanIcon />
                </Button>
              )}
            </DetailsHeader>
            <Item label="uuid" name="uuid" style={{ display: 'none' }}>
              <Input type="text" />
            </Item>
            <Item
              required
              label="Name"
              tooltip={{
                title:
                  'The topic name you want to capture preferences about: e.g. "Newsletter topics", "Communication frequency", "Your favorite brands", etc.',
              }}
              name="name"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
            >
              <Input data-cy="modal-topic-name-input" placeholder="Name" />
            </Item>
            <Item
              tooltip={{
                title:
                  'Additional information about this topic - why do you capture this data, how it will benefit your users, etc.',
              }}
              label="Description"
              name="description"
              data-cy="modal-topic-description-label"
            >
              <ReactQuill
                modules={{
                  toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
                }}
                formats={['bold', 'italic', 'underline', 'strike', 'link']}
                placeholder="Description (optional)"
                data-cy="modal-topic-description-input"
                theme="snow"
              />
            </Item>
            <Item
              required
              tooltip={{
                title: 'Type of selection the user will use to specify their choice',
              }}
              label="Selection Type"
              name="selectType"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Selection type required' }]}
            >
              <Select
                placeholder="Select Type"
                disabled={isTopicEditingDisabled}
                data-cy="modal-topic-selection-type-input"
                // Info: workaround to clear all defaults if type is not Checkbox
                onChange={() => markOptionAsDefault(-1, true)}
              >
                <Select.Option key={SelectionTypes.CHECKBOX} value={SelectionTypes.CHECKBOX}>
                  Multi Selection (Checkbox)
                </Select.Option>
                <Select.Option key={SelectionTypes.RADIO} value={SelectionTypes.RADIO}>
                  Single Selection (Radio)
                </Select.Option>
                <Select.Option key={SelectionTypes.SELECT} value={SelectionTypes.SELECT}>
                  Single Selection (Drop-Down)
                </Select.Option>
              </Select>
            </Item>
            <Flex alignItems="center">
              <Item
                required
                name="required"
                validateTrigger="onBlur"
                rules={[{ required: false }]}
                style={{ margin: 0 }}
                valuePropName="checked"
              >
                <Checkbox style={{ fontWeight: 400 }} data-cy="modal-topic-required-input">
                  Mark topic as mandatory
                </Checkbox>
              </Item>
              <Tooltip
                title="If checked, users must make a choice in this topic in order to save their preference under overall widget."
                placement="right"
              />
            </Flex>
            <Item
              label="Preference Options"
              tooltip={{
                title:
                  'Create topics for users to choose from. For example: Company Updates, Product Updates, Event News, etc.',
              }}
            >
              <Form.List name="options">
                {(options, { add, remove, move }) => {
                  return (
                    <Flex flexDirection="column">
                      <OptionsHeader>
                        <span
                          style={{
                            marginLeft: '15px',
                          }}
                        >
                          Items
                        </span>
                        <span>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Default
                          </span>
                          {options.length > 1 && <span>Actions</span>}
                        </span>
                      </OptionsHeader>
                      <DragDropContext onDragEnd={(result) => handleOnDragEndOptions(result, move)}>
                        <Droppable droppableId="DropId2">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <List
                                rowKey="key"
                                dataSource={options}
                                renderItem={(item, index) => (
                                  <Draggable draggableId={`draggable-${index}`} index={index}>
                                    {(providedElement) => (
                                      <div
                                        ref={providedElement.innerRef}
                                        {...providedElement.draggableProps}
                                        {...providedElement.dragHandleProps}
                                      >
                                        <OptionWrapper key={item.key}>
                                          <InputWrapper
                                            style={{
                                              width: options.length <= 1 ? '100%' : '85%',
                                            }}
                                          >
                                            <DragIndicatorIcon />
                                            <Item
                                              name={[index, 'name']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Preference option name required',
                                                },
                                                isNotEmptyField,
                                              ]}
                                            >
                                              <Input
                                                data-cy={`modal-topic-option-${index}-input`}
                                                placeholder="Option"
                                                onBlur={onValuesChange}
                                              />
                                            </Item>
                                          </InputWrapper>
                                          <Item
                                            required
                                            name={[index, 'default']}
                                            validateTrigger="onBlur"
                                            style={{
                                              marginLeft: '25px',
                                              marginRight: '15px',
                                              width: '30px',
                                              height: '100%',
                                            }}
                                            valuePropName="checked"
                                          >
                                            <Checkbox
                                              style={{ fontWeight: 400 }}
                                              checked={form.getFieldValue(
                                                `options[${index}].default`,
                                              )}
                                              onChange={(value) =>
                                                markOptionAsDefault(index, value.target.checked)
                                              }
                                            />
                                          </Item>
                                          {options.length > 1 && (
                                            <Button
                                              data-cy={`modal-topic-option-${index}-delete-button`}
                                              onClick={() => {
                                                if (edit) {
                                                  setRemoveOption(item.name);
                                                  setConfirmDeletion(DeletionType.OPTION);
                                                  return;
                                                }
                                                remove(item.name);
                                              }}
                                              htmlType="button"
                                              type="border"
                                              disabled={isTopicEditingDisabled}
                                              style={{
                                                marginRight: '5px',
                                              }}
                                            >
                                              <TrashCanIcon />
                                            </Button>
                                          )}
                                          <Form.Item
                                            label="uuid"
                                            name={[index, 'uuid']}
                                            style={{ display: 'none' }}
                                          >
                                            <Input type="text" />
                                          </Form.Item>
                                          {confirmDeletion === 'option' && (
                                            <DeleteConfirmation
                                              onDelete={() => {
                                                if (removeOption !== undefined) {
                                                  remove(removeOption);
                                                  setConfirmDeletion(false);
                                                  setRemoveOption(undefined);
                                                }
                                              }}
                                              onClose={() => setConfirmDeletion(false)}
                                              text="Are you sure you want to delete this preference option?"
                                            />
                                          )}
                                        </OptionWrapper>
                                      </div>
                                    )}
                                  </Draggable>
                                )}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Form.Item>
                        <AddButton
                          disabled={(isPacDeactivated && !isSuperAdmin) || isPacLite}
                          data-cy="modal-topic-option-add-button"
                          onClick={() => add({ name: '', uuid: uuidv4() })}
                          type="border"
                          htmlType="submit"
                        >
                          <PlusOutlined /> Add Option
                        </AddButton>
                      </Form.Item>
                    </Flex>
                  );
                }}
              </Form.List>
            </Item>
          </TopicsForm>
        )}
        {isTranslatingWidget && (
          <TopicsTranslation
            widget={widget}
            topicToEdit={topicToEdit}
            setIsTranslatingWidget={onCloseTranslationModel}
            editableLanguages={editableLanguages}
            languageList={languageList}
            isPacDeactivated={isPacDeactivated}
          />
        )}
      </Container>
      {confirmDeletion === 'topic' && (
        <DeleteConfirmation
          onDelete={() => {
            deleteTopic();
            setConfirmDeletion(false);
          }}
          onClose={() => setConfirmDeletion(false)}
          text="Are you sure you want to delete this topic?"
        />
      )}
    </>
  );
};

export default Topics;
