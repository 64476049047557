import { Item } from 'components/Form';
import styled from 'styled-components';

export const SettingsSectionWrapper = styled.div`
  display: flex;

  & p {
    width: 35%;
    margin-right: 30px;
  }
`;

export const InputsWrapper = styled.div`
  width: 100%;

  & .ant-select-selection-overflow-item {
    // Needed to overwrite antd styles
    width: auto !important;
  }
`;

export const StyledItem = styled(Item)`
  max-width: 490px;
`;

export const Label = styled.span`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
  height: 32px;
`;
