import { AxiosResponse } from 'axios';
import { AbstractApiService } from './abstractApiService';
import { Axios } from './axiosInstance';
import { ILocalization } from '../interfaces/widget';

export interface Option {
  uuid: string;
  name: string;
}

export interface TranslationObject {
  key: string;
  value: string | undefined;
}

export interface TranslateTopic {
  uuid?: number | string;
  name: string;
  description?: string;
}

export interface WidgetTranslation {
  uuid?: string;
  widgetUuid?: string;
  language?: string;
  title?: ILocalization | string;
  description?: ILocalization | string;
  additionalNote?: ILocalization | string;
  topics?: Array<TranslationObject> | Array<TranslateTopic> | undefined;
  options?: Array<TranslationObject> | Array<Option> | undefined;
  createdAt?: string;
  updatedAt?: string;
}

class WidgetTranslationApi extends AbstractApiService {
  create(
    widgetId: string,
    newWidgetTranslation: WidgetTranslation,
  ): Promise<AxiosResponse<WidgetTranslation>> {
    return Axios.post(
      `widget-translations/${widgetId}`,
      newWidgetTranslation,
      this.getApiHeaders(),
    );
  }

  getByLanguageKey(
    widgetId: string,
    languageKey: string,
  ): Promise<AxiosResponse<WidgetTranslation>> {
    return Axios.get(`widget-translations/${widgetId}/${languageKey}`, this.getApiHeaders());
  }

  update(
    translationUuid: string,
    widgetTranslsation: WidgetTranslation,
  ): Promise<AxiosResponse<WidgetTranslation>> {
    return Axios.patch(
      `widget-translations/${translationUuid}`,
      widgetTranslsation,
      this.getApiHeaders(),
    );
  }

  getLanguages(): Promise<AxiosResponse<{ [key: string]: string }>> {
    return Axios.get('widget-translations/languages', this.getApiHeaders());
  }
}

export default new WidgetTranslationApi();
