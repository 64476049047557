import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ColumnConfig } from '@ant-design/plots';
import { useMessage } from 'lib/hooks';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Spin from 'components/Spin';
import AnalyticsApiService, { AnalyticsOverview, TotalPerPac } from '../../lib/api/analyticsApi';
import { PageTitle, PageWrapper } from './styled';
import OverviewStats from './OverviewStats';
import PacsStats from './PacsStats';
import WidgetStats from './WidgetStats';

const Analytics = () => {
  const [analyticOverview, setAnalyticOverview] = useState<AnalyticsOverview>({
    totalPreferences: 0,
    totalUniqueUsers: 0,
    totalsPerPac: [],
  });

  const [preferencesPerConfiguration, setPreferencesPerConfiguration] = useState<ColumnConfig>({
    data: [],
    xField: 'Title',
    yField: 'Total',
    seriesField: 'Title',
    legend: false,
    xAxis: {
      label: {
        formatter: (value: string) => {
          return `${value.substring(0, 7)}...`;
        },
      },
    },
  });

  const [preferencesPerWidget, setPreferencesPerWidget] = useState<ColumnConfig>({
    data: [],
    xField: 'Title',
    yField: 'Total',
    seriesField: 'Title',
    legend: false,
    xAxis: {
      label: {
        formatter: (value: string) => {
          return `${value.substring(0, 7)}...`;
        },
      },
    },
  });
  const message = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showOnlyOwnedPacs, setShowOnlyOwnedPacs] = useState<boolean>(true);
  const [selectedPacIndex, setSelectedPacIndex] = useState<number>(0);
  const [selectedWidgetIndex, setSelectedWidgetIndex] = useState<number>(0);
  const [tableDataSource, setTableDataSource] = useState<Array<{}>>([]);
  const [selectedPac, setSelectedPac] = useState<TotalPerPac>({} as TotalPerPac);

  function updatePreferencesPerWidgetData(data: AnalyticsOverview, selectedIndex: number) {
    setPreferencesPerWidget({
      ...preferencesPerWidget,
      data: data.totalsPerPac[selectedIndex].statsPerWidget.map((pacData) => ({
        Title: pacData?.title,
        Total: pacData?.totalPreferences || 0,
      })),
    });
  }

  function updateWidgetData(data: AnalyticsOverview, pacIndex: number, widgetIndex: number) {
    const dataSource: Array<{}> = [];
    data.totalsPerPac[pacIndex].statsPerWidget[widgetIndex]?.topicsStats.forEach((topicStats) => {
      topicStats.options.forEach((option) => {
        dataSource.push({
          key: option.uuid,
          option: option.name,
          topic: topicStats.name,
          optIn: option.optIn,
          optInRate: option.optInRate.toFixed(2),
        });
      });
    });

    setTableDataSource(dataSource);
  }

  const updateWidgetIndex = (value: number) => {
    setSelectedWidgetIndex(value);
    updateWidgetData(analyticOverview, selectedPacIndex, value);
  };

  const updatePacIndex = (value: number): void => {
    setSelectedPacIndex(value);
    updatePreferencesPerWidgetData(analyticOverview, value);

    setSelectedWidgetIndex(0);
    updateWidgetData(analyticOverview, value, 0);
  };

  const fetchData = async (overrideShowOnlyOwnedPacs: boolean) => {
    try {
      const { data } = await AnalyticsApiService.getOverview(overrideShowOnlyOwnedPacs);
      setAnalyticOverview(data);
      setPreferencesPerConfiguration({
        ...preferencesPerConfiguration,
        data: data.totalsPerPac.map((pacData) => ({
          Title: pacData?.title,
          Total: pacData?.totalPreferences || 0,
        })),
      });

      if (data.totalsPerPac.length > 0) {
        updatePreferencesPerWidgetData(data, 0);
      }
      updateWidgetData(data, 0, 0);
      setSelectedPacIndex(0);
    } catch (err: any) {
      if (err.response.status === 404) {
        message.warning('No configurations found');
      } else {
        message.error('Something went wrong, please try again later');
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 50);
    }
  };

  const onOnlyShowOwnedPreferenceChange = (e: CheckboxChangeEvent): void => {
    setShowOnlyOwnedPacs(e.target.checked);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(showOnlyOwnedPacs);
  }, [showOnlyOwnedPacs]);

  useEffect(() => {
    setSelectedPac(analyticOverview.totalsPerPac[selectedPacIndex]);
  }, [analyticOverview, selectedPacIndex]);

  return (
    <div>
      <PageTitle>Analytics</PageTitle>
      {isLoading ? (
        <Spin />
      ) : (
        <PageWrapper>
          <OverviewStats
            showOnlyOwnedPacs={showOnlyOwnedPacs}
            onOnlyShowOwnedPreferenceChange={onOnlyShowOwnedPreferenceChange}
            analyticOverview={analyticOverview}
            preferencesPerConfiguration={preferencesPerConfiguration}
            preferencesPerWidget={preferencesPerWidget}
          />

          {analyticOverview.totalsPerPac.length > 1 && (
            <PacsStats
              updatePacIndex={updatePacIndex}
              analyticOverview={analyticOverview}
              preferencesPerWidget={preferencesPerWidget}
              selectedPacIndex={selectedPacIndex}
            />
          )}
          {selectedPac?.statsPerWidget?.length > 0 && (
            <WidgetStats
              updateWidgetIndex={updateWidgetIndex}
              selectedPacIndex={selectedPacIndex}
              selectedWidgetIndex={selectedWidgetIndex}
              analyticOverview={analyticOverview}
              tableDataSource={tableDataSource}
            />
          )}
        </PageWrapper>
      )}
    </div>
  );
};

export default Analytics;
