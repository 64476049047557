import React, { useEffect, useState } from 'react';
import { Checkbox, Form } from 'antd';

import { ITopic, IWidget } from 'lib/interfaces/widget';
import { ICmpIntegration } from 'lib/interfaces/cmpIntegration';
import useMessage from 'lib/hooks/useMessage';
import { showErrorResponseNotification } from 'lib/helpers';
import { StyledSelect as Select } from 'components/Select/Select';

import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';

import Modal from 'components/Modal/Modal';

import { Description, Title } from 'shared-components/ModalsContent';
import { Item, Input } from 'components/Form';
import { isNotEmptyField } from '../../../lib/regex/validations';

interface IProps {
  onClose: () => void;
  pacUuid: string;
  widgetsList: Array<IWidget>;
  selectedIntegration?: ICmpIntegration;
}

const CreateUsercentricsCMPModal = ({
  onClose,
  widgetsList,
  pacUuid,
  selectedIntegration,
}: IProps) => {
  const message = useMessage();
  const [loading, setLoading] = useState(false);
  const [mandatoryConsentSource, setMandatoryConsentSource] = useState([
    { required: false, message: 'This field is required' },
    isNotEmptyField,
  ]);

  const [form] = Form.useForm();
  const [selectedWidget, setSelectedWidget] = useState<IWidget | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<ITopic | null>(null);
  const [disableSave, setDisableSave] = useState(true);
  const [isConsentRequired, setIsConsentRequired] = useState(false);
  const [shouldConsentSourceBeDisabled, setShouldConsentSourceBeDisabled] = useState(
    !!selectedIntegration,
  );

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleConsentRequiredChanged = async () => {
    const consentRequired = form.getFieldValue('consentRequired');
    setMandatoryConsentSource([
      { required: consentRequired, message: 'This field is required' },
      isNotEmptyField,
    ]);

    setIsConsentRequired(consentRequired);
    try {
      await form.validateFields();
    } catch (e) {
      console.error(e);
    }

    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleWidgetChange = (widgetUuId: any) => {
    const foundWidget = widgetsList.find((widget) => widget.uuid === widgetUuId);
    setSelectedWidget(foundWidget || null);
    setSelectedTopic(null);
    form.setFieldValue(['consentSource', 'optionUuid'], null);
    form.setFieldValue(['consentSource', 'topicUuid'], null);
  };

  const handleTopicChange = (topicUuid: any) => {
    const foundTopic = selectedWidget?.topics.find((topic) => topic.uuid === topicUuid);
    setSelectedTopic(foundTopic || null);
    form.setFieldValue(['consentSource', 'optionUuid'], null);
  };

  const onPrimaryClick = async () => {
    try {
      const formData = await form.validateFields();

      try {
        setLoading(true);
        let response;

        if (selectedIntegration) {
          response = await UsercentricsCmpIntegrationApiService.edit(selectedIntegration.uuid, {
            ...selectedIntegration,
            ...formData,
            pacUuid: undefined,
            uuid: undefined,
          });
          message.success(`Integration updated: ${response.data ? response.data.uuid : ''}`);
        } else {
          response = await UsercentricsCmpIntegrationApiService.create({
            pacUuid,
            ...formData,
          });
          message.success(`New integration created: ${response.data ? response.data.uuid : ''}`);
        }

        setLoading(false);
        onClose();
      } catch (error) {
        showErrorResponseNotification(error);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (selectedIntegration) {
      if (!selectedIntegration.consentSource) {
        setShouldConsentSourceBeDisabled(false);
        return;
      }

      setIsConsentRequired(!!selectedIntegration.consentRequired);

      const foundWidget = widgetsList.find(
        (widget) => widget.uuid === selectedIntegration?.consentSource?.widgetUuid,
      );
      setSelectedWidget(foundWidget || null);

      const foundTopic = foundWidget?.topics.find(
        (topic) => topic.uuid === selectedIntegration?.consentSource?.topicUuid,
      );
      setSelectedTopic(foundTopic || null);
    }
  }, [selectedIntegration]);

  return (
    <Modal
      visible
      title="Connect Usercentrics CMP"
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      primaryButton={{
        label: selectedIntegration ? 'Update' : 'Save',
        onClick: onPrimaryClick,
        disabled: disableSave,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      <Title>Connect your CMP Configurations</Title>
      <Description>
        Configure CMP integration by providing CMP Configuration ID and the source of end-user
        consent that is required to link their User ID and CMP session ID
      </Description>
      <Form
        name="usercentrics-cmp"
        form={form}
        initialValues={{
          title: selectedIntegration?.title || null,
          settingsId: selectedIntegration?.settingsId || null,
          consentRequired: selectedIntegration?.consentRequired || false,
          consentSource: {
            widgetUuid: selectedIntegration?.consentSource?.widgetUuid || null,
            topicUuid: selectedIntegration?.consentSource?.topicUuid || null,
            optionUuid: selectedIntegration?.consentSource?.optionUuid || null,
          },
        }}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
          style={{ marginBottom: '12px' }}
        >
          <Input placeholder="Title" data-cy="cmp-new-configuration-title-input" />
        </Item>
        <Item
          label="CMP Configuration ID"
          name="settingsId"
          rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
          style={{ marginBottom: '12px' }}
        >
          <Input
            placeholder="Enter the ID of your CMP configuration"
            data-cy="cmp-new-configuration-settingId-input"
          />
        </Item>
        <Item
          name="consentRequired"
          style={{ marginBottom: '24px' }}
          onChange={handleConsentRequiredChanged}
          valuePropName="checked"
        >
          <Checkbox
            style={{ fontWeight: 400 }}
            data-cy="cmp-new-configuration-consentRequired-checkbox"
          >
            Sync CMP data only when end-user consent given
          </Checkbox>
        </Item>
        {isConsentRequired && (
          <Item label="Select consent source">
            <Item
              name={['consentSource', 'widgetUuid']}
              rules={mandatoryConsentSource}
              style={{ width: '32%' }}
            >
              <Select
                placeholder="Select Widget"
                data-cy="cmp-new-configuration-widgetUuid-select"
                onChange={handleWidgetChange}
                disabled={shouldConsentSourceBeDisabled}
              >
                {widgetsList.map((widget) => (
                  <Select.Option key={widget.uuid} value={widget.uuid}>
                    {widget.title}
                  </Select.Option>
                ))}
              </Select>
            </Item>
            <Item
              style={{ marginLeft: '20px', width: '32%' }}
              name={['consentSource', 'topicUuid']}
              rules={mandatoryConsentSource}
              key={selectedWidget?.uuid}
            >
              <Select
                disabled={!selectedWidget || shouldConsentSourceBeDisabled}
                placeholder="Select Topic"
                data-cy="cmp-new-configuration-widgetUuid-select"
                onChange={handleTopicChange}
              >
                {selectedWidget &&
                  selectedWidget.topics.map((topic) => (
                    <Select.Option key={topic.uuid} value={topic.uuid}>
                      {topic.name}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
            <Item
              style={{ marginLeft: '20px', width: '32%' }}
              name={['consentSource', 'optionUuid']}
              rules={mandatoryConsentSource}
              key={selectedTopic?.uuid}
            >
              <Select
                disabled={!selectedTopic || shouldConsentSourceBeDisabled}
                placeholder="Select Option"
                data-cy="cmp-new-configuration-widgetUuid-select"
              >
                {selectedTopic &&
                  selectedTopic.options.map((option) => (
                    <Select.Option key={option.uuid} value={option.uuid}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateUsercentricsCMPModal;
