/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Item, Input } from 'components/Form';
import { Form, FormInstance } from 'antd';
import { Translate as TranslateIcon } from 'components/Icons';
import { isNotEmptyField } from 'lib/regex/validations';
import 'react-quill/dist/quill.snow.css';
import { TranslationButton, StyledQuill } from './styled';

interface IPacInformationForm {
  detailsForm: FormInstance<{ name: string; description: string }>;
  onFieldsChange: () => void;
  onTranslate: (type: string, useHtmlEditor?: boolean) => void;
  showTranslationButton?: boolean;
}

const PacInformationForm = ({
  detailsForm,
  onFieldsChange,
  onTranslate,
  showTranslationButton = false,
}: IPacInformationForm) => {
  const onTranslateButtonClicked = (ev: any, translateTitle: string, useHtmlEditor = false) => {
    ev.preventDefault();
    onTranslate(translateTitle, useHtmlEditor);
  };
  return (
    <Form
      name="pac-details"
      form={detailsForm}
      initialValues={{
        title: '',
        description: '',
      }}
      onFieldsChange={onFieldsChange}
      style={{ padding: '16px', position: 'relative' }}
    >
      {showTranslationButton && (
        <TranslationButton
          onClick={(ev) => onTranslateButtonClicked(ev, 'title')}
          data-cy="configuration-pac-title-translation-button"
        >
          <TranslateIcon fill="#0045a5" /> Translate
        </TranslationButton>
      )}

      <Item
        label="Title"
        name="title"
        data-cy="configuration-pac-name-label"
        rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
        style={{ marginBottom: '24px' }}
      >
        <Input placeholder="Product Preferences" data-cy="configuration-pac-name-input" />
      </Item>
      {showTranslationButton && (
        <TranslationButton
          onClick={(ev) => onTranslateButtonClicked(ev, 'description', true)}
          data-cy="configuration-pac-description-translation-button"
        >
          <TranslateIcon fill="#0045a5" /> Translate
        </TranslationButton>
      )}
      <Item
        colon={false}
        label="Description"
        name="description"
        data-cy="configuration-pac-description-label"
      >
        <StyledQuill
          modules={{
            toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
          }}
          formats={['bold', 'italic', 'underline', 'strike', 'link']}
          placeholder="Specify description text for your Preference Management Center"
          theme="snow"
          style={{ fontSize: '14px' }}
          data-cy="configuration-pac-description-input"
        />
      </Item>
    </Form>
  );
};

export default PacInformationForm;
