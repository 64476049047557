import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    width: calc(50% - 8px);
  }
`;

export const Description = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 24px;
`;
