import React from 'react';
import { Tooltip } from 'antd';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import CodeSnippet from 'components/CodeSnippet';
import artBoardImage from 'assets/ArtBoard.svg';
import { Pac } from 'lib/api/pacApi';

import { Container, Description, Title, TitleWrapper } from './styled';

import SidebarButtons from '../SidebarButtons';

interface IProps {
  pacDetails?: Pac;
}

const ImplementationTab = ({ pacDetails }: IProps) => {
  const isLite = pacDetails?.type === 'lite';

  const widgetId = pacDetails?.widgetUuids[0] || '[WIDGET_ID]';
  const pacId = pacDetails?.uuid || '[PAC_ID]';
  const optionId = pacDetails?.widgets[0]?.topics[0]?.options[0]?.uuid || '[OPTION_ID]';
  const apiKey = '[API_KEY]';

  return (
    <div>
      <EmptyListPlaceholder
        title="Implementation"
        image={artBoardImage}
        description={
          <span>
            <p>
              The UC Preference Manager offers flexible integration options between your application
              and our platform including using standalone Preference Center page, embedded widgets,
              or using your own custom UI - please refer to our{' '}
              <strong>
                <a
                  href="https://docs.usercentrics.com/#/preference-manager"
                  target="_blank"
                  rel="noreferrer"
                >
                  documentation
                </a>
              </strong>{' '}
              for detailed information. <br />
              <br />
              To utilize our public API, you will require a secret API key that can be generated
              under the{' '}
              <strong>
                <a
                  href={`${window.location.origin}/account-settings`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Account Settings
                </a>
              </strong>{' '}
              page.
            </p>
          </span>
        }
      />

      {!isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Embedded widgets</Title>
          </TitleWrapper>
          <Description>
            Integrate specific widgets directly into your website using the following code snippet:
          </Description>
          <CodeSnippet
            copyContent={`<div
  class="embedded-preference-widget"
  data-token="[JWT_TOKEN]"
  data-widgetid="[WIDGET_ID]"
  data-language="[LANG_CODE]">
</div>
<link
  href="https://embeddings.preference-management.usercentrics.eu/latest/styles.css"
  rel="stylesheet">
<script
  src="https://embeddings.preference-management.usercentrics.eu/latest/bundle.js">
</script>`}
          >
            {'<div'}
            <br />
            &nbsp;&nbsp;class=&quot;embedded-preference-widget&quot;
            <br />
            &nbsp;&nbsp;data-token=&quot;
            <Tooltip
              title="In advance generated access token for the given UserID. To obtain a token, execute a direct call to the dedicated Public API end-point."
              placement="right"
            >
              <strong>[JWT_TOKEN]</strong>
            </Tooltip>
            &quot;
            <br />
            &nbsp;&nbsp;data-widgetid=&quot;
            <Tooltip
              title="Unique ID of the widget to be rendered, that can be found in the Widget management page."
              placement="right"
            >
              <strong>[WIDGET_ID]</strong>
            </Tooltip>
            &quot;
            <br />
            &nbsp;&nbsp;data-language=&quot;
            <Tooltip
              title="The language code of the localized version you want to use in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
              placement="right"
            >
              <strong>[LANG_CODE]</strong>
            </Tooltip>
            &quot;
            <br />
            {'>'}
            <br />
            {'</div>'}
            <br />
            {'<link'}
            <br />
            &nbsp;&nbsp;
            href=&quot;https://embeddings.preference-management.usercentrics.eu/latest/styles.css&quot;
            <br />
            &nbsp;&nbsp;{'rel="stylesheet">'}
            <br />
            {'<script '}
            <br />
            &nbsp;&nbsp;
            {
              'src="https://embeddings.preference-management.usercentrics.eu/latest/bundle.js"></script>'
            }
          </CodeSnippet>
        </Container>
      )}

      {!isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Stand-alone Preference Center</Title>
          </TitleWrapper>
          <Description>
            Access the dedicated UC Preference Center using the generated token through the
            following link, which can be placed anywhere on your website or mobile app:
          </Description>
          <CodeSnippet copyContent="https://app-preference.preference-management.usercentrics.eu/?token=[JWT_TOKEN]&lng=[LANG_CODE]">
            https://app-preference.preference-management.usercentrics.eu/?token=
            <Tooltip
              title="In advance generated access token for the given UserID. To obtain a token, execute a direct call to the dedicated Public API end-point."
              placement="right"
            >
              <strong>[JWT_TOKEN]</strong>
            </Tooltip>
            &lng=
            <Tooltip
              title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
              placement="right"
            >
              <strong>[LANG_CODE]</strong>
            </Tooltip>
          </CodeSnippet>

          <Description style={{ marginTop: '20px' }}>
            If you don’t want to generate a token for each user, you can use the following link to
            the Preference Center page. In this scenario, users will request an access token via
            email in a self-service manner. Please don&apos;t forget to activate{' '}
            <strong>Authentication via Email</strong> feature in configuration{' '}
            <a
              href={`${window.location.origin}/configurations/${pacId}/settings`}
              target="_blank"
              rel="noreferrer"
            >
              Settings
            </a>
            .
          </Description>
          <CodeSnippet copyContent="https://app-preference.preference-management.usercentrics.eu/login/2340e662-480b-4219-8b01-6fe79927d34a?lng=[LANG_CODE]">
            https://app-preference.preference-management.usercentrics.eu/login/
            <strong>2340e662-480b-4219-8b01-6fe79927d34a</strong>
            ?lng=
            <Tooltip
              title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
              placement="right"
            >
              <strong>[LANG_CODE]</strong>
            </Tooltip>
          </CodeSnippet>
        </Container>
      )}

      {isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Dedicated Preference Center with access via Email</Title>
          </TitleWrapper>
          <Description>
            Put this link onto your website, so your users can request an access link to their email
            and open the Preference Center to adjust their consents in a self-service manner.
          </Description>
          <CodeSnippet
            copyContent={`https://app-preference.preference-management.usercentrics.eu/login/${pacId}?lng=[LANG_CODE]`}
          >
            https://app-preference.preference-management.usercentrics.eu/login/
            <strong>{pacId}</strong>
            ?lng=
            <Tooltip
              title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
              placement="right"
            >
              <strong>[LANG_CODE]</strong>
            </Tooltip>
          </CodeSnippet>
        </Container>
      )}

      {!isLite && (
        <>
          <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>Integration using Private API key</Title>
            </TitleWrapper>
            <Description>
              You can integrate UC Preference Manager using our public API with a dedicated private
              API key available in the{' '}
              <strong>
                <a
                  href={`${window.location.origin}/account-settings`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Account Settings
                </a>
              </strong>{' '}
              page. Using this method, you can build your own custom interfaces or implement custom
              integrations with other systems - please refer to our{' '}
              <a
                href="https://docs.usercentrics.com/#/preference-manager-api"
                target="_blank"
                rel="noreferrer"
              >
                Public API documentation
              </a>{' '}
              for detailed information.
            </Description>
          </Container>
          {/* INFO: for now this block should be displayed only for PAC lite */}
          {/* <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>Capture preferences from existing HTML form with Javascript code</Title>
            </TitleWrapper>
            <Description>
              Integrate data capture directly into your website form using the JS code below. Please
              refer to our{' '}
              <a
                href="https://docs.usercentrics.com/#/preference-manager-setup"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{' '}
              for more details.
            </Description>
            <CodeSnippet
              copyContent={`<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>
<script>
  // Attach the preference management event listener
  window.setupForm({
    event: {
      // The element selector that you want to listen for an event, can be a form, button, etc
      // Use CSS selectors like:
      // - .theElementClass
      // - #theElementId
      // - div.anything > form
      elementSelector: "#simpleForm", // The form to capture preferences
      eventType: "submit", // The event type, usually form submission
      observeDomChanges: false,
    },
    widgetId: "${widgetId}", // Unique widget ID
    pacId: "${pacId}", // Unique PAC ID
    publicApiKey: "${apiKey}", // Public API key to authenticate
    userId: {
      selector: "#email" // Capture user ID (email)
    },
    inputs: [
      {
        selector: "#inputTerms", // Element capturing user preferences
        optionUuid: "${optionId}", // Option ID for preferences
        validate: function () {
          // Validation function for custom checks
        }
      }
    ]
  });
</script>`}
            >
              {
                '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>'
              }
              <br />
              {'<script>'}
              <br />
              &nbsp;&nbsp;// Attach the preference management event listener
              <br />
              &nbsp;&nbsp;{'window.setupForm({'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'event: {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// The element selector that you want to listen
              for an event, can be a form, button, etc
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// Use CSS selectors like:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - .theElementClass
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - #theElementId
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - div.anything &gt; form
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elementSelector: &quot;#simpleForm&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;eventType: &quot;submit&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;observeDomChanges: false,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;widgetId: &quot;
              <strong>{widgetId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;pacId: &quot;
              <strong>{pacId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;publicApiKey: &quot;
              <strong>{apiKey}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'userId: {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;#email&quot;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;inputs: [
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;#inputTerms&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;optionUuid: &quot;
              <strong>{optionId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'validate: function () {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// Validation function
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;]
              <br />
              {'});'}
              <br />
              {'</script>'}
            </CodeSnippet>
          </Container> */}
        </>
      )}

      {isLite && (
        <>
          <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>Capture preferences from existing HTML form with Javascript code</Title>
            </TitleWrapper>
            <Description>
              Integrate data capture directly into your website form using the JS code below. Please
              refer to our{' '}
              <a
                href="https://docs.usercentrics.com/#/preference-manager-setup"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{' '}
              for more details.
            </Description>
            <CodeSnippet
              copyContent={`<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>
<script>
  // Attach the preference management event listener
  window.setupForm({
    event: {
      // The element selector that you want to listen for an event, can be a form, button, etc
      // Use CSS selectors like:
      // - .theElementClass
      // - #theElementId
      // - div.anything > form
      elementSelector: "#simpleForm", // The form to capture preferences
      eventType: "submit", // The event type, usually form submission
      observeDomChanges: false,
    },
    widgetId: "${widgetId}", // Unique widget ID
    pacId: "${pacId}", // Unique PAC ID
    publicApiKey: "${apiKey}", // Public API key to authenticate
    userId: {
      selector: "#email" // Capture user ID (email)
    },
    inputs: [
      {
        selector: "#inputTerms", // Element capturing user preferences
        optionUuid: "${optionId}", // Option ID for preferences
        validate: function () {
          // Validation function for custom checks
        }
      }
    ]
  });
</script>`}
            >
              {
                '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>'
              }
              <br />
              {'<script>'}
              <br />
              &nbsp;&nbsp;// Attach the preference management event listener
              <br />
              &nbsp;&nbsp;{'window.setupForm({'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'event: {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// The element selector that you want to listen
              for an event, can be a form, button, etc
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// Use CSS selectors like:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - .theElementClass
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - #theElementId
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// - div.anything {'>'} form
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elementSelector: &quot;#simpleForm&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;eventType: &quot;submit&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;observeDomChanges: false,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;widgetId: &quot;
              <strong>{widgetId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;pacId: &quot;
              <strong>{pacId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;publicApiKey: &quot;
              <strong>{apiKey}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'userId: {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;#email&quot;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;inputs: [
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;#inputTerms&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;optionUuid: &quot;
              <strong>{optionId}</strong>
              &quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'validate: function () {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// Validation function
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;]
              <br />
              {'});'}
              <br />
              {'</script>'}
            </CodeSnippet>
          </Container>

          <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>
                Capture preferences from existing HTML form with server-side code (recommended)
              </Title>
            </TitleWrapper>
            <Description>
              The more advanced, but more robust option to capture data server-side. Here is an
              example of Python code you can use to process the form submission and capture
              preferences:
            </Description>
            <CodeSnippet
              copyContent={`url = 'https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences'
headers = {
  'accept': 'application/json',
  'X-API-KEY': '${apiKey}',
  'Content-Type': 'application/json'
}
data = {
  "pacId": "${pacId}",
  "widgetId": "${widgetId}",
  "userId": "[USER_EMAIL]",  # Replace with actual user email
  "answers": [
    "${optionId}"  # Replace with actual option UUID
  ]
}
response = requests.post(url, headers=headers, json=data)`}
            >
              url =
              &apos;https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences&apos;
              <br />
              {'headers = {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&apos;accept&apos;: &apos;application/json&apos;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{`'X-API-KEY': '${apiKey}',`}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&apos;Content-Type&apos;: &apos;application/json&apos;,
              <br />
              {'}'}
              <br />
              {'data = {'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{`"pacId": "${pacId}",`}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{`"widgetId": "${widgetId}",`}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&quot;userId&quot;: &quot;[USER_EMAIL]&quot;, # Replace with
              actual user email
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&quot;answers&quot;: [
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {`"${optionId}"  # Replace with actual option UUID`}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;]
              <br />
              {'}'}
              <br />
              response = requests.post(url, headers=headers, json=data)
            </CodeSnippet>
          </Container>
        </>
      )}

      <SidebarButtons
        setHasUnsavedChanges={() => false}
        onSave={() => null}
        isSaveDisabled
        isPacDeactivated={!!pacDetails?.deactivated}
      />
    </div>
  );
};

export default ImplementationTab;
