/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Form, FormInstance } from 'antd';
import { Item, Input, InputNumber } from 'components/Form';
import { Description, Title } from 'shared-components/ModalsContent';
import { StyledSelect as Select } from 'components/Select/Select';

import { isNotEmptyField, isNotFloat } from 'lib/regex/validations';
import { showErrorResponseNotification, findKeyByValue } from 'lib/helpers';
import WidgetService, { WidgetTranslation } from 'lib/api/widgetTranslationApi';
import { IWidget, ILocalization } from 'lib/interfaces/widget';
import TranslationModal from 'components/Modal/TranslationModal';
import { Translate as TranslateIcon } from 'components/Icons';
import 'react-quill/dist/quill.snow.css';
import { StyledQuill } from 'pages/PAC/styled';
import StyledSwitch from 'components/Switch/Switch';
import { SwitchContainer, TranslationButton, ItemsWrapper, InputWithTranslate } from './styled';
import useUserData from '../../../../lib/hooks/useUserData';

interface IProps {
  form: FormInstance<{ title: ILocalization | string; description: ILocalization | string }>;
  initialValues?: {
    title: ILocalization | string;
    description: ILocalization | string;
    additionalNote: ILocalization | string;
    doubleOptIn?: boolean;
    retentionPeriod?: number;
    retentionBehavior?: string;
  };
  onFieldsChange: () => void;
  edit?: boolean;
  editableLanguages?: Array<string>;
  languageList?: { [key: string]: string };
  widgetId: string;
  widget?: IWidget;
  isPacDeactivated: boolean;
}

const WidgetDetails = ({
  initialValues,
  form,
  onFieldsChange,
  edit,
  editableLanguages,
  languageList,
  widgetId,
  widget,
  isPacDeactivated,
}: IProps) => {
  const [showTranslationModal, setShowTranslationModal] = useState<string>('');
  const [translationsValues, setTranslationsValues] = useState<
    Array<AxiosResponse<WidgetTranslation> | null>
  >([]);
  const [shouldUseHtmlEditor, setShouldUseHtmlEditor] = useState<boolean>(false);
  const { isSuperAdmin } = useUserData();
  const [isRetentionBehaviorDisabled, setRetentionBehaviorDisabled] = useState<boolean>(
    initialValues?.retentionPeriod === 0 ||
      initialValues?.retentionPeriod === null ||
      initialValues?.retentionPeriod === undefined,
  );

  const handleTranslate = async (fieldName: string, useHtmlEditor = false) => {
    const languages = editableLanguages || ['en'];
    const translationsResponses = await Promise.allSettled(
      languages.map((language) =>
        WidgetService.getByLanguageKey(
          widgetId,
          findKeyByValue(languageList || { en: 'English' }, language),
        ),
      ),
    );

    setTranslationsValues(
      translationsResponses.map((el) => (el.status === 'fulfilled' ? el.value : null)),
    );
    setShouldUseHtmlEditor(useHtmlEditor);
    setShowTranslationModal(fieldName);
  };

  const fetchWidget = async () => {
    if (edit) {
      const widgetResponse = await WidgetService.getByLanguageKey(widgetId, 'en');

      form.setFieldsValue(widgetResponse.data);
    }
  };

  const submitTranslations = (fieldValues: { [key: string]: WidgetTranslation }) => {
    const enTranslation = translationsValues.find((el) => el?.data.language === 'en')?.data;
    try {
      Object.entries(fieldValues).forEach(async ([key, val]) => {
        if (val.uuid) {
          await WidgetService.update(val.uuid, {
            [showTranslationModal]: {
              key: Object(enTranslation)[showTranslationModal].key,
              value: Object(val)[showTranslationModal],
            },
          });
        } else {
          const fieldName = showTranslationModal === 'title' ? 'description' : 'title';

          await WidgetService.create(widgetId, {
            ...widget,
            options: enTranslation?.options,
            topics: enTranslation?.topics,
            language: key,
            [fieldName]: Object(enTranslation)[fieldName],
            [showTranslationModal]: {
              key: Object(enTranslation)[showTranslationModal].key,
              value: Object(val)[showTranslationModal],
            },
          });
        }
      });
      form.setFieldsValue({
        [`${showTranslationModal}`]: {
          value: Object(fieldValues.en)[showTranslationModal],
        },
      });
      setShowTranslationModal('');
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  useEffect(() => {
    fetchWidget();
  }, [edit]);

  return (
    <>
      <Title>Details</Title>
      <Description>Enter your widget details below.</Description>
      {showTranslationModal !== '' && (
        <TranslationModal
          htmlEditor={shouldUseHtmlEditor}
          fieldName={showTranslationModal}
          editableLanguagesList={editableLanguages || ['en']}
          languagesList={languageList || { en: 'English' }}
          translationsValues={translationsValues}
          isSaveDisabled={isPacDeactivated && !isSuperAdmin}
          onClose={() => {
            setShowTranslationModal('');
            fetchWidget();
          }}
          submitFunction={submitTranslations}
        />
      )}
      <Form
        name="widget-details"
        form={form}
        initialValues={initialValues}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <InputWithTranslate>
          <Item name={['title', 'key']} style={{ display: 'none' }}>
            <Input />
          </Item>
          {edit && editableLanguages && editableLanguages.length > 1 && (
            <TranslationButton
              onClick={() => handleTranslate('title')}
              data-cy="modal-translate-title-button"
            >
              <TranslateIcon fill="#0045a5" /> Translate
            </TranslationButton>
          )}
          <Item
            label="Title"
            name={['title', 'value']}
            rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
            style={{ marginBottom: '24px' }}
          >
            <Input
              placeholder="Product Preferences"
              data-cy="configuration-new-widget-name-input"
            />
          </Item>
        </InputWithTranslate>

        <InputWithTranslate>
          {edit && editableLanguages && editableLanguages.length > 1 && (
            <TranslationButton
              onClick={() => handleTranslate('description', true)}
              data-cy="modal-translate-description-button"
            >
              <TranslateIcon fill="#0045a5" /> Translate
            </TranslationButton>
          )}

          <Item name={['description', 'key']} style={{ display: 'none' }}>
            <Input />
          </Item>
          <Item
            colon={false}
            label="Description"
            name={['description', 'value']}
            data-cy="configuration-new-widget-description-label"
            style={{ marginBottom: '24px' }}
          >
            <StyledQuill
              modules={{
                toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
              }}
              placeholder="Set up your product preferences."
              formats={['bold', 'italic', 'underline', 'strike', 'link']}
              theme="snow"
            />
          </Item>
        </InputWithTranslate>
        <InputWithTranslate>
          {edit && editableLanguages && editableLanguages.length > 1 && (
            <TranslationButton
              onClick={() => handleTranslate('additionalNote', true)}
              data-cy="modal-translate-additionalNote-button"
            >
              <TranslateIcon fill="#0045a5" /> Translate
            </TranslationButton>
          )}
          <Item name={['additionalNote', 'key']} style={{ display: 'none' }}>
            <Input />
          </Item>
          <Item
            colon={false}
            label="Additional legal note"
            name={['additionalNote', 'value']}
            data-cy="configuration-new-widget-additional-note-label"
            tooltip={{
              title:
                'Here you can specify a note, that will be displayed as a separate block under the Topics and Options elements. This can be used i.e. to underline the Legal basis and Purposes of capturing data under the widget, as well as showing the link to the Privacy policy page if applicable.',
            }}
            style={{ marginBottom: '24px' }}
          >
            <StyledQuill
              modules={{
                toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
              }}
              placeholder="Set up your additional legal note"
              formats={['bold', 'italic', 'underline', 'strike', 'link']}
              theme="snow"
            />
          </Item>
        </InputWithTranslate>

        <ItemsWrapper>
          <div>
            <Item
              colon={false}
              label="Retention Period"
              name="retentionPeriod"
              style={{ marginBottom: '4px', width: '330px' }}
              validateTrigger="onBlur"
              rules={[isNotFloat]}
              tooltip={{
                title:
                  'The Preference data will be kept in our system for a specified number of months after the last update. If non-specified, or set to 0, the data will be stored without limitations',
              }}
            >
              <InputNumber
                type="number"
                min={0}
                step={1}
                placeholder="Retention Period in months"
                data-cy="configuration-new-widget-retentionPeriod-input"
                onChange={(value) => {
                  if (value !== 0 && value !== null && isRetentionBehaviorDisabled) {
                    setRetentionBehaviorDisabled(false);
                  } else if (value === 0 || value === null) {
                    setRetentionBehaviorDisabled(true);
                    form.setFieldValue('retentionBehavior', null);
                  }
                }}
              />
            </Item>
            <p>Define when preferences for this widget will expire.</p>
          </div>

          <div>
            <Item
              colon={false}
              label="Expiration behavior"
              name="retentionBehavior"
              style={{ marginBottom: '4px', width: '330px' }}
              rules={[
                { required: !isRetentionBehaviorDisabled, message: 'This field is required' },
              ]}
              validateTrigger="onBlur"
              tooltip={{
                title:
                  'Here you can define the way system will deal with expired preferences. They can be either deleted automatically from the system, or we can keep them until user makes his decision to update them or delete them completely.',
              }}
            >
              <Select
                placeholder="Select Type"
                disabled={isRetentionBehaviorDisabled}
                data-cy="modal-topic-selection-type-input"
              >
                <Select.Option key="keep" value="keep">
                  Keep expired preference data
                </Select.Option>
                <Select.Option key="delete" value="delete">
                  Delete expired preference data
                </Select.Option>
              </Select>
            </Item>
            <p>Define the way to process expired preferences.</p>
          </div>
        </ItemsWrapper>

        <Item
          colon={false}
          label="Double Opt-In"
          tooltip={{
            title:
              'First preference submission from the user will require a confirmation via email link. Will work only in case email address is being used as UserID',
          }}
        >
          <SwitchContainer>
            <div className="text-content">Activate double opt-in requirement for this widget</div>
            <Form.Item
              name="doubleOptIn"
              valuePropName="checked"
              style={{ marginBottom: '0', width: 'auto' }}
            >
              <StyledSwitch data-cy="configuration-new-widget-double-opt-in" />
            </Form.Item>
          </SwitchContainer>
        </Item>
      </Form>
    </>
  );
};

export default WidgetDetails;
