import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    version="1.1"
    width="24px"
    height="24px"
    viewBox="0 0 24.0 24.0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="widgets0">
        <path d="M17.9870388,11.5310308 C18.5689724,11.5310308 19.0450999,12.0074103 19.0450999,12.589652 L19.0450999,18.9413789 C19.0450999,19.5236205 18.5689724,20 17.9870388,20 L11.6386721,20 C11.0567385,20 10.580611,19.5236205 10.580611,18.9413789 L10.580611,12.589652 C10.580611,12.0074103 11.0567385,11.5310308 11.6386721,11.5310308 Z M7.40642772,11.5310308 C7.98836133,11.5310308 8.46448882,12.0074103 8.46448882,12.589652 L8.46448882,18.9413789 C8.46448882,19.5236205 7.98836133,20 7.40642772,20 L1.0580611,20 C0.476127496,20 0,19.5236205 0,18.9413789 L0,12.589652 C0,12.0074103 0.476127496,11.5310308 1.0580611,11.5310308 Z M13.7018913,0.309646685 C14.1145351,-0.103215562 14.7916942,-0.103215562 15.2043381,0.309646685 L19.6905171,4.79820034 C20.103161,5.21106259 20.103161,5.87799391 19.6905171,6.29085616 L15.1937574,10.789996 C14.7811136,11.2028583 14.1145351,11.2028583 13.7018913,10.789996 L9.2051316,6.29085616 C8.79248777,5.87799391 8.79248777,5.21106259 9.2051316,4.79820034 Z M7.40642772,0.944819373 C7.98836133,0.944819373 8.46448882,1.42119889 8.46448882,2.00344052 L8.46448882,8.35516739 C8.46448882,8.93740902 7.98836133,9.41378854 7.40642772,9.41378854 L1.0580611,9.41378854 C0.476127496,9.41378854 0,8.93740902 0,8.35516739 L0,2.00344052 C0,1.42119889 0.476127496,0.944819373 1.0580611,0.944819373 Z" />
      </clipPath>
      <clipPath id="widgets1">
        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(2.0 2.0)">
      <g clipPath="url(#widgets0)">
        <g transform="translate(-2.0 -2.0)">
          <g clipPath="url(#widgets1)">
            <polygon points="2,2 22,2 22,22 2,22 2,2" stroke="none" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
