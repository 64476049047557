import { message } from 'antd';
import { AxiosError } from 'axios';

export const showErrorResponseNotification = (e: any) => {
  const error = e as AxiosError;

  if (error.response?.data) {
    message.error(error.response?.data.message || error.response?.data.error);
  } else {
    message.error('Something went wrong.');
  }
};
