import React, { useState } from 'react';
import Menu from 'components/Menu/Menu';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';

interface IWidgetMenuProps {
  onEditDetails: () => void;
  onEditTopics: () => void;
  onDelete: () => void;
  onExport: () => void;
  onViewWidgetStructure: () => void;
  widgetUuid: string;
  dataCy?: string;
}

const WidgetMenu = ({
  onDelete,
  onEditDetails,
  onEditTopics,
  onExport,
  onViewWidgetStructure,
  widgetUuid,
  dataCy,
}: IWidgetMenuProps) => {
  const [isDeleteWidgetModal, setIsDeleteWidgetModal] = useState(false);

  return (
    <Menu defaultIcon dataCy={dataCy}>
      <Menu.Item
        key="editDetails"
        onClick={onEditDetails}
        data-cy={`widget-${widgetUuid}-action-edit-details`}
      >
        Edit Details
      </Menu.Item>
      <Menu.Item
        key="editTopics"
        onClick={onEditTopics}
        data-cy={`widget-${widgetUuid}-action-edit-topics`}
      >
        Edit Topics
      </Menu.Item>
      <Menu.Item key="export" onClick={onExport} data-cy={`widget-${widgetUuid}-action-export-csv`}>
        Export CSV
      </Menu.Item>
      <Menu.Item
        key="widgetStructure"
        onClick={onViewWidgetStructure}
        data-cy={`widget-${widgetUuid}-action-widget-structure`}
      >
        View Widget Structure
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="delete"
        onClick={() => setIsDeleteWidgetModal(true)}
        delete
        data-cy={`widget-${widgetUuid}-action-delete-widget`}
      >
        Delete Widget
      </Menu.Item>

      {isDeleteWidgetModal && (
        <DeleteConfirmation
          onDelete={onDelete}
          primaryButtonLabel="Delete"
          primaryButtonDanger
          width={480}
          hideFooterBorder={false}
          isDefaultFooter={false}
          onClose={() => setIsDeleteWidgetModal(false)}
          text="Are you sure you want to delete this widget, all integrations configured for it and all the preference data captured?"
        />
      )}
    </Menu>
  );
};

export default WidgetMenu;
