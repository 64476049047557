import React, { useState } from 'react';

import Webhooks from 'assets/webhooks.svg';

import { useMessage } from 'lib/hooks';
import { IWidget } from 'lib/interfaces/widget';
import { IWebhook } from 'lib/interfaces/webhooks';
import WebhookApiService from 'lib/api/webhookApi';

import CreateWebhookModal from 'components/Modal/CreateWebhook';
import EditWebhookModal from 'components/Modal/EditWebhook';
import Button from 'components/Button/Button';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';

import SidebarButtons from 'pages/PAC/SidebarButtons';

import {
  TrashCan as TrashCanIcon,
  TwoSidedArrow as TwoSidedArrowIcon,
  Edit as EditIcon,
} from 'components/Icons';

import { Pac, PacType } from 'lib/api/pacApi';
import {
  AddWebhookButton,
  EmptyListPlaceHolder,
  WebhookBox,
  WebhookDescription,
  WebhookTitle,
  ButtonsWrapper,
  UrlSpan,
} from './styled';
import { DescriptionSection, DescriptionTitle, PageContainer } from '../styled';
import { TextWithTooltip } from '..';

interface IProps {
  widgets: Array<IWidget>;
  webhooksList: Array<IWebhook>;
  fetchWebhooks: () => void;
  pacDetails?: Pac;
}

const WebhooksIntegration = ({ widgets, webhooksList, fetchWebhooks, pacDetails }: IProps) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [selectedWebhook, setSelectedWebhook] = useState<string>('');
  const message = useMessage();

  return (
    <PageContainer data-cy="webhooks-list-page">
      <DescriptionSection>
        <img src={Webhooks} alt="Webhooks" />

        <DescriptionTitle style={{ width: '50%' }}>
          <span
            style={{
              marginTop: '0',
              marginBottom: '0.5em',
              color: 'rgba(0,0,0,.85)',
              fontWeight: '500',
              display: 'block',
              fontSize: '1.17em',
            }}
          >
            Webhooks
          </span>
          <span>
            Webhooks empower third-party systems to respond to end-user choices whenever changes are
            submitted via our widget or Public API facilitating the implementation of custom
            workflows.
          </span>
          <br />
          <a
            href="https://docs.usercentrics.com/#/preference-manager-integrations"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </DescriptionTitle>
        <AddWebhookButton
          type="button"
          onClick={() => setCreateModalOpen(true)}
          disabled={pacDetails?.type === PacType.LITE}
          data-cy="integration-webhooks-create-new-button"
        >
          <span className="plus-icon">+</span>{' '}
          <TextWithTooltip text="Add Webhook" pacType={pacDetails?.type || PacType.FULL} />
        </AddWebhookButton>
      </DescriptionSection>
      {webhooksList.length === 0 ? (
        <EmptyListPlaceHolder>No webhooks connected</EmptyListPlaceHolder>
      ) : (
        webhooksList.map((webhook) => (
          <WebhookBox key={webhook.uuid}>
            <div style={{ maxWidth: '80%' }}>
              <WebhookTitle>{webhook.name}</WebhookTitle>
              <WebhookDescription>
                <span>{widgets.find((widget) => widget.uuid === webhook.widgetUuid)?.title}</span>
                <TwoSidedArrowIcon width="26px" />{' '}
                <UrlSpan title={webhook.webhookUrl}>{webhook.webhookUrl}</UrlSpan>
              </WebhookDescription>
            </div>
            <ButtonsWrapper>
              <Button
                onClick={() => {
                  setEditModalOpen(true);
                  setSelectedWebhook(webhook.uuid);
                }}
                htmlType="button"
                type="border"
                data-cy="edit-webhook-button"
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteConfirmationModal(true);
                  setSelectedWebhook(webhook.uuid);
                }}
                htmlType="button"
                type="border"
                data-cy="delete-webhook-button"
              >
                <TrashCanIcon />
              </Button>
            </ButtonsWrapper>
          </WebhookBox>
        ))
      )}
      {isEditModalOpen && (
        <EditWebhookModal
          onClose={() => setEditModalOpen(false)}
          widgetsList={widgets}
          webhook={webhooksList.find((w) => w.uuid === selectedWebhook)!}
          fetchWebhooks={() => fetchWebhooks()}
        />
      )}
      {isCreateModalOpen && (
        <CreateWebhookModal
          onClose={() => {
            setCreateModalOpen(false);
          }}
          fetchWebhooks={() => fetchWebhooks()}
          widgetsList={widgets}
        />
      )}
      {showDeleteConfirmationModal && (
        <DeleteConfirmation
          onDelete={async () => {
            try {
              await WebhookApiService.deleteById(selectedWebhook);
              fetchWebhooks();
              setShowDeleteConfirmationModal(false);
              setSelectedWebhook('');
            } catch (error) {
              message.error('something went wrong');
            }
          }}
          onClose={() => setShowDeleteConfirmationModal(false)}
          title="Delete Webhook?"
          text="Are you sure you want to delete this Webhook?"
          primaryButtonLabel="Delete"
          primaryButtonDanger
          hideFooterBorder={false}
          width={480}
          isDefaultFooter={false}
        />
      )}
      <SidebarButtons
        setHasUnsavedChanges={() => true}
        onSave={() => true}
        isSaveDisabled
        isPacDeactivated
      />
    </PageContainer>
  );
};

export default WebhooksIntegration;
