import styled from 'styled-components';
import { Form } from 'antd';

export const UserBlock = styled.ul`
  margin: 10px 0;
  & li {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-bottom: none;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & li:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  & li:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

export const InputWrapper = styled(Form)`
  margin-top: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  & button {
    margin-left: 10px;
  }
`;

export const OwnerWrapper = styled.div`
  padding: 2px 10px 2px 0;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.border};
  display: flex;
  align-items: center;
`;
