import styled from 'styled-components';

export const Container = styled.div`
  width: 740px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  margin-bottom: 20px;

  & p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primaryIcons};
    font-weight: 400;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;

  & p {
    margin-top: 0px;
    margin-bottom: 0;
  }
`;

export const LanguageSelectWrapper = styled.div`
  margin-top: 10px;
`;

export const BodyWrapper = styled.div`
  padding: 20px;
`;

export const CategorySubtitle = styled.span`
  font-weight: 500;
  margin-right: 10px;
`;
