import { AxiosResponse } from 'axios';
import { AbstractApiService } from './abstractApiService';
import { PreferenceApiAxios } from './axiosInstance';

export interface PayloadData {
  created_at: string;
  updatedAt: string;
  expiresAt?: string;
  userId: string;
  uuid: string;
  widgetName: string;
  widgetUuid: string;
  settingsId?: string;
  controllerId?: string;
  answers: Array<{
    topicAnswerUuids: Array<string>;
    topicName: string;
    topicUuid: string;
  }>;
}

export interface PreferencesRequest {
  payload: Array<PayloadData>;
}

export interface WidgetHistoryData {
  author: string;
  changeMode: number;
  preferenceUUID: string;
  timestamp: {
    value: string;
  };
  topicId: string;
  widgetId: string;
  // New fields
  language: string;
  topicTitleEn: string;
  topicTitleTranslated: string;
  // Changed fields
  answers: Array<{ id: string; en: string; translated: string }>;
}

class CustomerService extends AbstractApiService {
  getPreferences(
    userId: string,
    searchParams: { pacUuid?: string } = {},
  ): Promise<AxiosResponse<PreferencesRequest>> {
    return PreferenceApiAxios.get('customer/user/preferences', {
      ...this.getApiHeaders({
        'x-user-id': userId,
      }),
      params: {
        page: 1,
        limit: 111,
        ...searchParams,
      },
    });
  }

  getWidgetByTopicUuids(
    widgetUuid: string,
    searchParams: { topicUuids?: string } = {},
  ): Promise<AxiosResponse<any>> {
    return PreferenceApiAxios.get(`customer/widget/${widgetUuid}`, {
      ...this.getApiHeaders(),
      params: {
        ...searchParams,
      },
    });
  }

  getWidgetHistoryByWidgetId(
    preferenceUuid: string,
    searchParams: { widgetId?: string } = {},
  ): Promise<AxiosResponse<{ payload: Array<WidgetHistoryData> }>> {
    return PreferenceApiAxios.get(`customer/widget/${preferenceUuid}/history`, {
      ...this.getApiHeaders(),
      params: {
        ...searchParams,
      },
    });
  }

  deletePreferenceById(
    preferenceUuid: string,
    searchParams: { widgetId?: string } = {},
  ): Promise<AxiosResponse> {
    return PreferenceApiAxios.delete(`customer/widget/preferences/${preferenceUuid}`, {
      ...this.getApiHeaders(),
      params: {
        ...searchParams,
      },
    });
  }

  getWidgetExportData(searchParams: { widgetId?: string } = {}): Promise<AxiosResponse<any>> {
    return PreferenceApiAxios.get('customer/widget/export', {
      ...this.getApiHeaders(),
      responseType: 'blob',
      params: {
        ...searchParams,
      },
    });
  }

  getWidgetExportDataByUserId(
    userId: string,
    searchParams: { widgetId?: string } = {},
  ): Promise<AxiosResponse<any>> {
    return PreferenceApiAxios.get('customer/widget/export/user', {
      ...this.getApiHeaders({
        'x-user-id': userId,
      }),
      responseType: 'blob',
      params: {
        ...searchParams,
      },
    });
  }
}

export default new CustomerService();
