/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Form, FormInstance, List } from 'antd';
import { useApolloClient } from '@apollo/client';
import { Flex } from 'shared-components';

import { isNotEmptyField } from 'lib/regex/validations';
import { ILocalization, ITopic, IWidget } from 'lib/interfaces/widget';
import getTranslationQuery from 'lib/gql/queries/getTranslations';
import { Languages } from 'lib/enums/translations';
import WidgetTranslationApi, { Option, TranslationObject } from 'lib/api/widgetTranslationApi';
import { useMessage } from 'lib/hooks';
import { filterObjectByKey } from 'lib/helpers';

import Button from 'components/Button/Button';
import TranslationButton from 'components/Button/TranslationButton';
import { Item, Input } from 'components/Form';
import { CollapseArrow as CollapseIcon, UnfoldLess, UnfoldMore } from 'components/Icons';
import { SubTitle } from 'shared-components/ModalsContent';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  OptionWrapper,
  InputWrapper,
  TopicTranslation,
  TranslationContainer,
  TranslationFooter,
  TranslationBody,
  TranslationHeader,
  TranslationLanguageSection,
  TranslationLanguageHeader,
  Actions,
  TranslationTopicsForm,
  TranslationScrollContainer,
} from './styled';
import useUserData from '../../../../lib/hooks/useUserData';

interface IProps {
  topicToEdit: ITopic | null;
  setIsTranslatingWidget: (isOpen: boolean) => void;
  editableLanguages?: string[];
  languageList: { [key: string]: string };
  widget?: IWidget;
  isPacDeactivated: boolean;
}

const TopicsTranslation = ({
  topicToEdit,
  setIsTranslatingWidget,
  editableLanguages,
  languageList,
  widget,
  isPacDeactivated,
}: IProps) => {
  const message = useMessage();
  const translationForm: { [key: string]: FormInstance } = {};
  const [openedLanguages, setOpenedLanguages] = useState<string[]>(['en']);
  const [hasChangesOnForm, setHasChangesOnForm] = useState<boolean>(false);
  const [areTranslationsLoading, toggleTranslationsLoading] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [optionsPerLanguageKey, setOptionsPerLanguageKey] = useState<{
    [key: string]: Array<TranslationObject>;
  }>({});
  const [topicsPerLanguageKey, setTopicsPerLanguageKey] = useState<{
    [key: string]: Array<TranslationObject>;
  }>({});
  const client = useApolloClient();
  const { isSuperAdmin } = useUserData();

  function getTranslationValue(
    translations: TranslationObject[],
    key: string,
  ): TranslationObject | undefined {
    return translations.find((translation: TranslationObject) => translation.key === key);
  }

  const editableLanguagesKey = Object.keys(languageList)
    .filter((languageKey) => editableLanguages?.includes(languageList[languageKey]))
    .map((languageKey) => {
      [translationForm[languageKey]] = Form.useForm();
      return languageKey;
    })
    .reduce((accumulator: Array<string>, currentValue) => {
      if (currentValue === 'en') {
        accumulator.splice(0, 0, currentValue);
      } else {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

  const setTopicForm = ({ data }: AxiosResponse, languageKey: string) => {
    const nameTranslation = getTranslationValue(data.topics, `${topicToEdit?.uuid}:name`);
    const descriptionTranslation = getTranslationValue(
      data.topics,
      `${topicToEdit?.uuid}:description`,
    );
    const options = topicToEdit?.options.map((optionResponse: Option) => {
      const nameTranslationTopic = getTranslationValue(
        data.options,
        `${optionResponse?.uuid}:name`,
      );
      return {
        uuid: optionResponse.uuid,
        name: nameTranslationTopic?.value,
      };
    });

    const topic = {
      description: descriptionTranslation?.value || '',
      name: nameTranslation?.value || '',
      options,
      uuid: topicToEdit?.uuid,
    };

    translationForm[languageKey].setFieldsValue({
      ...topic,
      language: languageKey,
      translationUuid: data.uuid,
      widgetDescription: data.description,
      widgetTitle: data.title,
    });

    setOptionsPerLanguageKey((previousState) => {
      return { ...previousState, [languageKey]: data.options };
    });

    setTopicsPerLanguageKey((previousState) => {
      return { ...previousState, [languageKey]: data.topics };
    });
  };

  const isDefaultLanguage = (languageKey: string): boolean => {
    return Languages.ENGLISH === languageList[languageKey];
  };

  const saveTranslations = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for await (const languageKey of Object.keys(translationForm)) {
      try {
        const formData = await translationForm[languageKey].validateFields();
        const topics = topicsPerLanguageKey[languageKey].map((topic: TranslationObject) => {
          const alteredTopic = { ...topic };
          if (topic?.key === `${formData.uuid}:name`) {
            alteredTopic.value = formData.name;
          }

          if (topic?.key === `${formData.uuid}:description`) {
            alteredTopic.value = formData.description;
          }
          return alteredTopic;
        });

        const options = optionsPerLanguageKey[languageKey].map((option: TranslationObject) => {
          const alteredOption = { ...option };
          const optionEdited = formData.options.find((optionSearch: Option) => {
            return option?.key === `${optionSearch.uuid}:name`;
          });

          if (optionEdited) {
            alteredOption.value = optionEdited.name;
          }
          return alteredOption;
        });

        const widgetTranslation = {
          language: languageKey,
          title: widget?.title || { value: '', key: '' },
          description: widget?.description || { value: '', key: '' },
          topics,
          options,
        };
        await WidgetTranslationApi.update(formData.translationUuid, widgetTranslation);
      } catch (e) {
        message.error(
          `Some fields validations failed, please check your data for ${languageList[languageKey]}`,
        );
        throw new Error('Some validation failed');
      }
    }
    setIsTranslatingWidget(false);
  };

  const expandCollapseAll = () => {
    let local: string[] = [...openedLanguages];
    if (local.length === 0 || local.length !== editableLanguagesKey.length) {
      local = [...editableLanguagesKey];
    } else if (local.length === editableLanguagesKey.length) {
      local = [];
    }
    setOpenedLanguages(local);
  };

  const expandCollapseSingle = (languageKey: string) => {
    const local = [...openedLanguages];
    const languageIndex = local.indexOf(languageKey);
    if (languageIndex > -1) {
      local.splice(languageIndex, 1);
    } else {
      local.push(languageKey);
    }
    setOpenedLanguages(local);
  };

  const getExpandCollapseAllText = (): string => {
    const expandAll = 'Expand all';
    const collapseAll = 'Collapse all';

    if (openedLanguages.length === 0) {
      return expandAll;
    }
    if (openedLanguages.length === editableLanguagesKey.length) {
      return collapseAll;
    }
    return expandAll;
  };

  const getExpandButtonIcon = () => {
    const expandAll = <UnfoldMore />;
    const collapseAll = <UnfoldLess />;
    if (openedLanguages.length === 0) {
      return expandAll;
    }
    if (openedLanguages.length === editableLanguagesKey.length) {
      return collapseAll;
    }
    return expandAll;
  };

  const onFieldsChange = () => {
    setHasChangesOnForm(true);
  };

  const onAutoTranslate = async (languageKey: string) => {
    toggleTranslationsLoading(true);
    const englishVersion = await translationForm.en.validateFields();
    const originalVersion = await translationForm[languageKey].validateFields();
    const query = getTranslationQuery([languageKey]);
    client
      .query({
        query,
        variables: {
          //NOTE: this is temporary solution, since PL API not accept array of strings I used |~| as unique character to split string, without it we will over spam API
          //TODO: discuss with someone from PL team if they can improve API
          translationFor: `${englishVersion.name}|~|${
            englishVersion.description
          }${englishVersion.options
            .map((option: { name: string }) => `|~|${option.name}`)
            .join('')}`,
          sourceLanguage: 'en',
          targetLanguages: [languageKey],
        },
      })
      .then((response) => {
        const translationsResponse = filterObjectByKey(response.data.getTranslation, [languageKey]);
        const splitTranslations = Object(translationsResponse)[languageKey].split('|~|');

        const topic = {
          ...originalVersion,
          description: splitTranslations[1],
          name: splitTranslations[0],
          options: englishVersion.options.map(
            (option: { name: string; uuid: string }, index: number) => {
              return {
                name: splitTranslations[index + 2],
                uuid: option.uuid,
              };
            },
          ),
        };
        translationForm[languageKey].setFieldsValue(topic);
        toggleTranslationsLoading(false);
      })
      .catch((error) => {
        toggleTranslationsLoading(false);
        message.error('Some validation failed');
        throw new Error(error);
      });
  };

  useEffect(() => {
    const init = async () => {
      const localOptionsPerLanguageKey: {
        [key: string]: Array<TranslationObject>;
      } = {};
      const localTopicsPerLanguageKey: {
        [key: string]: Array<TranslationObject>;
      } = {};
      // eslint-disable-next-line no-restricted-syntax
      for await (const languageKey of editableLanguagesKey) {
        let response: AxiosResponse;

        try {
          response = await WidgetTranslationApi.getByLanguageKey(
            widget?.uuid as string,
            languageKey,
          );
          const { data } = response;

          localOptionsPerLanguageKey[languageKey] = data.options;
          localTopicsPerLanguageKey[languageKey] = data.topics;

          if (languageKey !== 'en') {
            const diffOptions = localOptionsPerLanguageKey.en.filter((option) => {
              return (
                data.options.findIndex((dataOption: TranslationObject) => {
                  return dataOption.key === option.key;
                }) === -1
              );
            });

            const diffTopics = localTopicsPerLanguageKey.en.filter((topic) => {
              return (
                data.topics.findIndex((dataTopic: TranslationObject) => {
                  return dataTopic.key === topic.key;
                }) === -1
              );
            });

            if (diffOptions.length > 0 || diffTopics.length > 0) {
              const widgetTranslation = {
                language: languageKey,
                title: data?.title || {},
                description: data?.description || {},
                topics: data.topics.concat(diffTopics),
                options: data.options.concat(diffOptions),
              };

              response = await WidgetTranslationApi.update(data.uuid, widgetTranslation);
              setTopicForm(response, languageKey);
            }
          }

          setTopicForm(response, languageKey);
          // In this scenario, catch is asking for defining it as ANY
        } catch (err: any) {
          if (err.response.data.statusCode === 404) {
            const topics = localTopicsPerLanguageKey.en;
            const options = localOptionsPerLanguageKey.en;
            const newWidgetTranslation = {
              language: languageKey,
              title: {
                value: widget?.title || '',
                key: `${widget?.uuid}:title` || '',
              } as ILocalization,
              description: {
                value: widget?.description || '',
                key: `${widget?.uuid}:description` || '',
              } as ILocalization,
              topics,
              options,
            };

            response = await WidgetTranslationApi.create(
              widget?.uuid as string,
              newWidgetTranslation,
            );

            setTopicForm(response, languageKey);
          } else {
            throw new Error(err);
          }
        }
      }
    };
    init();
  }, [true]);

  return (
    <TopicTranslation>
      <TranslationContainer>
        <TranslationBody>
          <TranslationHeader>
            <SubTitle>Translate ({topicToEdit?.name})</SubTitle>
            <Button
              Icon={getExpandButtonIcon()}
              onClick={expandCollapseAll}
              htmlType="button"
              type="border"
              style={{ paddingLeft: '8px' }}
              data-cy="modal-translations-expand-all-button"
            >
              {getExpandCollapseAllText()}
            </Button>
          </TranslationHeader>
          <TranslationScrollContainer>
            {editableLanguagesKey.map((languageKey) => {
              return (
                <TranslationLanguageSection
                  key={languageKey}
                  className={`${isDefaultLanguage(languageKey) ? 'default-language' : ''}`}
                >
                  <TranslationLanguageHeader
                    onClick={(event) => {
                      event.preventDefault();
                      expandCollapseSingle(languageKey);
                    }}
                  >
                    <SubTitle>
                      {languageList[languageKey]} <br />{' '}
                      {openedLanguages.includes(languageKey) && (
                        <span>{`${
                          isDefaultLanguage(languageKey) ? 'Default language' : 'Editable language'
                        }`}</span>
                      )}
                    </SubTitle>
                    <TranslationLanguageHeader>
                      {openedLanguages.includes(languageKey) && !isDefaultLanguage(languageKey) && (
                        <TranslationButton
                          onClick={(event) => {
                            event.stopPropagation();
                            onAutoTranslate(languageKey);
                          }}
                          dataCY="modal-translations-auto-translate-button"
                          isLoading={areTranslationsLoading}
                          label="Auto translate from default language"
                        />
                      )}
                      <Actions data-cy="modal-translations-expand-button">
                        <div
                          style={{
                            transform: openedLanguages.includes(languageKey)
                              ? 'rotate(0)'
                              : 'rotate(180deg)',
                          }}
                        >
                          <CollapseIcon />
                        </div>
                      </Actions>
                    </TranslationLanguageHeader>
                  </TranslationLanguageHeader>
                  <TranslationTopicsForm
                    style={{ display: openedLanguages.includes(languageKey) ? 'block' : 'none' }}
                    form={translationForm[languageKey]}
                    onFieldsChange={onFieldsChange}
                    key={languageKey}
                    initialValues={{
                      options: [{ name: '', uuid: uuidv4() }],
                    }}
                    colon={false}
                  >
                    <Item label="uuid" name="uuid" style={{ display: 'none' }}>
                      <Input type="text" />
                    </Item>
                    <Item label="language" name="language" style={{ display: 'none' }}>
                      <Input type="text" />
                    </Item>
                    <Item
                      label="widgetDescription"
                      name="widgetDescription"
                      style={{ display: 'none' }}
                    >
                      <Input type="text" />
                    </Item>
                    <Item label="widgetTitle" name="widgetTitle" style={{ display: 'none' }}>
                      <Input type="text" />
                    </Item>
                    <Item
                      label="translationUuid"
                      name="translationUuid"
                      style={{ display: 'none' }}
                    >
                      <Input type="text" />
                    </Item>
                    <Item
                      required
                      label="Name"
                      tooltip={{
                        title:
                          'The topic name you want to capture preferences about: e.g. "Newsletter topics", "Communication frequency", "Your favorite brands", etc.',
                      }}
                      name="name"
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: 'Topic name required' }, isNotEmptyField]}
                    >
                      <Input placeholder="Name" data-cy="modal-translations-name-input" />
                    </Item>
                    <Item
                      tooltip={{
                        title:
                          'Additional information about this topic - why do you capture this data, how it will benefit your users, etc.',
                      }}
                      label="Description"
                      name="description"
                      validateTrigger="onBlur"
                      rules={[{ required: false }]}
                    >
                      <ReactQuill
                        style={{ backgroundColor: 'white' }}
                        modules={{
                          toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
                        }}
                        placeholder="Description (optional)"
                        formats={['bold', 'italic', 'underline', 'strike', 'link']}
                        data-cy="modal-translations-description-input"
                        theme="snow"
                      />
                    </Item>
                    <Item
                      label="Preference Options"
                      tooltip={{
                        title:
                          'The preference options end-users choose their preferences from: e.g. newsletter topics are "Company news", "Product updates", "Partner news", etc.',
                      }}
                    >
                      <Form.List name="options">
                        {(options) => {
                          return (
                            <Flex flexDirection="column">
                              <List
                                rowKey="key"
                                dataSource={options}
                                renderItem={(item, index) => (
                                  <OptionWrapper key={item.key}>
                                    <InputWrapper>
                                      <Item
                                        name={[index, 'name']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Preference option name required',
                                          },
                                          isNotEmptyField,
                                        ]}
                                      >
                                        <Input
                                          placeholder="Option"
                                          data-cy={`modal-translations-option-${index}-input`}
                                        />
                                      </Item>
                                    </InputWrapper>
                                    <Form.Item
                                      label="uuid"
                                      name={[index, 'uuid']}
                                      style={{ display: 'none' }}
                                    >
                                      <Input type="text" />
                                    </Form.Item>
                                  </OptionWrapper>
                                )}
                              />
                            </Flex>
                          );
                        }}
                      </Form.List>
                    </Item>
                  </TranslationTopicsForm>
                </TranslationLanguageSection>
              );
            })}
          </TranslationScrollContainer>
        </TranslationBody>
        <TranslationFooter>
          <Button
            onClick={() => {
              if (hasChangesOnForm) {
                setShowDeleteConfirmationModal(true);
                return;
              }
              setIsTranslatingWidget(false);
            }}
            htmlType="button"
            data-cy="modal-translations-back-to-topic-button"
            type="border"
          >
            Back to Topic
          </Button>
          <Button
            onClick={() => {
              saveTranslations();
            }}
            data-cy="modal-translations-save-button"
            htmlType="button"
            type="primary"
            disabled={(isPacDeactivated && !isSuperAdmin) || !hasChangesOnForm}
          >
            Save Translations
          </Button>
        </TranslationFooter>
      </TranslationContainer>
      {showDeleteConfirmationModal && (
        <DeleteConfirmation
          onDelete={() => {
            setIsTranslatingWidget(false);
          }}
          onClose={() => setShowDeleteConfirmationModal(false)}
          title="Unsaved changes"
          text="You have unsaved changes. Are you sure you want to quit?"
        />
      )}
    </TopicTranslation>
  );
};

export default TopicsTranslation;
