import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 40px;
  padding-top: 0;
`;

export const SectionsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.darkGray};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const PageTitle = styled.h1`
  padding: 32px 40px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
`;

export const OverviewSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const OverviewStatsGroupContainer = styled.div`
  display: flex;
`;

export const OverviewStatsContainer = styled.div`
  margin-left: 25px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 16px 32px 16px 32px;
  min-width: 151px;
  min-height: 78px;
  text-align: right;
  & h2 {
    margin: 0;
  }
`;

export const OverviewStatsContainerTitle = styled.p`
  color: ${(props) => props.theme.colors.primaryIcons};
  font-size: 14px;
  margin: 0;
`;

export const OverviewDescription = styled.p`
  max-width: 300px;
  margin: 0;
  margin-bottom: 10px;
`;

export const InfoSectionsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const InfoSections = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex: 1;
  padding: 20px;
  align-items: center;
  height: 64px;
  justify-content: space-between;

  & p,
  h2,
  h3 {
    margin: 0;
  }
`;

export const GraphContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 20px;
`;

export const CircleIcon = styled.span`
  width: 12px;
  border-radius: 50%;
  background-color: black;
  display: inline-block;
  height: 12px;
`;
