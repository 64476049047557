import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from 'components/Button/Button';
import { Flex } from 'shared-components';
import { Description, Placeholder, Title, ImageWrapper } from './styled';

interface IEmptyListPlaceholder {
  title: string;
  description: string | JSX.Element;
  image: string;
  button?: {
    text: string;
    dataId?: string;
    onClick: () => void;
  } | null;
  link?: {
    text: string;
    dataId?: string;
    to: string;
  } | null;
}

const EmptyListPlaceholder = ({
  title,
  description,
  button,
  image,
  link,
}: IEmptyListPlaceholder) => {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 950px)',
  });

  return (
    <Placeholder>
      <Flex flexWrap={isBigScreen ? 'nowrap' : 'wrap'}>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {link && (
            <a style={{ marginTop: '10px', padding: '0' }} href={link.to} data-id={link.dataId}>
              {' '}
              {link.text}{' '}
            </a>
          )}
          {button && (
            <Button
              style={{ marginTop: '10px' }}
              onClick={button.onClick}
              type="primary"
              size="large"
              data-id={button.dataId}
            >
              {' '}
              {button.text}{' '}
            </Button>
          )}
        </div>
        <ImageWrapper>
          <img src={image} alt="Placeholder" />
        </ImageWrapper>
      </Flex>
    </Placeholder>
  );
};

export default EmptyListPlaceholder;
