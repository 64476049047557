import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import axios, { AxiosResponse } from 'axios';

import { ITopic, IWidget } from 'lib/interfaces/widget';
import { Steps } from 'lib/enums/steps';
import useMessage from 'lib/hooks/useMessage';
import CustomerService from 'lib/api/customerApi';
import { showErrorResponseNotification } from 'lib/helpers';

import Modal from 'components/Modal/Modal';
import { AbstractApiService } from 'lib/api/abstractApiService';
import Details from './Content/Details';
import Topics from './Content/Topics';
import useUserData from '../../../lib/hooks/useUserData';

interface IProps {
  fetchWidgets: () => Promise<void> | void;
  onClose: () => Promise<void> | void;
  widget: IWidget;
  modalStep: Steps;
  editableLanguages: string[];
  languageList: { [key: string]: string };
  isPacDeactivated: boolean;
  isPacLite: boolean;
}

const EditWidgetModal = ({
  onClose,
  widget,
  modalStep,
  editableLanguages,
  languageList,
  fetchWidgets,
  isPacDeactivated,
  isPacLite
}: IProps) => {
  const message = useMessage();
  const [detailsForm] = Form.useForm();
  const [topicsForm] = Form.useForm();
  const [topics, setTopics] = useState<ITopic[]>(widget.topics);
  const [disableNext, setDisableNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const abstractApiService = new AbstractApiService();
  const { isSuperAdmin } = useUserData();

  const showMessage = (response: AxiosResponse) =>
    message.success(
      <div>
        <span>Widget was updated: {response.data ? response.data.uuid : ''}</span>
        <br />
        <span>
          Please mind, due to cache settings, the changes will take effect in 10-15 minutes.
        </span>
      </div>,
    );

  useEffect(() => {
    const init = async () => {
      if (modalStep === Steps.TOPICS) {
        topicsForm.setFieldsValue(topics[0]);
      }

      const response = await CustomerService.getWidgetByTopicUuids(widget.uuid, {
        topicUuids: widget.topics.map((topic) => topic.uuid).join(','),
      });
      setAnswers(response.data.payload);
    };

    init();
  }, [modalStep]);

  useEffect(() => {
    setTopics(widget.topics);
  }, [widget]);

  const onFieldsChange = () => {
    const hasErrors = detailsForm.getFieldsError().some(({ errors }) => errors.length);
    setDisableNext(hasErrors);
  };

  const onTopicsChange = () => {
    const hasErrors = topicsForm.getFieldsError().some(({ errors }) => errors.length);
    setDisableNext(hasErrors);
  };

  const onPrimaryClick = async () => {
    if (modalStep === Steps.DETAILS) {
      try {
        const d = await detailsForm.validateFields();
        setLoading(true);
        axios
          .patch(
            `${process.env.REACT_APP_CONFIG_API_URL}/widgets/${widget.uuid}/default`,
            {
              title: d.title.value,
              description: d.description.value,
              additionalNote: d.additionalNote.value,
              doubleOptIn: d.doubleOptIn,
              retentionPeriod: d.retentionPeriod,
              retentionBehavior: d.retentionBehavior,
            },
            abstractApiService.getApiHeaders(),
          )
          .then((response) => {
            showMessage(response);
            setLoading(false);
            onClose();
          });
      } catch (error) {
        showErrorResponseNotification(error);
      }
    }
    if (modalStep === Steps.TOPICS) {
      try {
        setLoading(true);
        axios
          .patch(
            `${process.env.REACT_APP_CONFIG_API_URL}/widgets/${widget.uuid}/default`,
            {
              topics: topics.map((topic, index) => {
                const isTopicOld = widget.topics.map((t) => t.uuid).includes(topic.uuid);
                const oldVersionOfTopic = isTopicOld
                  ? widget.topics.filter((t) => t.uuid === topic.uuid)[0]
                  : null;
                const oldOptionIds = oldVersionOfTopic
                  ? oldVersionOfTopic.options.map((option) => option.uuid)
                  : [];
                // Check if initial topics of widget includes current topic
                return isTopicOld
                  ? {
                      uuid: topic.uuid,
                      order: index,
                      name: topic.name,
                      description: topic.description,
                      selectType: topic.selectType,
                      required: topic.required,
                      options: topic.options
                        .filter((option) => option.name !== undefined)
                        .map((option, optionIndex) =>
                          oldOptionIds.includes(option.uuid)
                            ? { ...option, order: optionIndex, default: option.default || false }
                            : {
                                name: option.name,
                                order: optionIndex,
                                default: option.default || false,
                              },
                        ),
                    }
                  : {
                      name: topic.name,
                      order: index,
                      description: topic.description,
                      selectType: topic.selectType,
                      required: topic.required,
                      options: topic.options
                        .filter((option) => option.name !== undefined)
                        .map((option, optionIndex) => ({
                          name: option.name,
                          order: optionIndex,
                          default: option.default || false,
                        })),
                    };
              }),
            },
            abstractApiService.getApiHeaders(),
          )
          .then((response) => {
            showMessage(response);
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            if (error.message.status === 422) {
              message.error('Some mandatory data was not sent');
            } else {
              showErrorResponseNotification(error);
            }
          });
      } catch (error) {
        showErrorResponseNotification(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      visible
      title="Edit Widget"
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      primaryButton={{
        label: 'Save Changes',
        onClick: onPrimaryClick,
        disabled: (isPacDeactivated && !isSuperAdmin) || disableNext,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      {modalStep === Steps.DETAILS && (
        <Details
          edit
          initialValues={{
            title: widget.title,
            description: widget.description,
            doubleOptIn: widget.doubleOptIn,
            retentionPeriod: widget.retentionPeriod,
            additionalNote: widget.additionalNote,
            retentionBehavior: widget.retentionBehavior,
          }}
          form={detailsForm}
          onFieldsChange={onFieldsChange}
          isPacDeactivated={isPacDeactivated}
          editableLanguages={editableLanguages}
          languageList={languageList}
          widgetId={widget.uuid}
          widget={widget}
        />
      )}
      {modalStep === Steps.TOPICS && (
        <Topics
          edit
          fetchWidgets={fetchWidgets}
          widget={widget}
          languageList={languageList}
          answers={answers}
          form={topicsForm}
          topics={topics}
          editableLanguages={editableLanguages}
          isPacDeactivated={isPacDeactivated}
          onFieldsChange={onTopicsChange}
          updateTopics={(value: Array<ITopic>) => {
            setTopics(value);
          }}
          isPacLite={isPacLite}
        />
      )}
    </Modal>
  );
};

export default EditWidgetModal;
