import React, { useEffect, useState } from 'react';

import { Form, FormInstance } from 'antd';
import { Description, Title } from 'shared-components/ModalsContent';

import { StyledSelect as Select } from 'components/Select/Select';
import { Input, Item } from 'components/Form';
import { isNotEmptyField } from 'lib/regex/validations';

interface IProps {
  form: FormInstance<{ type: string }>;
  initialValues?: { type: string };
  onFieldsChange: () => void;
}

const AuthorizationStep = ({ initialValues, form, onFieldsChange }: IProps) => {
  const [selectedType, setSelectedType] = useState<string>('no');

  useEffect(() => {
    if (form.getFieldValue('type')) {
      setSelectedType(form.getFieldValue('type'));
    }
  }, []);

  return (
    <>
      <Title>Webhook Authorization</Title>
      <Description>
        Choose if you want to use one of the available authorization methods.
      </Description>
      <Form
        name="webhook-settings"
        form={form}
        initialValues={initialValues}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <Item label="Method" name="type" style={{ marginBottom: '24px' }}>
          <Select
            placeholder="Select Topic"
            data-cy="webhook-new-authorization-method-select"
            // any here needed for ant-design
            onChange={(el: any) => {
              setSelectedType(el);
              form.resetFields();
              form.setFieldValue('type', el);
              if (el === 'api-key') form.setFieldValue('location', 'header');
              form.validateFields();
            }}
          >
            <Select.Option key="no" value="no" data-cy="webhook-new-authorization-method-option-no">
              No authorization
            </Select.Option>
            <Select.Option
              key="basic"
              value="basic"
              data-cy="webhook-new-authorization-method-option-basic"
            >
              Basic authorization with username and password
            </Select.Option>
            <Select.Option
              key="api-key"
              value="api-key"
              data-cy="webhook-new-authorization-method-option-apiKey"
            >
              Advanced authorization with API Key
            </Select.Option>
          </Select>
        </Item>
        {selectedType === 'basic' && (
          <>
            <Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="Username" data-cy="webhook-new-authorization-username-input" />
            </Item>
            <Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Input
                placeholder="Password"
                data-cy="webhook-new-authorization-password-input"
                type="password"
              />
            </Item>
          </>
        )}
        {selectedType === 'api-key' && (
          <>
            <Item
              label="Key"
              name="name"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="X-API-Key" data-cy="webhook-new-authorization-key-input" />
            </Item>
            <Item
              label="Value"
              name="value"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="Value" data-cy="webhook-new-authorization-value-input" />
            </Item>
            <Item
              label="Add To"
              name="location"
              style={{ marginBottom: '24px' }}
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
            >
              <Select
                placeholder="Add to"
                data-cy="webhook-new-authorization-location-select"
                options={[
                  { value: 'header', label: 'Header' },
                  { value: 'body', label: 'Body' },
                ]}
              />
            </Item>
          </>
        )}
      </Form>
    </>
  );
};

export default AuthorizationStep;
