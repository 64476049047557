import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="page/sidebar-unassigned-inactive" transform="translate(-8, -8)">
        <g id="space_dashboard_black_24dp" transform="translate(8.000000, 10.000000)">
          <rect id="Rectangle" x="0" y="0" width="20" height="20" />
          <path
            d="M7.5,17.5 L4.16666667,17.5 C3.25,17.5 2.5,16.75 2.5,15.8333333 L2.5,4.16666667 C2.5,3.25 3.25,2.5 4.16666667,2.5 L7.5,2.5 C8.41666667,2.5 9.16666667,3.25 9.16666667,4.16666667 L9.16666667,15.8333333 C9.16666667,16.75 8.41666667,17.5 7.5,17.5 Z M12.5,17.5 L15.8333333,17.5 C16.75,17.5 17.5,16.75 17.5,15.8333333 L17.5,11.6666667 C17.5,10.75 16.75,10 15.8333333,10 L12.5,10 C11.5833333,10 10.8333333,10.75 10.8333333,11.6666667 L10.8333333,15.8333333 C10.8333333,16.75 11.5833333,17.5 12.5,17.5 Z M17.5,6.66666667 L17.5,4.16666667 C17.5,3.25 16.75,2.5 15.8333333,2.5 L12.5,2.5 C11.5833333,2.5 10.8333333,3.25 10.8333333,4.16666667 L10.8333333,6.66666667 C10.8333333,7.58333333 11.5833333,8.33333333 12.5,8.33333333 L15.8333333,8.33333333 C16.75,8.33333333 17.5,7.58333333 17.5,6.66666667 Z"
            id="Shape"
            fill="#303030"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
