import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import AUTH_USER_MUTATION from 'lib/gql/mutations/authUser';
import SUPER_ADMIN_QUERY from 'lib/gql/queries/superAdmins';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import { useLogout } from './useLogout';

export default function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const logoutFromAuth0 = useLogout();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [stateAppAccess, setAppAccess] = useState<string[] | null>(null);
  const [stateAppPrivileges, setAppPrivileges] = useState<{ pmp: [] } | null>(null);
  const [errorState, setErrorState] = useState<{ error: string; error_description: string }>();
  const isLogoutPage = location.pathname.includes('/logout');

  const cache = new InMemoryCache({
    addTypename: false,
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_APOLLO_CLIENT}`,
    includeExtensions: false,
  });
  const authLink = setContext((_, { headers }) => {
    const localHeaders = {
      ...headers,
      'Access-Control-Allow-Origin': '*',
      withCredentials: true,
    };
    const token = localStorage.getItem('token');

    if (token) {
      localHeaders.Authorization = `Bearer ${token}`;
    }
    return {
      headers: {
        ...localHeaders,
      },
    };
  });

  const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });

  useEffect(() => {
    setIsLoading(true);
    localStorage.removeItem('token');

    getAccessTokenSilently()
      .then((responseToken: string) =>
        client.mutate({ mutation: AUTH_USER_MUTATION(), variables: { token: responseToken } }),
      )
      .then(
        ({
          data: {
            authenticateUser: { token },
          },
        }) => {
          localStorage.setItem('token', token);

          return client.query({
            query: SUPER_ADMIN_QUERY(),
          });
        },
      )
      .then(
        ({
          data: {
            superAdminData: { appAccess, privileges },
          },
        }) => {
          setAppPrivileges(privileges);
          setAppAccess(appAccess);
          setIsLoading(false);
        },
      )
      .catch((error) => {
        if (isLogoutPage) {
          setIsLoading(false);
          return null;
        }

        if (error?.error === 'login_required') {
          loginWithRedirect();
        } else {
          setErrorState(error);
          setIsLoading(false);

          if (error.code === 401) {
            return logoutFromAuth0();
          }

          return navigate('/loginError');
        }
        return null;
      });
  }, []);

  return { isLoading, client, stateAppAccess, errorState, stateAppPrivileges };
}
