import { AxiosResponse } from 'axios';
import { AbstractApiService } from './abstractApiService';
import { Axios } from './axiosInstance';

export interface IWidgetStat {
  uuid: string;
  title: string;
  totalPreferences: number;
  topicsStats: [
    {
      name: string;
      uuid: string;
      options: [
        {
          name: string;
          optIn: number;
          optInRate: number;
          uuid: string;
        },
      ];
    },
  ];
}
export interface TotalPerPac {
  uuid: string;
  title: string;
  totalWidgets: number;
  totalTopics: number;
  totalPreferences: number;
  uniqueUsers: number;
  statsPerWidget: Array<IWidgetStat>;
}
export interface AnalyticsOverview {
  totalsPerPac: Array<TotalPerPac>;
  totalUniqueUsers: number;
  totalPreferences: number;
}

class AnalyticsApiService extends AbstractApiService {
  getOverview(onlyOwned: boolean): Promise<AxiosResponse<AnalyticsOverview>> {
    return Axios.get('analytics/overview', {
      ...this.getApiHeaders(),
      params: {
        onlyOwned,
      },
    });
  }
}

export default new AnalyticsApiService();
