import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_520_941)">
      <path
        d="M19.4999 12C19.4999 11.77 19.4899 11.55 19.4699 11.32L21.3299 9.91C21.7299 9.61 21.8399 9.05 21.5899 8.61L19.7199 5.38C19.4699 4.94 18.9299 4.76 18.4699 4.96L16.3199 5.87C15.9499 5.61 15.5599 5.38 15.1499 5.19L14.8599 2.88C14.7999 2.38 14.3699 2 13.8699 2H10.1399C9.62991 2 9.19991 2.38 9.13991 2.88L8.84991 5.19C8.43991 5.38 8.04991 5.61 7.67991 5.87L5.52991 4.96C5.06991 4.76 4.52991 4.94 4.27991 5.38L2.40991 8.62C2.15991 9.06 2.26991 9.61 2.66991 9.92L4.52991 11.33C4.50991 11.55 4.49991 11.77 4.49991 12C4.49991 12.23 4.50991 12.45 4.52991 12.68L2.66991 14.09C2.26991 14.39 2.15991 14.95 2.40991 15.39L4.27991 18.62C4.52991 19.06 5.06991 19.24 5.52991 19.04L7.67991 18.13C8.04991 18.39 8.43991 18.62 8.84991 18.81L9.13991 21.12C9.19991 21.62 9.62991 22 10.1299 22H13.8599C14.3599 22 14.7899 21.62 14.8499 21.12L15.1399 18.81C15.5499 18.62 15.9399 18.39 16.3099 18.13L18.4599 19.04C18.9199 19.24 19.4599 19.06 19.7099 18.62L21.5799 15.39C21.8299 14.95 21.7199 14.4 21.3199 14.09L19.4599 12.68C19.4899 12.45 19.4999 12.23 19.4999 12ZM12.0399 15.5C10.1099 15.5 8.53991 13.93 8.53991 12C8.53991 10.07 10.1099 8.5 12.0399 8.5C13.9699 8.5 15.5399 10.07 15.5399 12C15.5399 13.93 13.9699 15.5 12.0399 15.5Z"
        fill="#303030"
      />
    </g>
    <defs>
      <clipPath id="clip0_520_941">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
