/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { Spin, Tooltip } from 'antd';

import { Copy, Key, NoKey, TrashCan } from 'components/Icons';
import Button from 'components/Button/Button';
import { transformDate } from 'components/Modal/UserDetails';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';

import { MAXIMUM_KEYS_TO_GENERATE } from 'lib/constants/global';
import { useToken, useMessage } from 'lib/hooks';
import { clickToCopy } from 'lib/helpers/clickToCopy';
import { IUserKeyData } from 'lib/interfaces/privateKeys';
import privateKeysService from 'lib/api/privateKeysApi';

import {
  PageWrapper,
  Container,
  Description,
  PageTitle,
  Title,
  TitleWrapper,
  KeyContentWrapper,
  KeyIconContainer,
  InfoDataContainer,
  CreatedAtContainer,
  NoKeysContainer,
} from './styled';

const AccountSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [copiedKey, setCopiedKey] = useState<boolean>(false);
  const [keyToDelete, setKeyToDelete] = useState<string | null>(null);
  const [apiKeys, setApiKeys] = useState<IUserKeyData[]>([]);

  const message = useMessage();
  const token = useToken();

  const createNewPrivateKey = async () =>
    privateKeysService.post().then((response) => setApiKeys(response.data.apiKeys || []));

  const fetchPrivateKeys = async () =>
    privateKeysService.get().then((response) => setApiKeys(response.data.apiKeys || []));

  const deletePrivateKey = async (apiKey: string) =>
    privateKeysService.delete(apiKey).then((response) => {
      setApiKeys(response.data.apiKeys || []);
      message.success(
        <div>
          <span>Your API key has been deleted.</span>
          <br />
          <span>Please note that you can no longer generate tokens with this API key.</span>
        </div>,
      );
    });

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }
      await fetchPrivateKeys();
      setIsLoading(false);
    };
    fetchData();
  }, [token]);
  return (
    <div>
      <PageTitle>Account Settings</PageTitle>
      <PageWrapper>
        <Container style={{ marginTop: '20px' }} data-cy="account-settings-keys-container">
          <TitleWrapper>
            <Title>API Key Management</Title>
            {apiKeys?.length < MAXIMUM_KEYS_TO_GENERATE && (
              <Button
                type="border"
                onClick={createNewPrivateKey}
                data-cy="account-settings-add-key-button"
              >
                Create API Key
              </Button>
            )}
          </TitleWrapper>
          <Description>Create and manage up to five API Keys below.</Description>
          <Spin spinning={isLoading}>
            {apiKeys?.length === 0 && (
              <NoKeysContainer data-cy="account-settings-keys-empty">
                <NoKey />
                <div style={{ marginLeft: '16px' }}>No API key created</div>
              </NoKeysContainer>
            )}
            {apiKeys?.map((value) => (
              <KeyContentWrapper
                key={value.key}
                data-cy={`account-settings-key-${value.key}-block`}
              >
                <KeyIconContainer>
                  <Key />
                </KeyIconContainer>
                <InfoDataContainer>
                  <span style={{ fontSize: '13px' }}>{value.key}</span>
                  <CreatedAtContainer>
                    Created at: {transformDate(value.createdAt)}
                  </CreatedAtContainer>
                </InfoDataContainer>
                <Tooltip title={copiedKey ? 'Copied' : 'Copy to clipboard'} placement="right">
                  <Button
                    onClick={() => {
                      clickToCopy(value.key, setCopiedKey);
                    }}
                    type="border"
                    htmlType="submit"
                    style={{ marginRight: '12px' }}
                    data-cy={`account-settings-copy-key-${value.key}-button`}
                  >
                    <Copy style={{ width: '20px' }} />
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => {
                    setKeyToDelete(value.key);
                  }}
                  type="border"
                  htmlType="submit"
                  data-cy={`account-settings-delete-key-${value.key}-button`}
                >
                  <TrashCan style={{ width: '20px' }} />
                </Button>
              </KeyContentWrapper>
            ))}
            {keyToDelete && (
              <DeleteConfirmation
                onDelete={() => {
                  deletePrivateKey(keyToDelete);
                  setKeyToDelete(null);
                }}
                onClose={() => setKeyToDelete(null)}
                title="Delete API Key"
                text="You are about to delete this API Key. Please note, any existing integrations that use this key will stop working right after its deletion!"
                width={480}
                primaryButtonLabel="Delete"
                primaryButtonDanger
                hideFooterBorder={false}
                isDefaultFooter={false}
              />
            )}
          </Spin>
        </Container>
      </PageWrapper>
    </div>
  );
};

export default AccountSettings;
