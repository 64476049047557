export enum WebhookPayloadAnswersType {
  CURRENT = 'current',
  DIFF = 'diff',
}

export enum WebhookPayloadAnswersLayout {
  UUIDS = 'uuids',
  TITLES_UUIDS_VALUES = 'titlesUuidsValues',
  TITLES_VALUES = 'titlesValues',
}
