import React from 'react';
import { Table } from 'antd';
import { Tooltip } from 'components/Form';
import { CircleIcon, InfoSections } from './styled';
import { COLORS } from '../../lib/constants/tableColors';

interface IWidgetStatsTable {
  dataSource: Array<{}>;
}

const WidgetStatsTable = ({ dataSource }: IWidgetStatsTable) => {
  const topicsPerColor: Array<string> = [];
  const columns = [
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      width: '35%',
      render: (text: string) => {
        if (topicsPerColor.indexOf(text) === -1) {
          topicsPerColor.push(text);
        }
        return (
          <>
            <CircleIcon style={{ backgroundColor: COLORS[topicsPerColor.length - 1] }} /> {text}
          </>
        );
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
      width: '35%',
    },
    {
      title: (
        <>
          Opt-in{' '}
          <Tooltip title="Total amount of opt-in for the specific option" placement="right" />
        </>
      ),
      dataIndex: 'optIn',
      width: '15%',
      key: 'optIn',
    },
    {
      title: (
        <>
          Opt-in Rate{' '}
          <Tooltip
            title="Relative value to the total amount of preferences under this widget"
            placement="right"
          />
        </>
      ),
      dataIndex: 'optInRate',
      key: 'optInRate',
      width: '15%',
      render: (text: string) => {
        return <>{text} %</>;
      },
    },
  ];
  return (
    <>
      <InfoSections
        style={{
          marginTop: '20px',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
      >
        <h3>
          Preferences Options
          <span style={{ paddingLeft: '10px' }}>
            <Tooltip
              title="Here you can see the distribution of the end-user submitted preferences under specific Configuration across the Widgets this Configuration contains"
              placement="right"
            />
          </span>
        </h3>
      </InfoSections>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </>
  );
};

export default WidgetStatsTable;
