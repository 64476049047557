import styled from 'styled-components';

import { InputNumber } from 'antd';

export default styled(InputNumber)`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => props.theme.colors.primaryText};

  & input {
    height: 40px;
  }

  & .ant-input-group {
    height: 40px;

    & .ant-input-group-addon {
      background: ${(props) => props.theme.colors.darkGray};
      color: ${(props) => props.theme.colors.gray};
    }

    & .ant-input-number-input {
      height: 100%;
    }
  }

  & .ant-input-number-handler-wrap {
    display: none;
  }
`;
