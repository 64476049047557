import React, { useState } from 'react';
import Menu from 'components/Menu/Menu';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';
import EditPermissions from 'components/Modal/EditPermissions';
import { Tooltip } from 'antd';
import { Pac, PacType } from 'lib/api/pacApi';
import useUserData from '../../lib/hooks/useUserData';

interface IWidgetMenuProps {
  pac: Pac;
  onPreview: () => void;
  fetchPac: () => void;
  onDelete: () => void;
  onDeactivate: () => void;
  onActivate: () => void;
  onSwitchPacType: (type: PacType) => void;
  isDeleteDisabled: boolean;
  dataCy: string;
}

const PacMenu = ({
  onDelete,
  pac,
  onPreview,
  isDeleteDisabled,
  fetchPac,
  dataCy,
  onDeactivate,
  onActivate,
  onSwitchPacType,
}: IWidgetMenuProps) => {
  const [isDeleteWidgetConfiguration, setIsDeleteConfigurationModal] = useState(false);
  const [isEditPermissionModalOpen, setIsEditPermissionModalOpen] = useState(false);
  const { isSuperAdmin } = useUserData();

  return (
    <Menu defaultIcon dataCy={dataCy}>
      <Menu.Item data-cy={`pac-${pac.uuid}-action-preview`} key="editDetails" onClick={onPreview}>
        Preview
      </Menu.Item>
      <Menu.Item
        data-cy={`pac-${pac.uuid}-action-edit-permissions`}
        key="editTopics"
        onClick={() => setIsEditPermissionModalOpen(true)}
      >
        Edit Permissions
      </Menu.Item>

      {isSuperAdmin && pac.type === PacType.FULL && (
        <Menu.Item
          data-cy={`pac-${pac.uuid}-action-switch-lite`}
          key="switch"
          onClick={() => onSwitchPacType(PacType.LITE)}
        >
          Switch to Lite
        </Menu.Item>
      )}
      {isSuperAdmin && pac.type === PacType.LITE && (
        <Menu.Item
          data-cy={`pac-${pac.uuid}-action-switch-full`}
          key="switch"
          onClick={() => onSwitchPacType(PacType.FULL)}
        >
          Switch to Full
        </Menu.Item>
      )}

      {isSuperAdmin && !pac.deactivated && (
        <Menu.Item
          data-cy={`pac-${pac.uuid}-action-deactivate`}
          key="deactivate"
          onClick={onDeactivate}
        >
          Deactivate
        </Menu.Item>
      )}
      {isSuperAdmin && pac.deactivated && (
        <Menu.Item
          data-cy={`pac-${pac.uuid}-action-deactivate`}
          key="deactivate"
          onClick={onActivate}
        >
          Activate
        </Menu.Item>
      )}
      <Menu.Divider />

      <Tooltip
        title={
          isDeleteDisabled ? 'This configuration contains widgets. Please delete them first.' : ''
        }
        placement="right"
        mouseLeaveDelay={0}
      >
        <span style={{ cursor: isDeleteDisabled ? 'not-allowed' : 'pointer' }}>
          <Menu.Item
            key="delete"
            disabled={isDeleteDisabled}
            onClick={() => setIsDeleteConfigurationModal(true)}
            data-cy={`pac-${pac.uuid}-action-delete`}
            delete
          >
            Delete Configuration
          </Menu.Item>
        </span>
      </Tooltip>

      {isDeleteWidgetConfiguration && (
        <DeleteConfirmation
          onDelete={() => {
            onDelete();
            setIsDeleteConfigurationModal(false);
          }}
          onClose={() => setIsDeleteConfigurationModal(false)}
          primaryButtonLabel="Delete"
          primaryButtonDanger
          width={480}
          hideFooterBorder={false}
          isDefaultFooter={false}
          title="Delete Configuration"
          text="Are you sure you want to delete this Configuration?"
        />
      )}
      {isEditPermissionModalOpen && (
        <EditPermissions
          owner={pac.creatorId}
          usersList={pac.customerIds}
          pac={pac}
          onClose={() => {
            setIsEditPermissionModalOpen(false);
            fetchPac();
          }}
          title="Edit Permissions"
        />
      )}
    </Menu>
  );
};

export default PacMenu;
