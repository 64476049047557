import styled from 'styled-components';

import { Select } from 'antd';

interface StyledSelectProps {
  $isUnselected?: boolean;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  & .ant-select-selector {
    border-color: ${(props) => props.theme.colors.border};
    border-radius: 4px !important;
    min-height: 40px;
    align-items: center;
    padding: 2px 8px;
    max-width: 100%;
  }

  & .ant-tag {
    display: flex;
    min-width: 0;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 0 2px;
    box-sizing: border-box;
  }

  & .ant-select-selection-item {
    font-size: 14px;
    ${(props) => props.$isUnselected && 'color: lightgrey !important;'}
  }

  & .ant-select-selection-overflow-item {
    width: auto;
  }

  & .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: 400;
  }

  & .ant-select-item-option-content {
    white-space: break-spaces !important;
    word-break: break-word !important;
  }
`;

export const Option = styled(Select.Option)`
  white-space: break-spaces;
  word-break: break-word;

  & .ant-select-item-option-selected {
    color: ${(props) => props.theme.colors.black(0.85)};
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.background};
  }
`;
