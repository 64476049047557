import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 20px;
`;

interface IProps {
  active: boolean;
  disabled?: boolean;
}

export const DotsButton = styled.button<IProps>`
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transform: rotate(90deg);
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  & span,
  svg {
    width: 100%;
    height: 100%;
  }

  & svg path {
    fill: ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.primaryIcons};
  }
`;

export const List = styled.div<{ index: number }>`
  right: -8px;
  top: 100%;
  background: white;
  border-radius: 4px;
  position: absolute;
  padding: 8px 0;
  min-width: 144px;
  z-index: ${({ index }) => index};
  box-shadow: ${(props) => props.theme.tooltipShadow};
`;

const itemHover = css`
  &:hover {
    background: ${(props) => props.theme.colors.lightBorder};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const itemDisabled = css`
  opacity: 0.75;
  cursor: default;
`;

export const Item = styled.button<{ isDisabled?: boolean; delete?: boolean }>`
  display: block;
  font-size: 14px;
  color: ${(props) => (props.delete ? props.theme.colors.red : props.theme.colors.primaryText)};
  padding: 0 16px;
  height: 32px;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  text-align: left;
  white-space: nowrap;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) => (props.isDisabled ? itemDisabled : itemHover)}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.border};
  margin: 4px 0;
`;
