import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.colors.primaryIcons};
  white-space: pre-wrap;
  margin-right: 40px;

  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Placeholder = styled.div`
  max-width: 740px;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 8px;

  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const ImageWrapper = styled.div`
  padding: 40px 27px;
  background-color: ${(props) => props.theme.colors.lightBlue};
`;
