import { AxiosResponse } from 'axios';
import { IStyles } from 'lib/interfaces/styles';
import { AbstractApiService } from './abstractApiService';
import { Axios } from './axiosInstance';

class StylingService extends AbstractApiService {
  get(pacId: string): Promise<AxiosResponse<IStyles>> {
    return Axios.get(`pac-styles/${pacId}`, this.getApiHeaders());
  }

  update(pacId: string, stylesObject: IStyles): Promise<AxiosResponse<IStyles>> {
    return Axios.patch(`pac-styles/${pacId}`, stylesObject, this.getApiHeaders());
  }

  post(pacId: string, stylesObject: IStyles): Promise<AxiosResponse<IStyles>> {
    return Axios.post(`pac-styles/${pacId}`, stylesObject, this.getApiHeaders());
  }
}

export default new StylingService();
