import styled from 'styled-components';
import backgroundImage from 'assets/background.svg';

export const Top = styled.div`
  text-align: center;
  padding: 80px 0;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  background: no-repeat url(${backgroundImage});
  background-size: cover;
  left: 50%;
  color: ${(props) => props.theme.colors.white};
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;
`;

export const Container = styled.div`
  width: 400px;
  padding: 24px;
  background: white;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 12px;
`;

export const Message = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.primaryIcons};

  & button {
    text-decoration: underline;
    background: none;
    display: inline;
    border: none;
    cursor: pointer;
  }
`;
