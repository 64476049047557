import React from 'react';
import Modal from 'components/Modal/Modal';

interface IWidgetMenuProps {
  onDelete: () => void;
  onClose: () => void;
  text: string;
  title?: string;
  width?: number;
  primaryButtonLabel?: string;
  primaryButtonDanger?: boolean;
  hideFooterBorder?: boolean;
  isDefaultFooter?: boolean;
}

const DeleteConfirmationModal = ({
  onDelete,
  onClose,
  text,
  title = 'Delete Widget',
  width = 770,
  primaryButtonLabel = 'Confirm',
  primaryButtonDanger = false,
  hideFooterBorder = true,
  isDefaultFooter = true,
}: IWidgetMenuProps) => (
  <Modal
    visible
    title={title}
    secondaryButton={{ label: 'Cancel', onClick: onClose }}
    width={width}
    bodyHeight={500}
    isConfirmation
    primaryButton={{
      label: primaryButtonLabel,
      onClick: onDelete,
      danger: primaryButtonDanger,
    }}
    hideFooterBorder={hideFooterBorder}
    onClose={onClose}
    isDefaultFooter={isDefaultFooter}
  >
    <span dangerouslySetInnerHTML={{ __html: text }} />
  </Modal>
);

export default DeleteConfirmationModal;
