import styled from 'styled-components';

export const TableLink = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 20px;
  margin-bottom: 0;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: 24px;
`;

export const InputContainer = styled.div`
  width: 35%;
  margin-right: 10px;
  input {
    height: 40px;
  }
`;

export const TextInputContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const EmptyUserData = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;
  background-color: rgba(247, 247, 247, 1);
`;

export const Text = styled.p`
  opacity: 1;
  color: rgba(151, 151, 151, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 20px;
`;

export const TableContainer = styled.div``;

export const ResultsHeader = styled.div`
  border-radius: 4px 4px 0px 0px;
  border-bottom: none;
  border: 1px solid rgba(222, 222, 222, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;

  p {
    opacity: 1;
    color: rgba(48, 48, 48, 1);
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
    margin-bottom: 0;
  }
`;
