import styled from 'styled-components';

export const Container = styled.div`
  width: 740px;
`;

export const WidgetBox = styled.div`
  width: 95%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 20px;
  padding-right: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WidgetTitleContainer = styled.div`
  flex: 1;
  width: 85%;
`;

export const WidgetSwitchContainer = styled.div`
  margin-right: 5px;
  width: 40px;

  & button {
    width: 40px;
  }
`;

export const WidgetTitle = styled.p`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryText};
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

export const WidgetID = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryIcons};
  display: flex;
  align-items: center;

  & svg {
    height: 16px;
    cursor: pointer;
  }
`;

export const WidgetOverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.primaryIcons};

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.highlightBlue};
    font-weight: 700;
    height: 20px;
    border-radius: 7px;
    padding-left: 7px;
    padding-right: 7px;
  }
`;

export const AddWidgetButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const WarningText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 20px;
  font-weight: 400;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
`;

export const DragWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  & span {
    height: 16px;
    width: 16px;

    & > svg {
      fill: ${(props) => props.theme.colors.defaultIcons};
    }
  }
`;

export const WidgetMenuContainer = styled.div`
  position: absolute;
  right: 42px;
  top: 31px;
  & span {
    height: 16px;
    width: 16px;

    & svg {
      fill: ${(props) => props.theme.colors.defaultIcons};
    }
  }
`;
