import { Layout as AntLayout, Menu as AntMenu } from 'antd';
import styled from 'styled-components';

const { Header: AntHeader } = AntLayout;

interface IProps {
  $hideInfo?: boolean;
}

export const Header = styled(AntHeader)<IProps>`
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
  max-height: 80rem;
  position: fixed;
  z-index: 10;

  & > img {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    border-right: ${(props) =>
      props.$hideInfo ? 'none' : `1px solid ${props.theme.colors.border}`};
    padding: 0 24px 0 16px;
    margin-right: 24px;
  }
`;

export const Menu = styled(AntMenu)`
  padding: 12px 0;
`;

export const MenuItem = styled(AntMenu.Item)`
  padding-left: 16px;
`;

export const BreadCrumbContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
`;

export const UserWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid ${(props) => props.theme.colors.border};
  height: 100%;

  svg {
    width: 24px;
    height: 24px;
  }

  .anticon {
    border-radius: 100px;
    padding: 3px;
    vertical-align: middle;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  padding: 0 16px 12px 16px;

  & #user-placeholder {
    width: 36px;
    height: 36px;
    margin: 16px 16px 0 0;
  }
`;

export const UserName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const DateLabel = styled.div`
  color: ${(props) => props.theme.colors.primaryIcons};
  font-size: 12px;
`;

export const DropdownItem = styled.div`
  margin: 5px 15px;
`;

export const BeamerButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
`;
