import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = ({ viewBox }: { viewBox: boolean }) => {
  const view = viewBox ? '0 0 24 24' : '0 0 30 30';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox={view} width="24">
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
};

export default (props: any) => <Icon component={CustomIcon} {...props} />;
