import React from 'react';
import { StyledButton, Loading } from './styled';

export interface IButtonProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'default' | 'primary' | 'link' | 'border' | 'white';
  danger?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: 'default' | 'large';
  Icon?: React.ReactNode;
  style?: object;
  hideTextWhenLoading?: boolean;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = ({
  children,
  type = 'default',
  size = 'default',
  Icon,
  loading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  hideTextWhenLoading = false,
  htmlType = 'button',
  ...props
}: IButtonProps) => (
  <StyledButton
    {...props}
    styleType={type}
    size={size}
    type={htmlType}
    onClick={loading ? () => null : onClick}
    loading={loading ? '1' : '0'}
  >
    {loading ? <Loading removeMargin={hideTextWhenLoading} /> : Icon}
    {hideTextWhenLoading && loading ? null : children}
  </StyledButton>
);

export default Button;
