import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/Button';

import { getPreviewLinkByPacId } from 'lib/helpers';
import { ButtonsWrapper } from './styled';
import useUserData from '../../lib/hooks/useUserData';

interface IProps {
  onSave: () => void;
  isSaveDisabled: boolean;
  isPacDeactivated?: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const SidebarButtons = ({
  onSave,
  isSaveDisabled,
  isPacDeactivated,
  setHasUnsavedChanges,
}: IProps) => {
  const params: { pacId?: string } = useParams();
  const { isSuperAdmin } = useUserData();
  const isSaveButtonDisabled = useMemo(() => {
    if (isPacDeactivated && !isSuperAdmin) {
      return true;
    }

    return isSaveDisabled;
  }, [isPacDeactivated, isSuperAdmin, isSaveDisabled]);

  useEffect(() => {
    setHasUnsavedChanges(!isSaveDisabled);
  }, [isSaveButtonDisabled]);

  return (
    <ButtonsWrapper>
      <a
        href={getPreviewLinkByPacId(params.pacId || '')}
        target="_blank"
        data-cy="configuration-pac-preview-link"
        rel="noopener noreferrer"
      >
        Preview
      </a>
      <Button
        data-cy="configuration-pac-save-button"
        onClick={() => onSave()}
        type={isPacDeactivated && !isSuperAdmin ? undefined : 'primary'}
        disabled={isSaveButtonDisabled}
      >
        Save
      </Button>
    </ButtonsWrapper>
  );
};

export default SidebarButtons;
