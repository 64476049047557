import styled from 'styled-components';

export const CodeExample = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  display: block;
  margin-top: 10px;
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  min-height: 43px;

  & > div,
  strong {
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    -webkit-user-select: none; /* Chrome all / Safari all */
    font-family: 'Courier New', Times, serif;
    user-select: none;
    word-break: break-word;
  }

  span {
    width: 85%;
  }

  .copyContainer {
    display: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBackgroundBlue};
    cursor: pointer;
    transition: background-color 250ms;
    .copyContainer {
      display: block;
      transition: display 250ms;
    }
  }
`;
