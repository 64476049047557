import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Radio } from 'antd';
import { Description, Title } from 'shared-components/ModalsContent';

import { StyledSelect as Select } from 'components/Select/Select';
import { Tooltip } from 'components/Form';

import { IWidget } from 'lib/interfaces/widget';
import { WebhookPayloadAnswersType, WebhookPayloadAnswersLayout } from 'lib/enums/webhooks';
import { SettingsSectionWrapper, InputsWrapper, StyledItem, Label } from './styled';

interface ISettings {
  topicUuid?: string;
  optionUuid?: string;
  requestData?: Array<string>;
  answersLayout?: WebhookPayloadAnswersLayout;
  answersType?: WebhookPayloadAnswersType;
}

interface IProps {
  form: FormInstance<ISettings>;
  initialValues?: ISettings;
  onFieldsChange: () => void;
  widget: IWidget;
}

const SettingsStep = ({ initialValues, form, onFieldsChange, widget }: IProps) => {
  const [selectedTopicId, setSelectedTopicId] = useState<string>('');
  const [selectedOptionId, setSelectedOptionId] = useState<string>('');
  const [showRadioButtons, setShowRadioButtons] = useState<boolean>(true);
  const [disableAnswersType, setDisableAnswersType] = useState<boolean>(true);

  useEffect(() => {
    setSelectedTopicId(form.getFieldValue('topicUuid'));
  }, [form]);

  useEffect(() => {
    setSelectedOptionId(form.getFieldValue('optionUuid'));
  }, [form]);

  const handleRequestDataChange = (value: any) => {
    if (!value.includes('answers')) {
      form.setFieldValue('answersLayout', WebhookPayloadAnswersLayout.UUIDS);
      form.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setShowRadioButtons(false);
    }
    if (!showRadioButtons) {
      form.setFieldValue('answersLayout', WebhookPayloadAnswersLayout.UUIDS);
      form.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setShowRadioButtons(true);
    }
  };

  const handleAnswersLayoutChange = (el: string) => {
    if (el === WebhookPayloadAnswersLayout.UUIDS) {
      form.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setDisableAnswersType(true);
    }
    if (el !== WebhookPayloadAnswersLayout.UUIDS && disableAnswersType) {
      setDisableAnswersType(false);
    }
  };

  return (
    <>
      <Title>Webhook Settings</Title>
      <Description>
        Webhook can be triggered only when certain topic/option are selected. Please leave these
        fields empty if you want to fire it with every widget submission.
      </Description>
      <Form
        name="webhook-settings"
        form={form}
        initialValues={initialValues}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <SettingsSectionWrapper>
          <p>Webhook Call Condition</p>
          <InputsWrapper>
            <StyledItem label="Topic" name="topicUuid" style={{ marginBottom: '24px' }}>
              <Select
                placeholder="Select Topic"
                data-cy="webhook-new-settings-topic-id-select"
                // any here needed for ant-design
                onChange={(el: any) => {
                  setSelectedTopicId(el);
                  form.setFieldValue('topicUuid', el);
                  form.setFieldValue('optionUuid', '');
                  setSelectedOptionId('');
                }}
                $isUnselected={selectedTopicId === ''}
                options={[
                  { value: '', label: <span>Any value</span> },
                  ...widget.topics.map((topic) => ({
                    value: topic.uuid,
                    label: <span>{topic.name}</span>,
                  })),
                ]}
              />
            </StyledItem>

            <StyledItem label="Option" name="optionUuid" style={{ marginBottom: '24px' }}>
              <Select
                placeholder="Select Option"
                data-cy="webhook-new-settings-option-id-select"
                onChange={(el: any) => {
                  form.setFieldValue('optionUuid', el);
                  setSelectedOptionId(el);
                }}
                $isUnselected={selectedOptionId === ''}
              >
                <Select.Option key="option-unselected" value="">
                  Any value
                </Select.Option>
                {widget.topics
                  .find((topic) => topic.uuid === selectedTopicId)
                  ?.options.map((option) => (
                    <Select.Option key={option.uuid} value={option.uuid}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </StyledItem>
          </InputsWrapper>
        </SettingsSectionWrapper>

        <SettingsSectionWrapper>
          <p>Payload</p>
          <InputsWrapper>
            <StyledItem
              label="Data"
              name="requestData"
              style={{ marginBottom: '24px' }}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                placeholder="Select Data"
                style={{ width: '100%' }}
                mode="tags"
                data-cy="webhook-new-settings-payload-select"
                onChange={handleRequestDataChange}
              >
                <Select.Option key="widgetId" value="widgetId">
                  Widget UUID
                </Select.Option>
                <Select.Option key="userId" value="userId">
                  User ID
                </Select.Option>
                <Select.Option key="answers" value="answers">
                  Answers
                </Select.Option>
              </Select>
            </StyledItem>
            {showRadioButtons && (
              <>
                <Label>
                  Answers to include{' '}
                  <Tooltip
                    title="Here you can specify what data and in which format should be included as part of answers object in the webhook payload."
                    placement="right"
                  />
                </Label>
                <StyledItem name="answersLayout" style={{ marginBottom: '24px' }}>
                  <Radio.Group
                    optionType="default"
                    onChange={(el) => handleAnswersLayoutChange(el.target.value)}
                  >
                    <Radio.Button
                      value={WebhookPayloadAnswersLayout.UUIDS}
                      data-cy="webhook-new-settings-answers-layout-uuids"
                    >
                      Only UUIDs
                    </Radio.Button>
                    <Radio.Button value={WebhookPayloadAnswersLayout.TITLES_UUIDS_VALUES}>
                      Titles, UUIDs, and Values
                    </Radio.Button>
                    <Radio.Button value={WebhookPayloadAnswersLayout.TITLES_VALUES}>
                      Titles and Values
                    </Radio.Button>
                  </Radio.Group>
                </StyledItem>

                <StyledItem name="answersType" style={{ marginBottom: '24px' }}>
                  <Radio.Group optionType="default">
                    <Radio.Button
                      value={WebhookPayloadAnswersType.CURRENT}
                      disabled={disableAnswersType}
                    >
                      All selected options
                    </Radio.Button>
                    <Radio.Button
                      value={WebhookPayloadAnswersType.DIFF}
                      disabled={disableAnswersType}
                    >
                      Only updated options
                    </Radio.Button>
                  </Radio.Group>
                </StyledItem>
              </>
            )}
          </InputsWrapper>
        </SettingsSectionWrapper>
      </Form>
    </>
  );
};

export default SettingsStep;
