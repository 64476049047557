import { AppContext } from 'lib/constants/global';
import { useContext } from 'react';

const useUserData = () => {
  const ctx = useContext(AppContext);

  return ctx.user;
};

export default useUserData;
