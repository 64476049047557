import { Table as AntTable } from 'antd';
import styled from 'styled-components';

interface ITable {
  shadow?: boolean;
  noBorder?: boolean;
  autoHeight?: boolean;
  isClickable?: boolean;
  isRemovePadding?: boolean;
  borderRadius?: string;
}

export const StyledTable = styled(AntTable)<ITable>`
  ${(props) => props.shadow && `box-shadow: ${props.theme.tableShadow};`}
  .ant-table-content {
    ${(props) => !props.autoHeight && 'min-height: 460px;'}
    border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.gray}`)};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '4px')};
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 15px 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
    background: white;
    color: ${(props) => props.theme.colors.primaryText};

    &:before {
      display: none;
    }
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
    max-width: 30%;
  }

  .ant-table-row {
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
    & td {
      word-break: break-word;
      max-width: 0;
    }
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-typography {
    margin-bottom: 0;
  }
`;

export const NewLabel = styled.span`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  width: 32px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  margin-left: 8px;

  &:after {
    content: 'New';
  }
`;
