import React, { useState, Children } from 'react';
import { Tooltip } from 'antd';
import ClickAwayListener from 'react-click-away-listener';
import { Dots as DotsIcon } from 'components/Icons';

import { Container, DotsButton, Divider, List, Item } from './styled';

const Menu = ({
  children,
  index = 1,
  defaultIcon,
  disabled,
  tooltip,
  testId,
  dataCy,
}: {
  children: React.ReactNode;
  index?: number;
  defaultIcon?: boolean;
  disabled?: boolean;
  tooltip?: string | null;
  testId?: string;
  dataCy?: string;
}) => {
  const [showList, setShowList] = useState(false);
  const areChildrenExist = !!Children.toArray(children).length;

  return areChildrenExist ? (
    <ClickAwayListener onClickAway={() => setShowList(false)}>
      <Container data-testid={testId}>
        <Tooltip title={tooltip} placement="right">
          <DotsButton
            data-cy={dataCy}
            active={showList}
            onClick={(e) => {
              setShowList(!showList);
              e.stopPropagation();
            }}
            disabled={disabled}
          >
            <DotsIcon viewBox={defaultIcon} />
          </DotsButton>
          {showList && <List index={index + 1}>{children}</List>}
        </Tooltip>
      </Container>
    </ClickAwayListener>
  ) : null;
};

Menu.Item = Item;
Menu.Divider = Divider;

export default Menu;
