export const filterObjectByKey = (
  rawObject: { [key: string]: string },
  allowedKeys: Array<string>,
) =>
  Object.keys(rawObject)
    .filter((key) => allowedKeys.includes(key))
    .reduce((obj, key) => {
      Object(obj)[key] = rawObject[key];
      return obj;
    }, {});
