import { AxiosResponse } from 'axios';
import { IWidget } from 'lib/interfaces/widget';
import { AbstractApiService } from './abstractApiService';
import { Axios } from './axiosInstance';

class WidgetApiService extends AbstractApiService {
  getById(widgetUuid: string): Promise<AxiosResponse<IWidget>> {
    return Axios.get(`/widgets/${widgetUuid}`, this.getApiHeaders());
  }

  deleteById(widgetUuid: string): Promise<AxiosResponse> {
    return Axios.delete(`/widgets/${widgetUuid}`, {
      ...this.getApiHeaders(),
      responseType: 'blob',
    });
  }
}

export default new WidgetApiService();
