import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C11.38 20 12.5 18.88 12.5 17.5C12.5 16.89 12.27 16.3 11.86 15.83C11.78 15.73 11.73 15.62 11.73 15.5C11.73 15.22 11.95 15 12.23 15H14C17.31 15 20 12.31 20 9C20 4.04 15.51 0 10 0ZM15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5C17 10.33 16.33 11 15.5 11ZM12.5 7C11.67 7 11 6.33 11 5.5C11 4.67 11.67 4 12.5 4C13.33 4 14 4.67 14 5.5C14 6.33 13.33 7 12.5 7ZM3 9.5C3 8.67 3.67 8 4.5 8C5.33 8 6 8.67 6 9.5C6 10.33 5.33 11 4.5 11C3.67 11 3 10.33 3 9.5ZM9 5.5C9 6.33 8.33 7 7.5 7C6.67 7 6 6.33 6 5.5C6 4.67 6.67 4 7.5 4C8.33 4 9 4.67 9 5.5Z"
      fill="#303030"
    />
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
