import { gql } from '@apollo/client';

const SUPER_ADMIN_QUERY = () => gql`
  query superAdminData {
    superAdminData(app: pmpConfiguration) {
      appAccess
      privileges {
        pmp
      }
    }
  }
`;

export default SUPER_ADMIN_QUERY;
