import React from 'react';
import styled from 'styled-components';
import { Translate as TranslateIcon } from 'components/Icons';

const AutoTranslateButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  & svg {
    height: 15px;
  }
`;

export default ({
  onClick,
  dataCY = '',
  isLoading,
  label,
  disabled,
}: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  dataCY?: string;
  isLoading: boolean;
  label: string;
  disabled?: boolean;
}) => (
  <AutoTranslateButton
    type="button"
    onClick={onClick}
    data-cy={dataCY}
    disabled={isLoading || disabled}
  >
    <TranslateIcon fill="#0045A5" /> {isLoading ? '... Loading' : label}
  </AutoTranslateButton>
);
