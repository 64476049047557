import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 18px;
  padding-left: 16px;
  margin-bottom: 5px;
`;

export const SubTitle = styled.h2`
  font-size: 16px;
  > span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  padding-left: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 12px;
`;
