import React, { useEffect, useState } from 'react';
import Item from 'antd/lib/descriptions/Item';
import { Tooltip } from 'components/Form';
import { Column, ColumnConfig } from '@ant-design/plots';
import { StyledSelect as Select } from 'components/Select/Select';
import { AnalyticsOverview, TotalPerPac } from '../../lib/api/analyticsApi';
import {
  GraphContainer,
  InfoSections,
  InfoSectionsContainer,
  OverviewSection,
  OverviewStatsGroupContainer,
  SectionsContainer,
} from './styled';

interface IPacsStats {
  updatePacIndex: (value: number) => void;
  analyticOverview: AnalyticsOverview;
  preferencesPerWidget: ColumnConfig;
  selectedPacIndex: number;
}

const PacsStats = ({
  updatePacIndex,
  analyticOverview,
  preferencesPerWidget,
  selectedPacIndex,
}: IPacsStats) => {
  const [selectedPac, setSelectedPac] = useState<TotalPerPac>({} as TotalPerPac);

  useEffect(() => {
    setSelectedPac(analyticOverview.totalsPerPac[selectedPacIndex]);
  }, [selectedPacIndex]);

  return (
    <SectionsContainer>
      <OverviewSection>
        <div>
          <h3>Configuration Analytics</h3>
        </div>
        <OverviewStatsGroupContainer>
          <Item>
            <Select
              dropdownMatchSelectWidth={false}
              style={{ width: '320px' }}
              onSelect={(value: any) => updatePacIndex(value)}
              defaultValue={0}
            >
              {analyticOverview.totalsPerPac.map((pacData, index) => (
                <Select.Option key={pacData.uuid} value={index}>
                  {pacData.title}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </OverviewStatsGroupContainer>
      </OverviewSection>
      <InfoSectionsContainer style={{ marginTop: '20px' }}>
        <InfoSections>
          <p>
            Unique User Ids{' '}
            <Tooltip
              title="Total amount of unique, non-identifiable User IDs (data subjects) that have preference objects saved under current Configuration"
              placement="right"
            />
          </p>
          <h2>{selectedPac?.uniqueUsers}</h2>
        </InfoSections>
        <InfoSections>
          <p>
            Preferences{' '}
            <Tooltip
              title="Total amount of preference data submissions for each separate widget under current Configuration"
              placement="right"
            />
          </p>
          <h2>{selectedPac?.totalPreferences}</h2>
        </InfoSections>
        <InfoSections>
          <p>
            Widgets{' '}
            <Tooltip
              title="Amount of widgets configured under current Configuration"
              placement="right"
            />
          </p>
          <h2>{selectedPac?.totalWidgets}</h2>
        </InfoSections>
        <InfoSections>
          <p>
            Topics{' '}
            <Tooltip
              title="Total amount of topics available across all configured widgets under current Configuration"
              placement="right"
            />
          </p>
          <h2>{selectedPac?.totalTopics}</h2>
        </InfoSections>
      </InfoSectionsContainer>
      {preferencesPerWidget.data.length > 0 && (
        <>
          <InfoSections
            style={{
              marginTop: '20px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
          >
            <h3>Preferences per Widget</h3>
            <Tooltip
              title="Here you can see the distribution of the end-user submitted preferences under specific Configuration across the Widgets this Configuration contains"
              placement="right"
            />
          </InfoSections>
          <GraphContainer style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
            <Column {...preferencesPerWidget} />
          </GraphContainer>
        </>
      )}
    </SectionsContainer>
  );
};

export default PacsStats;
