import styled from 'styled-components';
import { Flex } from 'shared-components';
import { Layout as AntLayout } from 'antd';

export const Layout = styled(AntLayout)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primaryText};
`;

export const LayoutHeader = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
`;

export const NotAuthorizedContent = styled(AntLayout.Content)`
  background-color: ${({ theme }) => theme.colors.background};
  height: calc(100vh - 4rem);
`;

export const Container = styled(Flex)`
  position: relative;
  top: 64px;
`;

export const Content = styled.div`
  margin-left: 232px;
  width: 100%;
`;
