import React, { useEffect, useState } from 'react';
import { StyledSelect as Select } from 'components/Select/Select';
import { Form } from 'antd';
import {
  InfoSections,
  InfoSectionsContainer,
  OverviewSection,
  OverviewStatsGroupContainer,
  SectionsContainer,
} from './styled';
import WidgetStatsTable from './WidgetStatsTable';
import { AnalyticsOverview, IWidgetStat } from '../../lib/api/analyticsApi';

interface IWidgetStats {
  updateWidgetIndex: (value: number) => void;
  selectedPacIndex: number;
  selectedWidgetIndex: number;
  analyticOverview: AnalyticsOverview;
  tableDataSource: Array<{}>;
}

const WidgetStats = ({
  analyticOverview,
  updateWidgetIndex,
  selectedPacIndex,
  selectedWidgetIndex,
  tableDataSource,
}: IWidgetStats) => {
  const [detailsForm] = Form.useForm();
  const [options, setOptions] = useState<Array<{}>>([]);
  const [selectedWidgetFromPac, setSelectedWidgetFromPac] = useState<IWidgetStat>(
    {} as IWidgetStat,
  );

  useEffect(() => {
    setSelectedWidgetFromPac(
      analyticOverview.totalsPerPac[selectedPacIndex].statsPerWidget[selectedWidgetIndex],
    );

    setOptions(
      analyticOverview.totalsPerPac[selectedPacIndex].statsPerWidget.map((widget, index) => {
        return { value: index, label: widget.title };
      }),
    );

    detailsForm.setFieldValue('selectedOption', selectedWidgetIndex);
  }, [selectedPacIndex, selectedWidgetIndex]);

  return (
    <SectionsContainer>
      <OverviewSection>
        <div>
          <h3>Widget Analytics</h3>
        </div>
        <OverviewStatsGroupContainer>
          <Form form={detailsForm}>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <Form.Item name="selectedOption" initialValue={0}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      style={{ width: '320px' }}
                      onSelect={(value: any) => updateWidgetIndex(value)}
                      options={options}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Form>
        </OverviewStatsGroupContainer>
      </OverviewSection>
      <InfoSectionsContainer style={{ marginTop: '20px' }}>
        <InfoSections>
          <p>Topics </p>
          <h2>{selectedWidgetFromPac?.topicsStats?.length}</h2>
        </InfoSections>
        <InfoSections>
          <p>Preferences </p>
          <h2>{selectedWidgetFromPac?.totalPreferences}</h2>
        </InfoSections>
      </InfoSectionsContainer>
      {selectedWidgetFromPac?.topicsStats?.length > 0 && (
        <WidgetStatsTable dataSource={tableDataSource} />
      )}
    </SectionsContainer>
  );
};

export default WidgetStats;
