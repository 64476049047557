import { IAppContext } from 'lib/interfaces/appContext';
import { createContext } from 'react';

export const MAXIMUM_KEYS_TO_GENERATE = 5;
export const DEFAULT_PAGINATION_TOTAL = 25;

export const AppContext = createContext<IAppContext>({
  message: null,
  user: { isSuperAdmin: false },
});
