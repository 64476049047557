import styled from 'styled-components';

interface IFlexContainerProps {
  alignItems?: 'start' | 'center' | 'end';
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'end';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  flexBasis?: string;
  flexWrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
}

export const Flex = styled.div<IFlexContainerProps>`
  display: flex;
  align-items: ${(props) => props.alignItems || 'normal'};
  justify-content: ${(props) => props.justifyContent || 'normal'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  flex-basis: ${(props) => props.flexBasis || 'auto'};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
`;
