import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    version="1.1"
    width="24px"
    height="24px"
    viewBox="0 0 24.0 24.0"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="i0i">
        <path d="M10,0 C14.74,0 19,3.73 19,9.22 C19.59,9.53 20,10.14 20,10.84 L20,13.14 C20,13.86 19.59,14.47 19,14.78 L19,16 C19,17.1 18.1,18 17,18 L10,18 C9.45,18 9,17.55 9,17 L9.0067602,16.883801 C9.06479592,16.3880102 9.48928571,16 10,16 L17,16 L17,8.9 C17,4.96 13.74,1.78 9.78,1.9 C5.95,2.01 3,5.36 3,9.19 L3,14 C3,14.55 2.55,15 2,15 L1.85130541,14.9944872 C0.820041322,14.9177686 0,14.05 0,13 L0,11 C0,10.26 0.4,9.62 1,9.28 C1,3.65 5.31,0 10,0 Z M7,9 C7.55228475,9 8,9.44771525 8,10 C8,10.5522847 7.55228475,11 7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.44771525 6.44771525,9 7,9 Z M13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 C12.4477153,11 12,10.5522847 12,10 C12,9.44771525 12.4477153,9 13,9 Z M10.05,3 C13.04,3 15.52,5.18 16,8.03 C12.88,8 10.19,6.19 8.88,3.56 C8.35,6.24 6.49,8.44 4.02,9.45 C3.76,5.51 7.02,3 10.05,3 Z" />
      </clipPath>
      <clipPath id="i1i">
        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(2.0 3.0)">
      <g clipPath="url(#i0i)">
        <polygon points="0,0 20,0 20,18 0,18 0,0" stroke="none" fill="#979797" />
        <g transform="translate(-2.0 -3.0)">
          <g clipPath="url(#i1i)">
            <polygon points="2,3 22,3 22,21 2,21 2,3" stroke="none" fill="#979797" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
