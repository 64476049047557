import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Radio } from 'antd';
import { AxiosError } from 'axios';

import { IWidget } from 'lib/interfaces/widget';
import useMessage from 'lib/hooks/useMessage';
import { Description, Title } from 'shared-components/ModalsContent';
import { showErrorResponseNotification } from 'lib/helpers';
import { isNotEmptyField } from 'lib/regex/validations';

import WebhookApiService from 'lib/api/webhookApi';

import Modal from 'components/Modal/Modal';
import { StyledSelect as Select, Option } from 'components/Select/Select';
import { Input, Tooltip } from 'components/Form';

import { IWebhook } from 'lib/interfaces/webhooks';
import { WebhookPayloadAnswersType, WebhookPayloadAnswersLayout } from 'lib/enums/webhooks';

import { SettingsSectionWrapper, InputsWrapper, StyledItem } from './styled';

interface IProps {
  onClose: () => void;
  fetchWebhooks: () => void;
  widgetsList: Array<IWidget>;
  webhook: IWebhook;
}

const EditWebhookModal = ({ onClose, widgetsList, fetchWebhooks, webhook }: IProps) => {
  const preSelectedType = webhook.authentication?.enabled ? webhook.authentication.type : 'no';
  const [selectedTopicId, setSelectedTopicId] = useState<string>(webhook.topicUuid || '');
  const [selectedOptionId, setSelectedOptionId] = useState<string>(webhook.optionUuid || '');
  const message = useMessage();
  const [webhookForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(preSelectedType);
  const [showRadioButtons, setShowRadioButtons] = useState<boolean>(
    webhook.requestData.includes('answers'),
  );
  const [disableAnswersType, setDisableAnswersType] = useState<boolean>(
    webhook.answersLayout === WebhookPayloadAnswersLayout.UUIDS,
  );

  const widget = widgetsList.find((w) => w.uuid === webhook.widgetUuid) || ({} as IWidget);

  const onFieldsChange = (form: FormInstance) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleRequestDataChange = (value: any) => {
    if (!value.includes('answers')) {
      webhookForm.setFieldValue('answersLayout', WebhookPayloadAnswersLayout.UUIDS);
      webhookForm.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setShowRadioButtons(false);
    }
    if (!showRadioButtons) {
      webhookForm.setFieldValue('answersLayout', WebhookPayloadAnswersLayout.UUIDS);
      webhookForm.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setShowRadioButtons(true);
    }
  };

  const handleAnswersLayoutChange = (el: string) => {
    if (el === WebhookPayloadAnswersLayout.UUIDS) {
      webhookForm.setFieldValue('answersType', WebhookPayloadAnswersType.CURRENT);
      setDisableAnswersType(true);
    }
    if (el !== WebhookPayloadAnswersLayout.UUIDS && disableAnswersType) {
      setDisableAnswersType(false);
    }
  };

  const onPrimaryClick = async () => {
    try {
      const formData = await webhookForm.validateFields();
      setLoading(true);

      const authObject =
        formData.authentication.type === 'basic'
          ? {
              username: formData.authentication.payload.username,
              password: formData.authentication.payload.password,
            }
          : formData.authentication.type === 'api-key'
            ? {
                name: formData.authentication.payload.name,
                value: formData.authentication.payload.value,
                location: formData.authentication.payload.location,
              }
            : {};
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { uuid, createdAt, customerId, updatedAt, ...webhookData } = webhook;

      const response = await WebhookApiService.editWebhook(webhook.uuid, {
        ...webhookData,
        ...formData,
        authentication: {
          enabled: formData.authentication.type !== 'no',
          ...(formData.authentication.type !== 'no' && { type: formData.authentication.type }),
          payload: authObject,
        },
      });
      message.success(`Webhook has been edited: ${response.data ? response.data.uuid : ''}`);
      fetchWebhooks();
      onClose();
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.data.statusCode === 422) {
        message.error('Some mandatory data was not sent');
      } else {
        showErrorResponseNotification(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    webhookForm.setFieldValue(['authentication', 'type'], preSelectedType);
  }, [webhook]);

  return (
    <Modal
      visible
      title="Edit Webhook"
      secondaryButton={{
        label: 'Cancel',
        onClick: () => onClose(),
      }}
      primaryButton={{
        label: 'Save',
        onClick: onPrimaryClick,
        disabled: disableSave,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      <Title data-cy="webhook-edit-modal">{webhook.name}</Title>
      <Description>{webhook.webhookUrl}</Description>
      <Form
        name="webhook-settings"
        form={webhookForm}
        initialValues={webhook}
        style={{ padding: '16px' }}
        onFieldsChange={() => onFieldsChange}
      >
        <SettingsSectionWrapper>
          <p>Configuration</p>
          <InputsWrapper>
            <StyledItem
              label="Webhook Name"
              name="name"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="Webhook Name" data-cy="webhook-edit-configuration-name-input" />
            </StyledItem>
            <StyledItem
              label="Widget"
              name="widgetUuid"
              rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
              style={{ marginBottom: '24px' }}
            >
              <Select
                placeholder="Select Type"
                data-cy="webhook-edit-configuration-widget-id-select"
                onChange={() => {
                  webhookForm.setFieldValue('optionUuid', '');
                }}
              >
                {widgetsList.map((w) => (
                  <Option
                    key={w.uuid}
                    value={w.uuid}
                    data-cy={`webhook-edit-configuration-widget-id-option-${w.title}`}
                  >
                    {w.title}
                  </Option>
                ))}
              </Select>
            </StyledItem>
            <StyledItem
              label="Webhook URL"
              name="webhookUrl"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  type: 'url',
                  message: 'This field must be a valid url.',
                },
                isNotEmptyField,
              ]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="Webhook URL" data-cy="webhook-edit-configuration-url-input" />
            </StyledItem>
          </InputsWrapper>
        </SettingsSectionWrapper>
        <SettingsSectionWrapper>
          <p>Condition</p>
          <InputsWrapper>
            <StyledItem label="Topic" name="topicUuid" style={{ marginBottom: '24px' }}>
              <Select
                placeholder="Select Topic"
                data-cy="webhook-edit-settings-topic-id-select"
                // any here needed for ant-design
                onChange={(el: any) => {
                  setSelectedTopicId(el);
                  webhookForm.setFieldValue('topicUuid', el);
                  webhookForm.setFieldValue('optionUuid', '');
                  setSelectedOptionId('');
                }}
                $isUnselected={selectedTopicId === ''}
                options={[
                  { value: '', label: <span>Any value</span> },
                  ...widget.topics.map((topic) => ({
                    value: topic.uuid,
                    label: <span>{topic.name}</span>,
                  })),
                ]}
              />
            </StyledItem>
            <StyledItem label="Option" name="optionUuid" style={{ marginBottom: '24px' }}>
              <Select
                placeholder="Select Option"
                data-cy="webhook-edit-settings-option-id-select"
                onChange={(el: any) => {
                  webhookForm.setFieldValue('optionUuid', el);
                  setSelectedOptionId(el);
                }}
                $isUnselected={selectedOptionId === ''}
              >
                <Select.Option key="option-unselected" value="">
                  Any value
                </Select.Option>
                {widget.topics
                  .find((topic) => topic.uuid === selectedTopicId)
                  ?.options.map((option) => (
                    <Select.Option key={option.uuid} value={option.uuid}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </StyledItem>
          </InputsWrapper>
        </SettingsSectionWrapper>

        <SettingsSectionWrapper>
          <p>Payload</p>
          <InputsWrapper>
            <StyledItem
              label="Data"
              name="requestData"
              style={{ marginBottom: '24px' }}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                placeholder="Select Option"
                style={{ width: '100%' }}
                mode="tags"
                data-cy="webhook-edit-settings-payload-select"
                onChange={handleRequestDataChange}
              >
                <Select.Option key="widgetId" value="widgetId">
                  Widget UUID
                </Select.Option>
                <Select.Option key="userId" value="userId">
                  User ID
                </Select.Option>
                <Select.Option key="answers" value="answers">
                  Answers
                </Select.Option>
              </Select>
            </StyledItem>
          </InputsWrapper>
        </SettingsSectionWrapper>
        {showRadioButtons && (
          <SettingsSectionWrapper>
            <p />
            <InputsWrapper>
              <StyledItem
                label="Answers to include"
                name="answersLayout"
                style={{ marginBottom: '24px' }}
                tooltip={{
                  title:
                    'Here you can specify what data and in which format should be included as part of answers object in the webhook payload.',
                }}
                onChange={(el: any) => handleAnswersLayoutChange(el.target.value)}
              >
                <Radio.Group optionType="default">
                  <Radio.Button value={WebhookPayloadAnswersLayout.UUIDS}>Only UUIDs</Radio.Button>
                  <Radio.Button value={WebhookPayloadAnswersLayout.TITLES_UUIDS_VALUES}>
                    Titles, UUIDs, and Values
                  </Radio.Button>
                  <Radio.Button value={WebhookPayloadAnswersLayout.TITLES_VALUES}>
                    Titles and Values
                  </Radio.Button>
                </Radio.Group>
              </StyledItem>

              <StyledItem name="answersType" style={{ marginBottom: '24px' }}>
                <Radio.Group optionType="default">
                  <Radio.Button
                    value={WebhookPayloadAnswersType.CURRENT}
                    disabled={disableAnswersType}
                  >
                    All selected options
                  </Radio.Button>
                  <Radio.Button
                    value={WebhookPayloadAnswersType.DIFF}
                    disabled={disableAnswersType}
                  >
                    Only updated options
                  </Radio.Button>
                </Radio.Group>
              </StyledItem>
            </InputsWrapper>
          </SettingsSectionWrapper>
        )}

        <SettingsSectionWrapper>
          <p>Authentication</p>
          <InputsWrapper>
            <StyledItem
              label="Method"
              name={['authentication', 'type']}
              style={{ marginBottom: '24px' }}
            >
              <Select
                placeholder="Select Topic"
                data-cy="webhook-edit-authorization-method-select"
                // any here needed for ant-design
                onChange={(el: any) => {
                  setSelectedType(el);
                  webhookForm.setFieldValue('type', el);
                  if (el === 'api-key') {
                    webhookForm.setFieldValue(['authentication', 'payload', 'location'], 'header');
                  } else {
                    webhookForm.setFieldValue(['authentication', 'payload', 'location'], null);
                  }
                }}
              >
                <Select.Option
                  key="no"
                  value="no"
                  data-cy="webhook-edit-authorization-method-option-no"
                >
                  No authorization
                </Select.Option>
                <Select.Option
                  key="basic"
                  value="basic"
                  data-cy="webhook-edit-authorization-method-option-basic"
                >
                  Basic authorization with username and password
                </Select.Option>
                <Select.Option
                  key="api-key"
                  value="api-key"
                  data-cy="webhook-edit-authorization-method-option-apiKey"
                >
                  Advanced authorization with API Key
                </Select.Option>
              </Select>
            </StyledItem>
            {selectedType === 'basic' && (
              <>
                <StyledItem
                  label="Username"
                  name={['authentication', 'payload', 'username']}
                  rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
                  style={{ marginBottom: '24px' }}
                >
                  <Input
                    placeholder="Username"
                    data-cy="webhook-edit-authorization-username-input"
                  />
                </StyledItem>
                <StyledItem
                  label="Password"
                  name={['authentication', 'payload', 'password']}
                  rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
                  style={{ marginBottom: '24px' }}
                >
                  <Input
                    placeholder="Password"
                    data-cy="webhook-edit-authorization-password-input"
                    type="password"
                  />
                </StyledItem>
              </>
            )}
            {selectedType === 'api-key' && (
              <>
                <StyledItem
                  label="Key"
                  name={['authentication', 'payload', 'name']}
                  rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
                  style={{ marginBottom: '24px' }}
                >
                  <Input placeholder="X-API-Key" data-cy="webhook-edit-authorization-key-input" />
                </StyledItem>
                <StyledItem
                  label="Value"
                  name={['authentication', 'payload', 'value']}
                  rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
                  style={{ marginBottom: '24px' }}
                >
                  <Input placeholder="Value" data-cy="webhook-edit-authorization-value-input" />
                </StyledItem>
                <StyledItem
                  label="Add To"
                  name={['authentication', 'payload', 'location']}
                  style={{ marginBottom: '24px' }}
                >
                  <Select
                    placeholder="Add to"
                    data-cy="webhook-edit-authorization-authorization-location-select"
                    options={[
                      { value: 'header', label: 'Header' },
                      { value: 'body', label: 'Body' },
                    ]}
                  />
                </StyledItem>
              </>
            )}
          </InputsWrapper>
        </SettingsSectionWrapper>
      </Form>
    </Modal>
  );
};

export default EditWebhookModal;
