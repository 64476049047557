import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9963 16.3594C11.8863 16.3594 11.7844 16.3406 11.6906 16.3031C11.5969 16.2656 11.5031 16.2 11.4094 16.1063L7.1625 11.8594C6.99375 11.6855 6.91406 11.4827 6.92344 11.2509C6.93281 11.019 7.01821 10.8188 7.17963 10.65C7.35571 10.4813 7.56094 10.3969 7.79531 10.3969C8.02969 10.3969 8.23125 10.4813 8.4 10.65L11.1562 13.4344V3.84375C11.1562 3.60469 11.2376 3.4043 11.4002 3.24258C11.5629 3.08086 11.7645 3 12.0049 3C12.2454 3 12.4453 3.08086 12.6047 3.24258C12.7641 3.4043 12.8437 3.60469 12.8437 3.84375V13.4344L15.6281 10.65C15.7931 10.4813 15.9891 10.4016 16.2159 10.4109C16.4428 10.4203 16.6443 10.5094 16.8204 10.6781C16.9818 10.8469 17.0625 11.0484 17.0625 11.2828C17.0625 11.5172 16.9781 11.7188 16.8094 11.8875L12.5906 16.1063C12.4969 16.2 12.4019 16.2656 12.3056 16.3031C12.2094 16.3406 12.1062 16.3594 11.9963 16.3594ZM4.6875 21C4.2375 21 3.84375 20.8313 3.50625 20.4938C3.16875 20.1563 3 19.7625 3 19.3125V16.1344C3 15.8953 3.08133 15.6949 3.24398 15.5332C3.40666 15.3715 3.60822 15.2906 3.84867 15.2906C4.08914 15.2906 4.28906 15.3715 4.44844 15.5332C4.60781 15.6949 4.6875 15.8953 4.6875 16.1344V19.3125H19.3125V16.1344C19.3125 15.8953 19.3938 15.6949 19.5565 15.5332C19.7192 15.3715 19.9207 15.2906 20.1612 15.2906C20.4016 15.2906 20.6016 15.3715 20.7609 15.5332C20.9203 15.6949 21 15.8953 21 16.1344V19.3125C21 19.7625 20.8312 20.1563 20.4937 20.4938C20.1562 20.8313 19.7625 21 19.3125 21H4.6875Z"
      fill="#303030"
    />
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
