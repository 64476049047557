import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = ({
  height = '16',
  width = '16',
  fill = '#303030',
}: {
  height: string;
  width: string;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 96 960 960"
    width={width}
    fill={fill}
  >
    <path d="M480 467q-5 0-10.5-2t-10.5-7L322 321q-9-9-9-22t9-22q9-9 21-9t21 9l116 116 116-116q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L501 458q-5 5-10 7t-11 2ZM322 874q-9-9-9-21.5t9-21.5l137-137q5-5 10.5-7t10.5-2q6 0 11 2t10 7l138 138q9 9 9 21t-9 21q-9 9-22 9t-22-9L480 759 365 874q-9 9-21.5 9t-21.5-9Z" />
  </svg>
);

export default (props: any) => <Icon component={() => CustomIcon(props)} {...props} />;
