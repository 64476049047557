import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = ({
  height = '20px',
  width = '20px',
  fill = '#303030',
}: {
  height: string;
  width: string;
  fill: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/google/navigation/collapse</title>
    <defs>
      <path
        d="M15.875,8.9999928 L11.995,12.8799928 L8.115,8.9999928 C7.92816847,8.81274013 7.67451764,8.7075072 7.41,8.7075072 C7.14548236,8.7075072 6.89183153,8.81274013 6.705,8.9999928 C6.315,9.3899928 6.315,10.0199928 6.705,10.4099928 L11.295,14.9999928 C11.685,15.3899928 12.315,15.3899928 12.705,14.9999928 L17.295,10.4099928 C17.685,10.0199928 17.685,9.3899928 17.295,8.9999928 C16.905,8.6199928 16.265,8.6099928 15.875,8.9999928 L15.875,8.9999928 Z"
        id="path-117"
      />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Icon/google/navigation/collapse">
        <mask id="mask-22" fill="white">
          <use
            xlinkHref="#path-117"
            transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
          />
        </mask>
        <g
          id="icon/navigation/expand_more_20px"
          fillRule="nonzero"
          transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
        />
        <g id="\u21B3-Color" mask="url(#mask-22)" fill={fill}>
          <rect id="Rectangle" x={0} y={0} width={20} height={20} />
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
