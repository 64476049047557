import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

export const DeletionBox = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 12px 16px;
  width: 100%;
  margin-top: 20px;
`;

export const Description = styled.p`
  opacity: 0.8;
  color: rgba(89, 89, 89, 1);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

export const Dot = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(206, 206, 206, 1);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 8px;
`;

export const ChangelogWrapper = styled.div`
  display: flex;

  & p {
    margin-left: 5px;
    margin-bottom: 0;
  }

  & img {
    margin-top: 3px;
    width: 14px;
    height: 14px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OptionName = styled.div`
  border-radius: 6px;
  background: ${(props) => props.theme.colors.lightBorder};
  padding: 5px;
  margin-right: 3px;
  margin-bottom: 2px;
  border: 1px solid ${(props) => props.theme.colors.gray};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  & button:first-child {
    margin-right: 10px;
  }
`;

export const ExpiredLabel = styled.span`
  margin-left: 4px;
  padding: 6px 8px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  background-color: #fdeff0;
`;
