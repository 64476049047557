import React from 'react';
import Menu from 'components/Menu/Menu';
import useUserData from '../../lib/hooks/useUserData';

interface IWidgetMenuProps {
  pac: string;
  makeOwner: () => void;
  onDelete: () => void;
  dataCy: string;
}

const PacMenu = ({ onDelete, pac, makeOwner, dataCy }: IWidgetMenuProps) => {
  const { isSuperAdmin } = useUserData();
  return (
    <Menu defaultIcon dataCy={dataCy}>
      {isSuperAdmin && (
        <>
          <Menu.Item data-cy={`pac-${pac}-action-make-owner`} key="editDetails" onClick={makeOwner}>
            Make Owner
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item
        key="delete"
        onClick={() => onDelete()}
        data-cy={`pac-${pac}-action-delete`}
        delete
      >
        Remove Access
      </Menu.Item>
    </Menu>
  );
};

export default PacMenu;
