import React, { useEffect, useState } from 'react';
import { Tooltip } from 'components/Form';
import { StyledSelect } from 'components/Select/Select';
import {
  arrayMove,
  SortableMultiValue,
  SortableMultiValueLabel,
  SortableSelect,
} from 'components/Select/SelectWithReorder';
import { Form } from 'antd';
import PacService, { Pac } from 'lib/api/pacApi';
import { CustomTagWithoutDeselect } from 'components/Select/Tag';
import { showErrorResponseNotification, findKeyByValue } from 'lib/helpers';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useMessage } from 'lib/hooks';
import Switch from 'components/Switch/Switch';
import SidebarButtons from '../SidebarButtons';
import {
  BodyWrapper,
  CategorySubtitle,
  Container,
  LanguageSelectWrapper,
  Title,
  TitleWrapper,
} from './styled';
import PacInformationForm from '../PacInformationForm';

interface IGeneralProp {
  editableLanguages: Array<string>;
  setEditableLanguage: Function;
  title: string;
  description: string;
  emailAccessReq: boolean;
  isSaveDisabled: boolean;
  disableSave: Function;
  visibleLanguagesReq: Array<string>;
  languageList: { [key: string]: string };
  handleTranslate: (fieldName: string, useHtmlEditor: boolean | undefined) => void;
  setLoading: (status: boolean) => void;
  updateStatesFromResponse: Function;
  setWidgetDetails: Function;
  pacDetails?: Pac;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const GeneralTab = ({
  editableLanguages,
  setEditableLanguage,
  title,
  description,
  emailAccessReq,
  isSaveDisabled,
  disableSave,
  visibleLanguagesReq,
  languageList,
  handleTranslate,
  setLoading,
  updateStatesFromResponse,
  setWidgetDetails,
  pacDetails,
  setHasUnsavedChanges,
}: IGeneralProp) => {
  const [detailsForm] = Form.useForm();
  const [emailAccess, setEmailAccess] = useState<boolean>(false);
  const [visibleLanguages, setVisibleLanguage] = useState<Array<{ label: string; value: string }>>(
    editableLanguages.map((val: string) => ({ label: val, value: val })),
  );
  const params: { pacId?: string } = useParams();
  const message = useMessage();

  useEffect(() => {
    detailsForm.setFieldsValue({
      title,
      description,
    });
  }, [title, description]);

  useEffect(() => {
    setEmailAccess(emailAccessReq);
  }, [emailAccessReq]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newValue = arrayMove(visibleLanguages, oldIndex, newIndex);
    setVisibleLanguage(newValue);

    if (isSaveDisabled === true) {
      disableSave(false);
    }
  };

  useEffect(() => {
    setVisibleLanguage(visibleLanguagesReq.map((val: string) => ({ label: val, value: val })));
  }, [visibleLanguagesReq]);

  const updatePacDetails = async () => {
    if (params.pacId) {
      try {
        const form = await detailsForm.validateFields();
        setLoading(true);
        const result: AxiosResponse<Pac> = await PacService.update(params.pacId, {
          title: form.title,
          description: form.description,
          isEmailLoginAvailable: emailAccess,
          editableLanguages: editableLanguages.map((el: string) =>
            findKeyByValue(languageList, el),
          ),
          visibleLanguages: visibleLanguages.map((el: { value: string }) =>
            findKeyByValue(languageList, el.value),
          ),
        });
        updateStatesFromResponse(result);
        setLoading(false);
        disableSave(true);

        message.success(
          <div>
            <span>Configuration settings were successfully updated.</span>
            <br />
            <span>
              Please mind, due to cache settings, the changes will take effect in 10-15 minutes.
            </span>
          </div>,
        );
      } catch (error) {
        showErrorResponseNotification(error);
      }
    }
  };

  const onFieldsChange = () => {
    const values = detailsForm.getFieldsValue();
    if (isSaveDisabled === true) {
      disableSave(false);
    }

    setWidgetDetails({
      title: values.title,
      description: values.description,
    });
  };

  const handleSelectLanguage = (value: string) => {
    if (!Object.values(languageList).includes(value)) {
      return;
    }

    setEditableLanguage([...editableLanguages, value]);
    disableSave(false);
  };

  const handleChangeVisibleLanguage = (langs: { label: string; value: string }[]) => {
    if (langs.length > 0) {
      setVisibleLanguage(langs);
    }
    disableSave(false);
  };

  return (
    <>
      <Container>
        <TitleWrapper>
          <Title>Content</Title>
          <span>
            Below you can enter the relevant content for your Preference Management Center
          </span>
        </TitleWrapper>
        <PacInformationForm
          showTranslationButton={editableLanguages.length >= 2}
          detailsForm={detailsForm}
          onFieldsChange={onFieldsChange}
          onTranslate={handleTranslate}
        />
      </Container>
      <Container>
        <TitleWrapper>
          <Title>Language Settings</Title>
          <span>
            Please specify which languages you want to enable for your Preference Management Center.
            Users will be able to switch between them.
          </span>
        </TitleWrapper>
        <BodyWrapper>
          <div>
            <CategorySubtitle>Editable Languages</CategorySubtitle>
            <Tooltip
              title='Choose the languages you want to enable for your settings. After adding a language here, it cannot be deleted. Adding a language here does not make it available to your users. Please add the respective language after finishing the translation under "Visible Languages" in order for it to be displayed for your users.'
              placement="right"
            />
          </div>
          <StyledSelect
            data-cy="configuration-pac-editable-languages"
            mode="tags"
            style={{ width: '100%' }}
            onSelect={(value: any) => handleSelectLanguage(value)}
            value={editableLanguages}
            tagRender={CustomTagWithoutDeselect}
            defaultValue={['English']}
            options={Object.values(languageList)
              .sort()
              .map((el: string) => ({
                label: el,
                value: el,
              }))}
          />
          <LanguageSelectWrapper>
            <div>
              <CategorySubtitle>Visible Languages</CategorySubtitle>
              <Tooltip
                title="Choose the languages you want to enable for your users."
                placement="right"
              />
            </div>
            <div data-cy="configuration-pac-visible-languages">
              <SortableSelect
                useDragHandle
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                isMulti
                options={editableLanguages.map((el: string) => ({ label: el, value: el }))}
                value={visibleLanguages}
                onChange={(value: any) => {
                  handleChangeVisibleLanguage(value);
                }}
                components={{
                  MultiValue: SortableMultiValue,
                  MultiValueLabel: SortableMultiValueLabel,
                }}
                closeMenuOnSelect={false}
              />
            </div>
            <p>
              Drag and drop language to the first position, to make it &quot;default&quot; on the
              Preference Center page.
            </p>
          </LanguageSelectWrapper>
        </BodyWrapper>
      </Container>
      <Container>
        <TitleWrapper style={{ marginBottom: 0, borderBottom: 'none' }}>
          <Title>
            <span>Authentication via Email</span>
            <Switch
              checked={emailAccess}
              data-cy="configuration-pac-email-access-select"
              onChange={(value: boolean) => {
                disableSave(false);
                setEmailAccess(value);
              }}
            />
          </Title>
          <span>
            Allow users to request access link to the Preference Center direct on their email
            address, without authentication on your website, i.e. from Newsletter. For more details,
            please refer to our{' '}
            <a href="https://docs.usercentrics.com/#/preference-manager-auth-mail">documentation</a>
            .
          </span>
        </TitleWrapper>
      </Container>
      <SidebarButtons
        setHasUnsavedChanges={setHasUnsavedChanges}
        onSave={updatePacDetails}
        isSaveDisabled={isSaveDisabled}
        isPacDeactivated={!!pacDetails?.deactivated}
      />
    </>
  );
};

export default GeneralTab;
