import React, { useState } from 'react';
import { Form } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { ITopic } from 'lib/interfaces/widget';
import { Steps } from 'lib/enums/steps';
import useMessage from 'lib/hooks/useMessage';
import { showErrorResponseNotification } from 'lib/helpers';

import Modal from 'components/Modal/Modal';
import { AbstractApiService } from 'lib/api/abstractApiService';
import Details from './Content/Details';
import Topics from './Content/Topics';

interface IProps {
  onClose: () => Promise<void> | void;
  editableLanguages: string[];
  languageList: { [key: string]: string };
  isPacDeactivated: boolean;
  isPacLite: boolean;
}

const CreateWidgetModal = ({
  onClose,
  editableLanguages,
  languageList,
  isPacDeactivated,
  isPacLite
}: IProps) => {
  const message = useMessage();
  const [detailsForm] = Form.useForm();
  const [details, setDetails] = useState({
    title: { value: '', key: '' },
    description: { value: '', key: '' },
    additionalNote: { value: '', key: '' },
    doubleOptIn: false,
    retentionPeriod: 0,
    retentionBehavior: 'keep',
  });
  const [topicsForm] = Form.useForm();
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [step, setStep] = useState<Steps>(Steps.DETAILS);
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const params: { pacId?: string } = useParams();
  const abstractApiService = new AbstractApiService();

  const onFieldsChange = () => {
    const hasErrors = detailsForm.getFieldsError().some(({ errors }) => errors.length);
    setDisableNext(hasErrors);
  };

  const onTopicsChange = () => {
    const hasErrors = topicsForm.getFieldsError().some(({ errors }) => errors.length);
    setDisableNext(hasErrors);
  };

  const onPrimaryClick = async () => {
    if (step === Steps.DETAILS) {
      try {
        const d = await detailsForm.validateFields();
        setDetails(d);
        setStep(Steps.TOPICS);
        setDisableNext(true);
      } catch (e) {
        return;
      }
    }
    if (step === Steps.TOPICS) {
      try {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_CONFIG_API_URL}/widgets/default?pacUuid=${params.pacId}`,
            {
              title: details.title.value,
              description: details.description.value,
              additionalNote: details.additionalNote.value,
              doubleOptIn: details.doubleOptIn,
              retentionPeriod: details.retentionPeriod,
              topics: topics.map((topic, index) => ({
                name: topic.name,
                order: index,
                description: topic.description,
                selectType: topic.selectType,
                required: topic.required,
                options: topic.options
                  .filter((option) => option.name !== undefined)
                  .map((option, optionIndex) => ({
                    name: option.name,
                    order: optionIndex,
                    default: option.default || false,
                  })),
              })),
            },
            abstractApiService.getApiHeaders(),
          )
          .then((response) => {
            message.success(`New widget created: ${response.data ? response.data.uuid : ''}`);
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            if (error.message.status === 422) {
              message.error('Some mandatory data was not sent');
            } else {
              showErrorResponseNotification(error);
            }
          });
      } catch (error) {
        showErrorResponseNotification(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSecondaryClick = () => {
    if (step === Steps.TOPICS) {
      setDisableNext(false);

      return setStep(Steps.DETAILS);
    }
    return onClose();
  };

  return (
    <Modal
      visible
      title="Create Widget"
      secondaryButton={{
        label: step === Steps.TOPICS ? 'Back' : 'Cancel',
        onClick: onSecondaryClick,
      }}
      primaryButton={{
        label: step === Steps.TOPICS ? 'Create Widget' : 'Next',
        onClick: onPrimaryClick,
        disabled: disableNext,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      {step === Steps.DETAILS && (
        <Details
          isPacDeactivated={isPacDeactivated}
          initialValues={details}
          form={detailsForm}
          onFieldsChange={onFieldsChange}
          widgetId=""
        />
      )}
      {step === Steps.TOPICS && (
        <Topics
          languageList={languageList}
          editableLanguages={editableLanguages}
          isPacDeactivated={isPacDeactivated}
          form={topicsForm}
          topics={topics}
          updateTopics={(value: Array<ITopic>) => {
            setTopics(value);
          }}
          onFieldsChange={onTopicsChange}
          isPacLite={isPacLite}
        />
      )}
    </Modal>
  );
};

export default CreateWidgetModal;
