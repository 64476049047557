import styled from 'styled-components';

export const WidgetDetailsContainer = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
`;

export const WidgetInfoContainer = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryIcons};
`;
export const WidgetButtonContainer = styled.div`
  padding-left: 40px;
`;

export const WidgetInfo = styled.div`
  display: flex;

  & .title {
    flex: 1;
  }
`;

export const WidgetTopicContainer = styled.div`
  background-color: ${(props) => props.theme.colors.darkGray};
  padding: 20px;
  margin-bottom: 8px;
`;

export const WidgetTopicsContainer = styled.div`
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const WidgetTopicTitleContainer = styled.div`
  display: flex;
`;

export const WidgetTitle = styled.span`
  font-weight: 500;
  width: 100px;
`;

export const WidgetName = styled.span`
  width: 260px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const WidgetUuid = styled.span`
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CopyContainer = styled.span`
  width: 20px;
  margin-left: 16px;
`;

export const WidgetDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.gray};
  margin-top: 12px;
  margin-bottom: 12px;
  content: ' ';
`;
