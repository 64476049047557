import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import Modal from 'components/Modal/Modal';
import { Item, Input } from 'components/Form';

import { Form } from 'antd';
import { showErrorResponseNotification, findKeyByValue } from 'lib/helpers';
import { Pac } from 'lib/api/pacApi';
import { WidgetTranslation } from 'lib/api/widgetTranslationApi';
import { useApolloClient } from '@apollo/client';
import getTranslationQuery from 'lib/gql/queries/getTranslations';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TranslationButton from 'components/Button/TranslationButton';

interface IWidgetMenuProps {
  htmlEditor?: boolean;
  editableLanguagesList: Array<string>;
  languagesList: { [key: string]: string };
  fieldName: string;
  translationsValues: Array<AxiosResponse<Pac> | AxiosResponse<WidgetTranslation> | null>;
  onClose: () => void;
  title?: string;
  width?: number;
  primaryButtonLabel?: string;
  primaryButtonDanger?: boolean;
  submitFunction: (formValues: { [key: string]: Pac }) => void;
  isSaveDisabled?: boolean;
}

const TranslationModal = ({
  htmlEditor = false,
  fieldName,
  onClose,
  editableLanguagesList,
  languagesList,
  translationsValues,
  title = 'Translation',
  width = 770,
  primaryButtonLabel = 'Save Changes',
  primaryButtonDanger = false,
  submitFunction,
  isSaveDisabled = false,
}: IWidgetMenuProps) => {
  const client = useApolloClient();
  const [translationsForm] = Form.useForm();
  const englishVersion = translationsValues.find((el) => el?.data.language === 'en')?.data;
  const [areTranslationsLoading, toggleTranslationsLoading] = useState<boolean | string>(false);
  const parsedTranslationsValues = translationsValues
    .filter((el) => el !== null)
    .map((translationResponse) => {
      const responseData = translationResponse?.data;

      return {
        ...responseData,
        language: responseData?.language || 'en',
        // TODO: fix interfaces to avoid this check
        description:
          typeof responseData?.description === 'object' ? responseData.description.value : '',
        title: typeof responseData?.title === 'object' ? responseData?.title.value : '',
        additionalNote:
          typeof responseData?.additionalNote === 'object' ? responseData.additionalNote.value : '',
      };
    });

  const initialValues = editableLanguagesList.reduce(
    (prev, curr) => ({
      ...prev,
      [findKeyByValue(languagesList, curr)]: parsedTranslationsValues.find(
        (el) => el?.language === findKeyByValue(languagesList, curr),
      ) || { language: curr, [fieldName]: Object(englishVersion)[fieldName]?.value, uuid: null },
    }),
    {},
  );

  const onSubmit = () => {
    const formValues = translationsForm.getFieldsValue();
    submitFunction(formValues);
  };

  const onAutoTranslate = async (lang: string) => {
    toggleTranslationsLoading(lang);
    const formValues = await translationsForm.validateFields();
    const languageCode = findKeyByValue(languagesList, lang);

    const query = getTranslationQuery([languageCode]);

    client
      .query({
        query,
        variables: {
          translationFor: formValues.en[fieldName],
          sourceLanguage: 'en',
          targetLanguages: [languageCode],
        },
      })
      .then((response) => {
        translationsForm.setFieldsValue({
          ...formValues,
          [languageCode]: { [fieldName]: response.data.getTranslation[languageCode] },
        });
        toggleTranslationsLoading(false);
      })
      .catch((error) => {
        toggleTranslationsLoading(false);
        showErrorResponseNotification(error);
      });
  };

  return (
    <Modal
      visible
      title={title}
      secondaryButton={{ label: 'Cancel', onClick: onClose }}
      width={width}
      bodyHeight={500}
      isConfirmation
      primaryButton={{
        disabled: isSaveDisabled,
        label: primaryButtonLabel,
        onClick: () => onSubmit(),
        danger: primaryButtonDanger,
      }}
      onClose={onClose}
    >
      <Form
        name="pac-translations"
        form={translationsForm}
        initialValues={initialValues}
        style={{ padding: '16px', position: 'relative' }}
        colon={false}
      >
        {editableLanguagesList.map((lang) => (
          <div key={`here-${findKeyByValue(languagesList, lang)}`}>
            <Item
              label={lang}
              name={[findKeyByValue(languagesList, lang), 'uuid']}
              style={{ display: 'none' }}
            >
              <Input />
            </Item>
            <div style={{ position: 'relative' }}>
              {lang !== 'English' && (
                <TranslationButton
                  onClick={() => onAutoTranslate(lang)}
                  isLoading={areTranslationsLoading === lang}
                  disabled={!!areTranslationsLoading}
                  label="Auto translate from default language"
                />
              )}
              <Item
                label={lang}
                name={[findKeyByValue(languagesList, lang), fieldName]}
                style={{ marginBottom: '24px' }}
              >
                {!htmlEditor ? (
                  <Input placeholder="" data-cy="configuration-pac-name-input" />
                ) : (
                  <ReactQuill
                    modules={{
                      toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
                    }}
                    formats={['bold', 'italic', 'underline', 'strike', 'link']}
                    placeholder="Description (optional)"
                    data-cy="configuration-pac-name-input"
                    theme="snow"
                  />
                )}
              </Item>
            </div>
          </div>
        ))}
      </Form>
    </Modal>
  );
};

export default TranslationModal;
