const TOKEN_API = 'tokenApi';

export function getApiToken() {
  return localStorage.getItem(TOKEN_API);
}

export function setApiToken(token: string) {
  return localStorage.setItem(TOKEN_API, token);
}

export function removeApiToken() {
  return localStorage.removeItem(TOKEN_API);
}
