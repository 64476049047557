import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const StyledSpin = styled(Spin)`
  // min-width: 100% - sidebar size - paddings
  // min-height: 100% - header size - paddings
  min-width: calc(100vw - 232px - 80px);
  min-height: calc(100vh - 64px - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export default () => <StyledSpin />;
