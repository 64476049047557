import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    version="1.1"
    width="24px"
    height="24px"
    viewBox="0 0 24.0 24.0"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="i0">
        <path d="M16,0 C17.1,0 18,0.9 18,2 L18,16 C18,17.1 17.1,18 16,18 L2,18 C0.9,18 0,17.1 0,16 L0,2 C0,0.9 0.9,0 2,0 L16,0 Z M16,2 L2,2 L2,16 L16,16 L16,2 Z M9,12.49 C9.71,12.49 10.25,13.04 10.25,13.74 C10.25,14.43 9.72,15 9,15 C8.3,15 7.74,14.44 7.74,13.74 C7.74,13.03 8.3,12.49 9,12.49 Z M8.99,3 C10.47,3 11.48,3.67 11.99,4.51 C12.43,5.23 12.69,6.59 12.01,7.59 C11.25,8.7 10.53,9.04 10.14,9.76 C9.99,10.05 9.92,10.24 9.92,11.17 L8.1,11.17 C8.1,10.68 8.02,9.88 8.41,9.19 C8.9,8.32 9.83,7.8 10.37,7.03 C10.94,6.22 10.62,4.7 9,4.7 C7.94,4.7 7.42,5.51 7.2,6.18 L5.56,5.49 C6.01,4.15 7.22,3 8.99,3 Z" />
      </clipPath>
      <clipPath id="i1">
        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(3.0 3.0)">
      <g clipPath="url(#i0)">
        <polygon points="0,0 18,0 18,18 0,18 0,0" stroke="none" fill="#979797" />
        <g transform="translate(-3.0 -3.0)">
          <g clipPath="url(#i1)">
            <polygon points="3,3 21,3 21,21 3,21 3,3" stroke="none" fill="#979797" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
