import { Theme } from 'lib/theme';
import styled, { css, keyframes } from 'styled-components';

interface ButtonProps {
  size?: 'large' | 'default';
  styleType?: 'primary' | 'default' | 'link' | 'border' | 'white';
  disabled?: boolean;
  danger?: boolean;
  loading: '1' | '0';
  type?: string;
}

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

interface IProps {
  removeMargin?: boolean;
}

export const Loading = styled.div<IProps>`
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: white;
  border-radius: 50%;
  margin-right: ${(props) => (props.removeMargin ? 0 : '8px')};
  animation-name: ${spinning};
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const whiteStyle = css<ButtonProps>`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primaryText};
  border: 1px solid ${(props) => props.theme.colors.black(0.15)};
  &:hover,
  &:active {
    opacity: 1;
    border-color: ${(props) =>
      props.disabled ? props.theme.colors.disabledButton : props.theme.colors.infoBlue()};
    & svg path,
    g {
      ${(props) => props.disabled && `fill: ${props.theme.colors.greyIcons};`}
    }
  }
  & ${Loading} {
    border-top-color: ${(props) => props.theme.colors.infoBlue()};
  }
`;

const primaryStyle = css<ButtonProps>`
  background: ${(props) => {
    if (props.danger) return props.theme.colors.red;
    return props.theme.colors.primary;
  }};
  color: white;

  & svg path {
    fill: white;
  }
`;

const getDefaultButtonColor = (props: ButtonProps & { theme: Theme }) => {
  if (props.disabled) return props.theme.colors.greyIcons;
  return props.theme.colors.primaryText;
};

const defaultStyle = css<ButtonProps>`
  background: ${(props) => {
    if (props.disabled) return props.theme.colors.disabledButton;
    return props.theme.colors.gray;
  }};
  color: ${getDefaultButtonColor};

  & svg path {
    fill: ${getDefaultButtonColor};
  }

  & ${Loading} {
    border-top-color: ${getDefaultButtonColor};
  }
`;

const getLinkButtonColor = (props: ButtonProps & { theme: Theme }) => {
  if (props.disabled) return props.theme.colors.greyIcons;
  if (props.danger) return props.theme.colors.red;
  return props.theme.colors.primary;
};

const linkStyle = css<ButtonProps>`
  background: transparent;
  color: ${getLinkButtonColor};

  & svg path {
    fill: ${getLinkButtonColor};
  }
`;

const getBorderButtonColor = (props: ButtonProps & { theme: Theme }) => {
  if (props.disabled) return props.theme.colors.greyIcons;
  if (props.danger) return props.theme.colors.red;
  return props.theme.colors.primaryText;
};

const borderStyle = css<ButtonProps>`
  background: transparent;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  color: ${getBorderButtonColor};

  & svg path {
    fill: ${getBorderButtonColor};
  }

  & ${Loading} {
    border-top-color: ${getBorderButtonColor};
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 ${(props) => (props.size === 'large' ? 24 : 16)}px;
  height: ${(props) => props.size === 'large' && '40px'};
  min-height: 32px;
  outline: none;
  cursor: ${(props) => (props.disabled || props.loading === '1' ? 'not-allowed' : 'pointer')};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.loading === '1' || props.disabled ? 0.6 : 1)};

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &:focus,
  &:hover {
    opacity: ${(props) => {
      if (props.loading === '1' || props.disabled) return 0.6;
      return 0.8;
    }};
  }
  &:active {
    opacity: ${(props) => {
      if (props.loading === '1' || props.disabled) return 0.6;
      return 0.7;
    }};
  }

  ${(props) => {
    switch (props.styleType) {
      case 'primary':
        return primaryStyle;
      case 'link':
        return linkStyle;
      case 'border':
        return borderStyle;
      case 'white':
        return whiteStyle;
      case 'default':
      default:
        return defaultStyle;
    }
  }}
`;
