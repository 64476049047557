import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderItem = styled(NavLink)`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryText};

  &.active {
    color: ${(props) => props.theme.colors.primary};
  }

  &.no-active {
    color: ${(props) => props.theme.colors.primaryText};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HeaderItemNoLink = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryText};

  &.active {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const HeaderItemSpace = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryText};
`;

export const HeaderTitleText = styled.span``;
