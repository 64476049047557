import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
    <path
      d="M10.5 15.6667H2.16667V4.83342C2.16667 4.37508 1.79167 4.00008 1.33333 4.00008C0.875 4.00008 0.5 4.37508 0.5 4.83342V15.6667C0.5 16.5834 1.25 17.3334 2.16667 17.3334H10.5C10.9583 17.3334 11.3333 16.9584 11.3333 16.5001C11.3333 16.0417 10.9583 15.6667 10.5 15.6667ZM14.6667 12.3334V2.33341C14.6667 1.41675 13.9167 0.666748 13 0.666748H5.5C4.58333 0.666748 3.83333 1.41675 3.83333 2.33341V12.3334C3.83333 13.2501 4.58333 14.0001 5.5 14.0001H13C13.9167 14.0001 14.6667 13.2501 14.6667 12.3334ZM13 12.3334H5.5V2.33341H13V12.3334Z"
      fill="#303030"
    />
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
