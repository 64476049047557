import React from 'react';

import { Form, FormInstance } from 'antd';
import { Item, Input } from 'components/Form';
import { Description, Title } from 'shared-components/ModalsContent';

import { StyledSelect as Select } from 'components/Select/Select';

import { isNotEmptyField } from 'lib/regex/validations';
import { IWidget } from 'lib/interfaces/widget';

interface IConfiguration {
  name: string;
  widgetUuid: string;
  webhookUrl: string;
}

interface IProps {
  form: FormInstance<IConfiguration>;
  initialValues?: IConfiguration;
  onFieldsChange: () => void;
  widgetsList: Array<IWidget>;
}

const ConfigurationStep = ({ initialValues, form, onFieldsChange, widgetsList }: IProps) => {
  return (
    <>
      <Title>Webhook Configuration</Title>
      <Description>
        To setup webhook, please add a name, select the widget you want this webhook to be linked to
        and define the target URL this webhook should use.
      </Description>
      <Form
        name="webhook-configuration"
        form={form}
        initialValues={initialValues}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <Item
          label="Webhook Name"
          name="name"
          rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
          style={{ marginBottom: '24px' }}
        >
          <Input placeholder="Webhook Name" data-cy="webhook-new-configuration-name-input" />
        </Item>
        <Item
          label="Widget"
          name="widgetUuid"
          rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
          style={{ marginBottom: '24px' }}
        >
          <Select placeholder="Select Type" data-cy="webhook-new-configuration-widget-id-select">
            {widgetsList.map((widget) => (
              <Select.Option
                key={widget.uuid}
                value={widget.uuid}
                data-cy={`webhook-new-configuration-widget-id-option-${widget.title}`}
              >
                {widget.title}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item
          label="Webhook URL"
          name="webhookUrl"
          rules={[
            { required: true, message: 'This field is required' },
            {
              type: 'url',
              message: 'This field must be a valid url.',
            },
            isNotEmptyField,
          ]}
          style={{ marginBottom: '24px' }}
        >
          <Input placeholder="Webhook URL" data-cy="webhook-new-configuration-url-input" />
        </Item>
      </Form>
    </>
  );
};

export default ConfigurationStep;
