import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const PageTitle = styled.h1`
  font-size: 24px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
`;

export const PageWrapper = styled.div`
  padding: 40px;
  padding-top: 0;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 32px 40px;
`;

export const TableConfigName = styled(NavLink)`
  color: ${(props) => props.theme.colors.primaryText};
`;

export const Counter = styled.span`
  padding: 2px 10px;
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.primaryText};
  border-radius: 10px;
  font-size: 12px;
`;

export const Menu = styled.div`
  display: flex;
  width: 100px;

  .drop-down {
    cursor: pointer;
  }
`;

export const ConfigTableTitle = styled.div`
  & svg {
    height: 18px;
    cursor: pointer;
  }
`;
