import React, { useState } from 'react';
import { Checkbox, Form } from 'antd';

import useMessage from 'lib/hooks/useMessage';

import Modal from 'components/Modal/Modal';
import { Item, Input } from 'components/Form';

import { Description, Title } from 'shared-components/ModalsContent';
import { AxiosResponse } from 'axios';
import PacService, { Pac } from 'lib/api/pacApi';
import { useAuth0 } from '@auth0/auth0-react';
import { StyledQuill } from 'pages/PAC/styled';
import { isNotEmptyField } from 'lib/regex/validations';
import { showErrorResponseNotification } from 'lib/helpers';

interface IProps {
  onClose: () => Promise<void> | void;
  addNewPac: (newPAC: Pac) => void;
}

const CreateConfigurationModal = ({ onClose, addNewPac }: IProps) => {
  const { user } = useAuth0();
  const message = useMessage();
  const [detailsForm] = Form.useForm();
  const [disableSave, setDisableSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const onValuesChange = async () => {
    try {
      const formValues = await detailsForm.validateFields();
      return detailsForm.setFieldsValue(formValues);
    } catch {
      return null;
    }
  };

  const onFieldsChange = () => {
    const hasErrors = detailsForm.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const onPrimaryClick = async () => {
    try {
      const formData = await detailsForm.validateFields();
      if (formData.name.trim() === '') {
        setDisableSave(true);
        return detailsForm.setFieldsValue({ name: null, description: formData.description });
      }
      setLoading(true);

      const response: AxiosResponse<Pac> = await PacService.create({
        title: formData.name,
        description: formData?.description !== null ? formData.description : '<p></p>',
        customerId: formData.customerId,
        sendNotification: formData.sendNotification,
      });

      message.success(
        `New configuration created: ${
          response.data ? `${response.data.title} [${response.data.uuid}]` : ''
        }`,
      );

      addNewPac(response.data);
      return onClose();
    } catch (error) {
      return showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible
      title="Create Configuration"
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      primaryButton={{
        label: 'Create Configuration',
        onClick: onPrimaryClick,
        disabled: disableSave,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      <>
        <Title>Configuration Details</Title>
        <Description>Enter your configuration details below.</Description>
        <Form
          name="configuration-details"
          form={detailsForm}
          initialValues={{
            name: null,
            description: null,
            customerId: user?.email,
            sendNotification: true,
          }}
          style={{ padding: '16px' }}
          onFieldsChange={onFieldsChange}
          onValuesChange={() => onValuesChange()}
        >
          <Item
            label="Title"
            name="name"
            rules={[{ required: true, message: 'This field is required' }, isNotEmptyField]}
            style={{ marginBottom: '24px' }}
          >
            <Input placeholder="Product Preferences" />
          </Item>
          <Item colon={false} label="Description" name="description">
            <StyledQuill
              modules={{
                toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
              }}
              formats={['bold', 'italic', 'underline', 'strike', 'link']}
              placeholder="Specify description text for your Preference Management Center"
              theme="snow"
              style={{ fontSize: '14px' }}
            />
          </Item>
          <Item
            label="Configuration Owner"
            name="customerId"
            tooltip={{
              title:
                'Here you can specify the Owner of the Configuration, who would have full control over the Configuration and the data.',
            }}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
            style={{ marginBottom: '8px' }}
          >
            <Input
              placeholder="Email address"
              onChange={(event) => {
                detailsForm.setFieldValue('customerId', event.target.value.toLowerCase());
              }}
            />
          </Item>
          <Item
            required
            name="sendNotification"
            validateTrigger="onBlur"
            rules={[{ required: false }]}
            style={{ marginBottom: '24px' }}
            valuePropName="checked"
          >
            <Checkbox
              style={{ fontWeight: 400 }}
              data-cy="modal-topic-required-input"
              defaultChecked
            >
              Notify user via email
            </Checkbox>
          </Item>
        </Form>
      </>
    </Modal>
  );
};

export default CreateConfigurationModal;
