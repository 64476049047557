import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    width="96px"
    height="96px"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    id="user-placeholder"
  >
    <rect fill="#4074BC" x="0" y="0" width="96" height="96" rx="96" />
    <path
      fill="#FFFFFF"
      d="M48,48 C54.63,48 60,42.63 60,36 C60,29.37 54.63,24 48,24 C41.37,24 36,29.37 36,36 C36,42.63 41.37,48 48,48 Z M48,54 C39.99,54 24,58.02 24,66 L24,72 L72,72 L72,66 C72,58.02 56.01,54 48,54 Z"
    />
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
