import { AxiosResponse } from 'axios';
import { AbstractApiService } from './abstractApiService';
import { PreferenceManagerApiAxios } from './axiosInstance';

class PreferenceManagerApi extends AbstractApiService {
  verifyUser(email: string): Promise<AxiosResponse> {
    return PreferenceManagerApiAxios.post(
      '/auth/verify',
      { email },
      {
        ...this.getApiHeaders(),
        withCredentials: true,
      },
    );
  }
}

export default new PreferenceManagerApi();
