import styled from 'styled-components';

import { Info, Close } from 'components/Icons';

export const InfoIcon = styled(Info)`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: #0096ff;
    }
  }
`;

export const CloseIcon = styled(Close)`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${(props) => props.theme.colors.primaryIcons};
    }
  }
`;
