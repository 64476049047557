import { getApiToken } from '../helpers/tokenApi';

interface ExtraHeaders {
  'x-customer-id'?: string;
  'x-api-key'?: string;
  'x-user-id'?: string;
}

export class AbstractApiService {
  private getApiToken = getApiToken;

  public getApiHeaders(configurableHeaders: ExtraHeaders = {}): {
    headers: { Authorization: string; authorizationPLToken: string };
  } {
    const token = localStorage.getItem('token');

    return {
      headers: {
        ...configurableHeaders,
        Authorization: `Bearer ${this.getApiToken()}`,
        authorizationPLToken: token || '',
      },
    };
  }
}
