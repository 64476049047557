import React from 'react';
import Icon from '@ant-design/icons';

const CustomIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>vpn_key_black_24dp</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-308.000000, -461.000000)">
        <g id="Group-2-Copy-3" transform="translate(272.000000, 347.000000)">
          <g id="Subscription" transform="translate(20.000000, 90.000000)">
            <g id="vpn_key_black_24dp" transform="translate(16.000000, 24.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <path
                d="M12.65,10 C11.7,7.31 8.9,5.5 5.77,6.12 C3.48,6.58 1.62,8.41 1.14,10.7 C0.32,14.57 3.26,18 7,18 C9.61,18 11.83,16.33 12.65,14 L17,14 L17,16 C17,17.1 17.9,18 19,18 C20.1,18 21,17.1 21,16 L21,14 C22.1,14 23,13.1 23,12 C23,10.9 22.1,10 21,10 L12.65,10 Z M7,14 C5.9,14 5,13.1 5,12 C5,10.9 5.9,10 7,10 C8.1,10 9,10.9 9,12 C9,13.1 8.1,14 7,14 Z"
                id="Shape"
                fill="#303030"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: any) => <Icon component={CustomIcon} {...props} />;
