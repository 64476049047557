import styled from 'styled-components';
import { Form } from 'antd';
import { Flex } from 'shared-components';
import Button from 'components/Button/Button';

interface IProps {
  selected?: boolean;
  addButton?: boolean;
}

export const TopicDragWrapper = styled.div`
  display: flex;
  align-items: baseline;

  & span {
    height: 16px;
    width: 16px;

    & svg {
      fill: ${(props) => props.theme.colors.defaultIcons};
    }
  }
`;

export const TopicsButton = styled.button<IProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 200px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.gray)};
  background: white;
  text-align: left;
  padding: 12px;
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  margin-bottom: 24px;
  ${(props) => props.addButton && 'margin-left: 16px;'}

  & p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
  }

  &:disabled {
    color: ${(props) => props.theme.colors.silver};
    cursor: not-allowed;
  }
`;

export const TopicsForm = styled(Form)`
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  margin-left: 40px;
  padding: 20px;
  /* 240 = left button width + margin */
  width: calc(100% - 240px);

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin: 0;

    & span {
      font-weight: 400;
    }
  }
`;

export const Container = styled(Flex)`
  min-height: 288px;
  text-overflow: ellipsis;
`;

export const OptionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  & span {
    height: 16px;
    width: 16px;

    & svg {
      fill: ${(props) => props.theme.colors.defaultIcons};
    }
  }

  & button {
    height: 40px;
    width: 40px;

    & span {
      height: 20px;
      width: 20px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: baseline;

  & div {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const AddButton = styled(Button)`
  & svg {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
`;

export const CustomLabel = styled.p`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  flex: 1;
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: start;

  & p {
    margin: 0;
  }
`;

export const TopicTranslation = styled.div`
  position: absolute;
  height: calc(100% - 53px);
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  display: flex;
  justify-content: end;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

export const TranslationContainer = styled.div`
  width: 80%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: -5px 0 10px -3px ${(props) => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
`;

export const TranslationFooter = styled.div`
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.gray};
  display: flex;
  justify-content: space-between;
`;

export const TranslationBody = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 32px;
`;

export const TranslationScrollContainer = styled.div`
  margin-top: 18px;
`;

export const TranslationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TranslationLanguageSection = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 20px;
  margin-bottom: 18px;
  &.default-language {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

export const TranslationLanguageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const TranslationTopicsForm = styled(Form)`
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin: 0;

    & span {
      font-weight: 400;
    }
  }
`;

export const CheckboxWrapper = styled.span`
  margin: 0 25px;
`;

export const TranslationButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  z-index: 5;
  display: flex;
  align-items: center;

  & span {
    margin-right: 5px;
  }
  & svg {
    height: 15px;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.background};
  padding: 10px;

  & > div.text-content {
    font-weight: normal;
    flex: 1;
    width: 100%;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputWithTranslate = styled.div`
  position: relative;
`;
