import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { Title } from 'shared-components/ModalsContent';
import { IWidget } from 'lib/interfaces/widget';
import Button from 'components/Button/Button';
import Copy from 'components/Icons/Copy';
import { clickToCopy } from 'lib/helpers';
import { StyledTooltip } from 'components/Form/Tooltip';
import { Pac } from 'lib/api/pacApi';
import {
  CopyContainer,
  WidgetButtonContainer,
  WidgetDetailsContainer,
  WidgetDivider,
  WidgetInfo,
  WidgetInfoContainer,
  WidgetName,
  WidgetTitle,
  WidgetTopicContainer,
  WidgetTopicTitleContainer,
  WidgetTopicsContainer,
  WidgetUuid,
} from './styled';

interface IProps {
  onClose: () => Promise<void> | void;
  widget: IWidget;
  pacDetails?: Pac;
}

const ViewWidgetStructure = ({ onClose, widget, pacDetails }: IProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const onOpenJsonConfigFile = () => {
    window.open(
      `${process.env.REACT_APP_PREFERENCE_API_URL}/public/widgets/${widget.uuid}`,
      '_blank',
    );
  };

  return (
    <Modal
      visible
      title="View Widget Structure"
      secondaryButton={{
        label: 'Close',
        onClick: onClose,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
      isDefaultFooter={false}
    >
      <>
        <Title data-cy={`widget-${widget.uuid}-title`}>{widget.title}</Title>
        <WidgetDetailsContainer>
          <WidgetInfoContainer>
            <WidgetInfo style={{ paddingBottom: '10px' }}>
              <span className="title" data-cy={`widget-${widget.uuid}-uuid`}>
                Widget ID: {widget.uuid}{' '}
              </span>
              <StyledTooltip title={copied ? 'Copied' : 'Copy to clipboard'} placement="right">
                <Copy
                  onClick={() => {
                    clickToCopy(widget.uuid, setCopied);
                  }}
                  data-cy={`configuration-widget-structure--${widget.uuid}-copy-icon`}
                />
              </StyledTooltip>
            </WidgetInfo>
            <WidgetInfo>
              <span className="title" data-cy={`widget-${pacDetails?.uuid}-configuration`}>
                Configuration ID: {pacDetails?.uuid}
              </span>
              <StyledTooltip title={copied ? 'Copied' : 'Copy to clipboard'} placement="right">
                <Copy
                  onClick={() => {
                    clickToCopy(pacDetails?.uuid || '', setCopied);
                  }}
                  data-cy={`configuration-widget-structure--${pacDetails?.uuid}-copy-icon`}
                />
              </StyledTooltip>
            </WidgetInfo>
          </WidgetInfoContainer>
          <WidgetButtonContainer>
            <Button
              onClick={onOpenJsonConfigFile}
              type="primary"
              size="default"
              data-cy="open-json-file"
            >
              Open JSON config file
            </Button>
          </WidgetButtonContainer>
        </WidgetDetailsContainer>
        <WidgetTopicsContainer>
          {widget.topics.map((topic) => (
            <WidgetTopicContainer key={topic.uuid}>
              <WidgetTopicTitleContainer>
                <WidgetTitle data-cy={`topic-title-${topic.uuid}`}>Topic</WidgetTitle>
                <WidgetName data-cy={`topic-name-${topic.uuid}`}>{topic.name}</WidgetName>
                <WidgetUuid data-cy={`topic-uuid-${topic.uuid}`}>{topic.uuid}</WidgetUuid>
                <CopyContainer>
                  <StyledTooltip title={copied ? 'Copied' : 'Copy to clipboard'} placement="right">
                    <Copy
                      onClick={() => {
                        clickToCopy(topic.uuid as string, setCopied);
                      }}
                      data-cy={`configuration-widget-structure-topic--${topic.uuid}-copy-icon`}
                    />
                  </StyledTooltip>
                </CopyContainer>
              </WidgetTopicTitleContainer>
              <WidgetDivider />
              {topic.options.map((option, index) => (
                <WidgetTopicTitleContainer key={option.uuid}>
                  <WidgetTitle data-cy={`option-title-${option.uuid}`}>
                    {index === 0 ? 'Options' : ''}
                  </WidgetTitle>
                  <WidgetName data-cy={`option-name-${option.uuid}`}>{option.name}</WidgetName>
                  <WidgetUuid data-cy={`option-uuid-${option.uuid}`}>{option.uuid}</WidgetUuid>
                  <CopyContainer>
                    <StyledTooltip
                      title={copied ? 'Copied' : 'Copy to clipboard'}
                      placement="right"
                    >
                      <Copy
                        onClick={() => {
                          clickToCopy(option.uuid as string, setCopied);
                        }}
                        data-cy={`configuration-widget-structure-option--${option.uuid}-copy-icon`}
                      />
                    </StyledTooltip>
                  </CopyContainer>
                </WidgetTopicTitleContainer>
              ))}
            </WidgetTopicContainer>
          ))}
        </WidgetTopicsContainer>
      </>
    </Modal>
  );
};

export default ViewWidgetStructure;
