import React from 'react';
import { Checkbox } from 'antd';
import { Tooltip } from 'components/Form';
import { Column, ColumnConfig } from '@ant-design/plots';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AnalyticsOverview } from '../../lib/api/analyticsApi';
import {
  GraphContainer,
  InfoSections,
  InfoSectionsContainer,
  OverviewDescription,
  OverviewSection,
  OverviewStatsContainer,
  OverviewStatsContainerTitle,
  OverviewStatsGroupContainer,
  SectionsContainer,
} from './styled';

interface IOverviewStats {
  showOnlyOwnedPacs: boolean;
  onOnlyShowOwnedPreferenceChange: (e: CheckboxChangeEvent) => void;
  analyticOverview: AnalyticsOverview;
  preferencesPerConfiguration: ColumnConfig;
  preferencesPerWidget: ColumnConfig;
}

const OverviewStats = ({
  showOnlyOwnedPacs,
  onOnlyShowOwnedPreferenceChange,
  analyticOverview,
  preferencesPerConfiguration,
  preferencesPerWidget,
}: IOverviewStats) => {
  const totalPerPacLength = analyticOverview.totalsPerPac.length;
  const preferencePerWidgetDataLength = preferencesPerWidget.data.length;
  return (
    <SectionsContainer>
      <OverviewSection>
        <div>
          <h3>Overview</h3>
          <OverviewDescription>
            Monitor and analyze how many users are interacting with your Preference Management
            solution.
          </OverviewDescription>
          <Checkbox checked={showOnlyOwnedPacs} onChange={onOnlyShowOwnedPreferenceChange}>
            Only show configurations that I own
          </Checkbox>
        </div>
        <OverviewStatsGroupContainer>
          {totalPerPacLength > 1 && (
            <OverviewStatsContainer>
              <OverviewStatsContainerTitle>
                Configurations{' '}
                <Tooltip title="Total amount of Configurations available" placement="right" />
              </OverviewStatsContainerTitle>
              <h2>{totalPerPacLength}</h2>
            </OverviewStatsContainer>
          )}
          <OverviewStatsContainer>
            <OverviewStatsContainerTitle>
              Unique User Ids{' '}
              {totalPerPacLength > 1 ? (
                <Tooltip
                  title="Total amount of unique, non-identifiable User IDs (data subjects) that have preference objects saved under all Configurations available"
                  placement="right"
                />
              ) : (
                <Tooltip
                  title="Total amount of unique, non-identifiable User IDs (data subjects) that have preference objects saved under current Configuration"
                  placement="right"
                />
              )}
            </OverviewStatsContainerTitle>
            <h2>{analyticOverview.totalUniqueUsers}</h2>
          </OverviewStatsContainer>
          <OverviewStatsContainer>
            <OverviewStatsContainerTitle>
              Preferences{' '}
              {totalPerPacLength > 1 ? (
                <Tooltip
                  title="Total amount of preference data submissions for each separate widget under all Configurations available"
                  placement="right"
                />
              ) : (
                <Tooltip
                  title="Total amount of preference data submissions for each separate widget under current Configuration"
                  placement="right"
                />
              )}
            </OverviewStatsContainerTitle>
            <h2>{analyticOverview.totalPreferences}</h2>
          </OverviewStatsContainer>
        </OverviewStatsGroupContainer>
      </OverviewSection>

      {totalPerPacLength > 1 && (
        <>
          <InfoSections
            style={{
              marginTop: '20px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
          >
            <h3>Preferences per Configuration</h3>
            <Tooltip
              title="Here you can see the distribution of the end-user submitted preferences across the Configurations."
              placement="right"
            />
          </InfoSections>
          {preferencesPerConfiguration.data.length > 0 && (
            <GraphContainer style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
              <Column {...preferencesPerConfiguration} />
            </GraphContainer>
          )}
        </>
      )}

      {totalPerPacLength === 1 && (
        <InfoSectionsContainer style={{ marginTop: '20px' }}>
          <InfoSections>
            <p>
              Widgets{' '}
              <Tooltip
                title="Amount of widgets configured under current Configuration"
                placement="right"
              />
            </p>
            <h2>{analyticOverview.totalsPerPac[0].totalWidgets}</h2>
          </InfoSections>
          <InfoSections>
            <p>
              Topics{' '}
              <Tooltip
                title="Total amount of topics available across all configured widgets under current Configuration"
                placement="right"
              />
            </p>
            <h2>{analyticOverview.totalsPerPac[0].totalTopics}</h2>
          </InfoSections>
        </InfoSectionsContainer>
      )}

      {totalPerPacLength === 1 && preferencePerWidgetDataLength > 0 && (
        <>
          <InfoSections
            style={{
              marginTop: '20px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
          >
            <h3>
              Preferences per Widget
              <span style={{ paddingLeft: '10px' }}>
                <Tooltip
                  title="Here you can see the distribution of the end-user submitted preferences under specific Configuration across the Widgets this Configuration contains"
                  placement="right"
                />
              </span>
            </h3>
          </InfoSections>
          <GraphContainer style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
            <Column {...preferencesPerWidget} />
          </GraphContainer>
        </>
      )}
    </SectionsContainer>
  );
};

export default OverviewStats;
