import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { CodeExample } from './styled';
import { Copy } from '../Icons';
import { clickToCopy } from '../../lib/helpers';

interface IProps {
  copyContent?: string;
  children: React.ReactNode;
}

const CodeSnippet = ({ copyContent, children }: IProps) => {
  const [copiedClicked, setCopiedClicked] = useState<boolean>(false);
  return (
    <CodeExample>
      <div>{children}</div>
      {copyContent && (
        <div className="copyContainer">
          <Tooltip title={copiedClicked ? 'Copied' : 'Copy to clipboard'} placement="right">
            <Copy
              onClick={() => {
                clickToCopy(copyContent, setCopiedClicked);
              }}
              style={{ width: '20px' }}
            />
          </Tooltip>
        </div>
      )}
    </CodeExample>
  );
};

export default CodeSnippet;
