import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { setApiToken } from '../helpers/tokenApi';

const useToken = () => {
  const [token, setToken] = useState('');
  const { getIdTokenClaims } = useAuth0();

  const fetchToken = async () => {
    const tokenData: IdToken | undefined = await getIdTokenClaims();
    setToken(tokenData?.__raw || '');
    setApiToken(tokenData?.__raw || '');
  };

  useEffect(() => {
    fetchToken();
  }, [token]);

  return token;
};

export default useToken;
