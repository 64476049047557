import styled from 'styled-components';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
  background-color: ${(props) => props.theme.colors.silver};
  min-width: 40px;
  height: 20px;

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.colors.primary};

    & .ant-switch-handle {
      left: calc(100% - 18px);
    }
  }

  & .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
`;

export default StyledSwitch;
