import { gql } from '@apollo/client';

const getTranslationQuery = (targetLanguages: string[]) => {
  return gql`
  query getTranslation(
    $translationFor: String!
    $sourceLanguage: String!
    $targetLanguages: [String]
  ) {
    getTranslation(
      translationFor: $translationFor
      sourceLanguage: $sourceLanguage
      targetLanguages: $targetLanguages
    ) {
      ${targetLanguages.join('\n')}
    }
  }
`;
};

export default getTranslationQuery;
