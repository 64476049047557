import { AxiosResponse } from 'axios';
import { IApiKeysResponse } from 'lib/interfaces/privateKeys';
import { ApiKeyType } from 'lib/enums/apiKey';
import { AbstractApiService } from './abstractApiService';
import { PreferenceApiAxios } from './axiosInstance';

class PrivateKeysService extends AbstractApiService {
  post(type: ApiKeyType = ApiKeyType.PRIVATE): Promise<AxiosResponse<IApiKeysResponse>> {
    return PreferenceApiAxios.post(
      'api-keys',
      {
        type,
      },
      this.getApiHeaders(),
    );
  }

  get(): Promise<AxiosResponse<IApiKeysResponse>> {
    return PreferenceApiAxios.get('api-keys', this.getApiHeaders());
  }

  delete(apiKey: string): Promise<AxiosResponse<IApiKeysResponse>> {
    return PreferenceApiAxios.delete(
      'api-keys',
      this.getApiHeaders({
        'x-api-key': apiKey,
      }),
    );
  }
}

export default new PrivateKeysService();
