import styled from 'styled-components';

export const Container = styled.div`
  width: 740px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 20px;

  & p {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primaryIcons};
    font-weight: 400;
  }
`;

export const Description = styled.div`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryIcons};

  & a {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
