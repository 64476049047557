import { AxiosResponse } from 'axios';
import { IWebhook } from 'lib/interfaces/webhooks';
import { AbstractApiService } from './abstractApiService';
import { IntegrationApiAxios } from './axiosInstance';

class WebhookApiService extends AbstractApiService {
  createWebhook(pacUuid: string, newWebhook: any): Promise<AxiosResponse<IWebhook>> {
    return IntegrationApiAxios.post(
      `webhooks?pacUuid=${pacUuid}`,
      newWebhook,
      this.getApiHeaders(),
    );
  }

  editWebhook(webhookUuid: string, webhookPayload: IWebhook): Promise<AxiosResponse<IWebhook>> {
    return IntegrationApiAxios.patch(
      `/webhooks/${webhookUuid}`,
      webhookPayload,
      this.getApiHeaders(),
    );
  }

  getByPacId(pacUuid: string): Promise<AxiosResponse<Array<IWebhook>>> {
    return IntegrationApiAxios.get(`/webhooks/${pacUuid}`, this.getApiHeaders());
  }

  deleteById(webhookUuid: string): Promise<AxiosResponse> {
    return IntegrationApiAxios.delete(`/webhooks/${webhookUuid}`, {
      ...this.getApiHeaders(),
      responseType: 'blob',
    });
  }
}

export default new WebhookApiService();
