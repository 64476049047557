import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { useMessage, useToken } from 'lib/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IWidget } from 'lib/interfaces/widget';
import { IWebhook } from 'lib/interfaces/webhooks';
import { ICmpIntegration } from 'lib/interfaces/cmpIntegration';
import { Languages } from 'lib/enums/translations';
import {
  showErrorResponseNotification,
  exportToCsv,
  findKeyByValue,
  sortWidgetsByUuid,
} from 'lib/helpers';
import TranslationModal from 'components/Modal/TranslationModal/index';

import Spin from 'components/Spin';
import WidgetApiService from 'lib/api/widgetApi';
import PacService, { Pac, PacType } from 'lib/api/pacApi';
import WebhookApiService from 'lib/api/webhookApi';
import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';
import CustomerService from 'lib/api/customerApi';
import { AbstractApiService } from 'lib/api/abstractApiService';
import WidgetTranslationApi from 'lib/api/widgetTranslationApi';
import useUserData from 'lib/hooks/useUserData';
import Modal from 'components/Modal/Modal';
import WidgetsList from './WidgetsList';
import StylingTab from './Styling';

import { PageTitle, PageWrapper } from './styled';
import GeneralTab from './General';
import IntegrationTab from './Integrations';
import WebhooksIntegration from './Integrations/Webhooks';
import UsercentricsCmpIntegration from './Integrations/UsercentricsCmp';
import ImplementationTab from './Implementation';

interface IPacProps {
  onPageDataLoad: (
    id: string,
    title: string,
    deactivated: boolean,
    type: PacType,
    description?: string,
  ) => void;
  onPageUnmount: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  setNextPageBlocked: (nextPage: string | null) => void;
  nextPageBlocked: null | string;
  selectedTab: string;
}

const PAC = ({
  onPageDataLoad,
  onPageUnmount,
  selectedTab = 'General',
  setHasUnsavedChanges,
  nextPageBlocked,
  setNextPageBlocked,
}: IPacProps) => {
  const navigate = useNavigate();
  const message = useMessage();

  const [widgets, setWidgets] = useState<Array<IWidget>>([]);
  const [emailAccess, setEmailAccess] = useState<boolean>(false);
  const [widgetDetails, setWidgetDetails] = useState({ title: '', description: '' });
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const [languageList, setLanguageList] = useState<{ [key: string]: string }>({
    en: Languages.ENGLISH,
  });
  const [webhooksList, updateWebhooksList] = useState<Array<IWebhook>>([]);
  const [cmpIntegrationList, updateCmpIntegrationList] = useState<Array<ICmpIntegration>>([]);
  const [editableLanguages, setEditableLanguage] = useState<Array<string>>([Languages.ENGLISH]);
  const [visibleLanguages, setVisibleLanguage] = useState<Array<string>>(editableLanguages);
  const [isSaveDisabled, disableSave] = useState<boolean>(true);
  const [isWidgetLoaded, setIsWidgetLoaded] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<string>('');
  const [shouldUseHtmlEditor, setShouldUseHtmlEditor] = useState<boolean>(false);
  const [translationsValues, setTranslationsValues] = useState<Array<AxiosResponse<Pac> | null>>(
    [],
  );
  const [pacDetails, setPacDetails] = useState<Pac | undefined>();
  const { isSuperAdmin } = useUserData();
  const token = useToken();
  const params: { pacId?: string } = useParams();
  const abstractApiService = new AbstractApiService();
  const location = useLocation();
  // ! here is added because of useParams returns string | undefined but in this case, this page is available only when pacId is specified
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pacId = params.pacId!;

  const updateStatesFromResponse = (
    response: AxiosResponse<Pac>,
    languageListLocal?: { [key: string]: string },
  ) => {
    const responseTitle = response.data.title;
    const responseDescription = response.data.description;

    if (Object.keys(response.data).includes('editableLanguages')) {
      setEditableLanguage(
        response.data.editableLanguages.map(
          (el: string) => Object(languageListLocal || languageList)[el],
        ),
      );
      setVisibleLanguage(
        response.data.visibleLanguages.map(
          (el: string) => Object(languageListLocal || languageList)[el],
        ),
      );
    }

    setEmailAccess(response.data.isEmailLoginAvailable || false);
    onPageDataLoad(
      pacId,
      responseTitle,
      !!response.data.deactivated,
      response.data.type,
      responseDescription,
    );
    setWidgetDetails({
      title: responseTitle,
      description: responseDescription,
    });
  };

  const fetchWidgets = async () => {
    try {
      const langsResponse = await WidgetTranslationApi.getLanguages();
      const response: AxiosResponse<Pac> = await PacService.getById(pacId);

      setPacDetails(response.data);
      setLanguageList(langsResponse.data);
      updateStatesFromResponse(response, langsResponse.data);
      const sortedWidgets = sortWidgetsByUuid(response.data.widgets, response.data.widgetUuids);
      setWidgets(sortedWidgets);
      setIsWidgetLoaded(true);
    } catch (error: any) {
      if (
        error?.response?.status === 404 ||
        (error?.response?.status === 400 && error?.response?.data?.message.includes('invalid UUID'))
      ) {
        navigate('/404/');
        return;
      }
      if (error?.response?.status === 403) {
        navigate('/403/');
        return;
      }

      if (error?.response?.status >= 500) {
        navigate('/500/');
        return;
      }

      navigate('/unknownError/');
    }
  };

  const fetchWebhooks = async () => {
    if (params.pacId) {
      setLoading(true);
      try {
        const response = await WebhookApiService.getByPacId(params.pacId);
        updateWebhooksList(response.data);
      } catch (error) {
        showErrorResponseNotification(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchCmpIntegrations = async () => {
    if (params.pacId) {
      setLoading(true);
      try {
        const response = await UsercentricsCmpIntegrationApiService.getById(pacId);
        updateCmpIntegrationList(response.data);
      } catch (error) {
        showErrorResponseNotification(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const exportReq = async (widgetId: string) => {
    try {
      const response = await CustomerService.getWidgetExportData({ widgetId });
      exportToCsv(response.data, `pmp_data_${widgetId}_${Date.now()}.csv`);
    } catch (error: any) {
      if (error.response.status === 422) {
        message.error('There is no preference data for this widget');
      } else {
        showErrorResponseNotification(error);
      }
    }
  };

  const deleteWidgetReq = async (widgetUuid: string) => {
    try {
      await WidgetApiService.deleteById(widgetUuid);

      message.success(`Widget was deleted: ${widgetUuid}`);
      fetchWidgets();
      fetchWebhooks();
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const saveReorderedWidgets = async (widgetsProps: IWidget[]) => {
    setWidgets(widgetsProps);

    try {
      setLoading(true);
      await PacService.updateWidgetFromPack(pacId, {
        widgets: widgetsProps.map((widget) => widget.uuid),
      });

      await fetchWidgets();
      setLoading(false);
      message.success(
        <div>
          <span>Widget order changed</span>
          <br />
          <span>
            Please mind, due to cache settings, the changes will take effect in 10-15 minutes.
          </span>
        </div>,
      );
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const hiddenWidgetChange = (widgetUuid: string, hiddenNewState: boolean): void => {
    setIsWidgetLoaded(false);
    try {
      axios
        .patch(
          `${process.env.REACT_APP_CONFIG_API_URL}/widgets/${widgetUuid}/default`,
          {
            hidden: hiddenNewState,
          },
          abstractApiService.getApiHeaders(),
        )
        .then((response) => {
          message.success(
            <div>
              <span>
                Widget {response.data ? response.data.title : ''} is now{' '}
                {!hiddenNewState && <>visible</>} {hiddenNewState && <>hidden</>} on Preference
                Center page
              </span>
            </div>,
          );
          fetchWidgets();
        });
    } catch (e) {
      message.error(`Something went wrong while updating the widget: ${widgetUuid}`);
    } finally {
      setIsWidgetLoaded(true);
    }
  };

  const fetchData = async () => {
    if (!token) {
      return;
    }
    fetchWidgets();
    setLoading(false);
  };

  const submitTranslations = (fieldValues: { [key: string]: Pac }) => {
    const enTranslation = translationsValues.find((el) => el?.data.language === 'en')?.data;
    try {
      Object.entries(fieldValues).forEach(async ([key, val]) => {
        if (val.uuid) {
          await PacService.updateTranslation(val.uuid, {
            [showTranslationModal]: {
              key: Object(enTranslation)[showTranslationModal].key,
              value: Object(val)[showTranslationModal],
            },
          });
        } else {
          const fieldName = showTranslationModal === 'title' ? 'description' : 'title';
          await PacService.postTranslation(pacId, {
            language: key,
            [fieldName]: Object(enTranslation)[fieldName],
            [showTranslationModal]: {
              key: Object(enTranslation)[showTranslationModal].key,
              value: Object(val)[showTranslationModal],
            },
          });
        }
      });
      setLoading(true);
      disableSave(true);
      setShowTranslationModal('');
      fetchData();
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const handleTranslate = async (fieldName: string, useHtmlEditor = false) => {
    if (!isSaveDisabled) {
      return message.error('Please save any unsaved changes before opening the translation modal');
    }
    const translationsResponses = await Promise.allSettled(
      editableLanguages.map((language) =>
        PacService.getTranslationLanguages(pacId, findKeyByValue(languageList, language)),
      ),
    );
    setTranslationsValues(
      translationsResponses.map((el) => (el.status === 'fulfilled' ? el.value : null)),
    );
    setShouldUseHtmlEditor(useHtmlEditor);
    return setShowTranslationModal(fieldName);
  };

  const onModalCancelClicked = () => {
    setNextPageBlocked(null);
  };

  useEffect(() => {
    fetchData();
    return () => {
      onPageUnmount();
    };
  }, [token]);

  useEffect(() => {
    // Function to call on route change
    if (location.pathname.includes('integrations')) {
      fetchWebhooks();
      fetchCmpIntegrations();
    }
  }, [location]);

  return isDataLoading || !isWidgetLoaded ? (
    <Spin />
  ) : (
    <>
      {showTranslationModal !== '' && (
        <TranslationModal
          htmlEditor={shouldUseHtmlEditor}
          fieldName={showTranslationModal}
          editableLanguagesList={editableLanguages}
          languagesList={languageList}
          isSaveDisabled={!!pacDetails?.deactivated && !isSuperAdmin}
          translationsValues={translationsValues}
          onClose={() => setShowTranslationModal('')}
          submitFunction={submitTranslations}
        />
      )}

      {nextPageBlocked && (
        <Modal
          visible
          title="Unsaved changes"
          secondaryButton={{ label: 'Cancel', onClick: onModalCancelClicked }}
          width={600}
          bodyHeight={500}
          isConfirmation
          primaryButton={{
            label: 'Confirm',
            onClick: async () => {
              await fetchWidgets();
              disableSave(true);
              setHasUnsavedChanges(false);
              onModalCancelClicked();
              navigate(nextPageBlocked as string);
            },
            danger: true,
          }}
          hideFooterBorder
          onClose={onModalCancelClicked}
          isDefaultFooter
        >
          <span>
            You have unsaved changes on the current screen. If proceeding further, these changes
            will be lost. Are you sure?
          </span>
        </Modal>
      )}
      {selectedTab === 'General' && (
        <>
          <PageTitle>Settings</PageTitle>
          <PageWrapper>
            <GeneralTab
              setHasUnsavedChanges={setHasUnsavedChanges}
              editableLanguages={editableLanguages}
              setEditableLanguage={setEditableLanguage}
              title={widgetDetails.title}
              description={widgetDetails.description}
              emailAccessReq={emailAccess}
              isSaveDisabled={isSaveDisabled}
              disableSave={disableSave}
              visibleLanguagesReq={visibleLanguages}
              languageList={languageList}
              handleTranslate={handleTranslate}
              setLoading={setLoading}
              updateStatesFromResponse={updateStatesFromResponse}
              setWidgetDetails={setWidgetDetails}
              pacDetails={pacDetails}
            />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'Widgets' && (
        <>
          <PageTitle>Widgets</PageTitle>
          <PageWrapper>
            <WidgetsList
              widgets={widgets}
              languageList={languageList}
              exportReq={exportReq}
              fetchWidgets={fetchWidgets}
              deleteWidgetReq={deleteWidgetReq}
              updateWidgetOrder={saveReorderedWidgets}
              hiddenWidgetChange={hiddenWidgetChange}
              editableLanguages={editableLanguages}
              pacDetails={pacDetails}
              isWidgetLoading={!isWidgetLoaded}
            />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'Appearance' && (
        <>
          <PageTitle>Appearance</PageTitle>
          <PageWrapper>
            <StylingTab
              setHasUnsavedChanges={setHasUnsavedChanges}
              token={token}
              pacId={pacId}
              pacDetails={pacDetails}
            />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'Implementation' && (
        <>
          <PageTitle>Implementation</PageTitle>
          <PageWrapper>
            <ImplementationTab pacDetails={pacDetails} />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'Integrations' && (
        <>
          <PageTitle>Integrations</PageTitle>
          <PageWrapper>
            <IntegrationTab
              webhooksList={webhooksList}
              cmpIntegrationList={cmpIntegrationList}
              fetchCmpIntegrations={fetchCmpIntegrations}
              fetchWebhooks={fetchWebhooks}
              widgets={widgets}
              pacDetails={pacDetails}
            />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'Webhooks' && (
        <>
          <PageTitle>Webhook Integration</PageTitle>
          <PageWrapper>
            <WebhooksIntegration
              widgets={widgets}
              webhooksList={webhooksList}
              fetchWebhooks={fetchWebhooks}
              pacDetails={pacDetails}
            />
          </PageWrapper>
        </>
      )}

      {selectedTab === 'CmpUsercentrics' && (
        <>
          <PageTitle>Usercentrics Consent Management Platform (CMP)</PageTitle>
          <PageWrapper>
            <UsercentricsCmpIntegration
              widgets={widgets}
              cmpIntegrationList={cmpIntegrationList}
              fetchCmpIntegrations={fetchCmpIntegrations}
              pacDetails={pacDetails}
            />
          </PageWrapper>
        </>
      )}
    </>
  );
};

export default PAC;
