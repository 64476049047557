import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import styled from 'styled-components';
import { Tooltip as TooltipIcon } from 'components/Icons';

const overlayInnerStyle = {
  fontSize: '12px',
  borderRadius: '4px',
  padding: '10px',
  background: 'rgba(48, 48, 48, 0.8)',
};

const StyledTooltip = styled(AntdTooltip)`
  & #questionIcon path {
    fill: ${(props) => props.theme.colors.gray};
  }
`;

interface IProps {
  placement: TooltipPlacement;
  title: string;
}

const Tooltip = ({ placement, title }: IProps) => (
  <StyledTooltip placement={placement} title={title} overlayInnerStyle={overlayInnerStyle}>
    <TooltipIcon />
  </StyledTooltip>
);

export { Tooltip, StyledTooltip, overlayInnerStyle };
