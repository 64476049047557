import React from 'react';
import Icon from '@ant-design/icons';

function CustomIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_670_2175)">
        <path
          fill="#303030"
          d="M2 11.64v2.027a.33.33 0 00.333.333H4.36a.313.313 0 00.233-.1l7.28-7.273-2.5-2.5L2.1 11.4a.327.327 0 00-.1.24zm11.807-6.946c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z"
        />
      </g>
      <defs>
        <clipPath id="clip0_670_2175">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default (props: any) => <Icon component={CustomIcon} {...props} />;
