import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  min-width: 232px;
  background: ${(props) => props.theme.colors.background};
  padding: 16px;
  position: fixed;
  min-height: calc(100vh - 64px);
`;

export const Item = styled(NavLink)`
  padding: 10px 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primaryText};

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.lightBorder};
    border-radius: 8px;
    font-weight: 600;

    & svg,
    & svg path,
    & svg g path,
    & svg #analytics,
    & #analyticsPage path,
    & svg #user-management {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Text = styled.span`
  margin-left: 8px;
`;
