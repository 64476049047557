import React from 'react';
import {
  Widgets,
  Statistics,
  Code,
  Analytics,
  Settings,
  Appearance,
  Configurations,
  Integrations,
} from 'components/Icons';
import { useLocation } from 'react-router-dom';
import { Container, Item, Text } from './styled';

interface ISideBar {
  hasUnsavedChanges: boolean;
  setNextPageBlocked: (nextPage: string) => void;
}

const SideBar = ({ hasUnsavedChanges, setNextPageBlocked }: ISideBar) => {
  const location = useLocation();
  const pathLevels = location.pathname.split('/');

  const onItemClicked = (event: React.MouseEvent, nextPage: string) => {
    if (hasUnsavedChanges) {
      setNextPageBlocked(nextPage);
      event.preventDefault();
    }
  };

  if (pathLevels.length > 2 && pathLevels[2] !== '' && pathLevels[1] === 'configurations') {
    const widgetPath = `/configurations/${pathLevels[2]}/widgets`;
    const settingsPath = `/configurations/${pathLevels[2]}/settings`;
    const appearancePath = `/configurations/${pathLevels[2]}/appearance`;
    const integrationsPath = `/configurations/${pathLevels[2]}/integrations`;
    const implementationPath = `/configurations/${pathLevels[2]}/implementation`;
    return (
      <Container>
        <Item
          to={widgetPath}
          onClick={(event) => onItemClicked(event, widgetPath)}
          data-cy="configuration-tab-widgets"
        >
          <Widgets />
          <Text>Widgets</Text>
        </Item>
        <Item
          to={settingsPath}
          onClick={(event) => onItemClicked(event, settingsPath)}
          data-cy="configuration-tab-general"
        >
          <Settings />
          <Text>Settings</Text>
        </Item>
        <Item
          to={appearancePath}
          onClick={(event) => onItemClicked(event, appearancePath)}
          data-cy="configuration-tab-styling"
        >
          <Appearance />
          <Text>Appearance</Text>
        </Item>
        <Item
          to={integrationsPath}
          onClick={(event) => onItemClicked(event, integrationsPath)}
          data-cy="configuration-tab-integrations"
        >
          <Integrations />
          <Text>Integrations</Text>
        </Item>
        <Item
          to={implementationPath}
          onClick={(event) => onItemClicked(event, implementationPath)}
          data-cy="configuration-tab-implementation"
        >
          <Code />
          <Text>Implementation</Text>
        </Item>
      </Container>
    );
  }

  return (
    <Container>
      <Item to="/configurations">
        <Configurations />
        <Text>Configurations</Text>
      </Item>
      <Item to="/analytics">
        <Analytics />
        <Text>Analytics</Text>
      </Item>
      <Item to="/preference-data">
        <Statistics />
        <Text>Preference Data</Text>
      </Item>
      <Item to="/account-settings">
        <Code />
        <Text>Account Settings</Text>
      </Item>
    </Container>
  );
};

export default SideBar;
