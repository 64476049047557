import React from 'react';

function Icon({ width = '16' }: { width: string }) {
  return (
    <svg
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#595959"
        d="M2.912 6.5l.938.938c.092.1.14.218.144.356a.465.465 0 01-.144.356.48.48 0 01-.7 0l-1.8-1.8a.439.439 0 01-.106-.162A.553.553 0 011.212 6c0-.067.01-.13.032-.187a.439.439 0 01.106-.163l1.8-1.8a.48.48 0 01.7 0c.1.1.15.219.15.356 0 .138-.05.257-.15.357l-.938.937h6.175l-.937-.937a.535.535 0 01-.144-.357.465.465 0 01.144-.356.48.48 0 01.7 0l1.8 1.8a.44.44 0 01.106.163c.021.058.031.12.031.187s-.01.13-.03.188a.44.44 0 01-.107.162l-1.8 1.8a.48.48 0 01-.7 0A.487.487 0 018 7.794c0-.138.05-.256.15-.356l.937-.938H2.912z"
      />
    </svg>
  );
}

export default Icon;
